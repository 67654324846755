import { withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';

// custom styles Input
const BootstrapInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },

  input: {
    cursor: 'pointer !important',
    position: 'relative',
    backgroundColor: '#00A6A6 ',
    border: '1px solid #ced4da',
    borderRadius: '30px',
    fontSize: 12,
    height: 17,
    color: '#FFFFFF',
    padding: '9px 10px 10px 18px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),

    // Use the system font instead of the default Roboto font.
    fontFamily: ['"Helvetica Neue"', 'Arial', 'Helvetica', '"sans-serif"'].join(
      ',',
    ),

    '&:focus': {
      borderRadius: '30px',
      color: '#00000',
      borderColor: '#80bdff',
      backgroundColor: '#00A6A6 ',
    },

    '&:disabled': {
      cursor: 'not-allowed !important',
    },
  },
}))(InputBase);

export default BootstrapInput;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// @material-ui/core
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';

// @material-ui/icons

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import {
  faCheck,
  faTimes,
  faExclamationCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// configs
import api from 'config/api';
import globalFunc from 'config/globalFunc';

import styleCss from 'assets/css/views/global.module.css';
import UploadProgramDialog from './UploadProgramDialog';

function TabelValidasiBahaya({ openNotification, userId }) {
  // states
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [dataList, setDataList] = useState([]);

  const toggleDialog = () => {
    setDialogOpen(!isDialogOpen);
  };

  const onDownloadFormatClick = () => {
    const urlDownload = api.getProgramExcelTemplate();
    window.open(urlDownload);
  };

  const getList = id => {
    setLoading(true);
    api
      .getLayerPrograms(id)
      .then(res => {
        if (res.data.error_code === 0) {
          let dataa = res.data.data;
          setDataList(dataa);
        } else {
          openNotification('danger', res.data.message);
        }
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        console.log('Errorr:', err);
      });
  };

  useEffect(() => {
    getList(userId);
  }, []);

  return (
    <>
      <div className={styleCss.DataSaya}>
        <div>
          <Typography className={styleCss.JudulDataSaya}>
            <ArrowDropDownIcon style={{ color: '#00A6A6' }} />
            <p>Data Saya</p>
          </Typography>
          <div
            style={{
              overflowX: 'auto',
              overflowY: 'auto',
              margin: ' 15px 15px',
              marginBottom: '50px',
              maxHeight: '500px',
            }}
          >
            {isLoading ? (
              <Skeleton variant="rect" width="100%">
                <div style={{ height: 200 }} />
              </Skeleton>
            ) : (
              <table className={styleCss.TableValidasiBahaya}>
                <thead>
                  <tr>
                    <th style={{ backgroundColor: '#1CF4D5' }}>No</th>
                    <th>Nama File</th>
                    <th>Jenis </th>
                    <th>File</th>
                    <th>Tanggal </th>
                    <th>Note </th>
                    <th className={styleCss.AprvTh}>Aprv </th>
                  </tr>
                </thead>
                <tbody>
                  {dataList.map((dt, index) => (
                    <tr key={`data-list_${dt.id}`}>
                      <td style={{ backgroundColor: '#c4fbf2 ' }}>
                        {index + 1}
                      </td>
                      <td>{dt.layer_name}</td>
                      <td>{dt.jenis}</td>
                      <td>
                        {dt.jenis === 'api' ? (
                          <a style={{ color: '#00A6A6' }} href={dt.api_target}>
                            {dt.api_target}
                          </a>
                        ) : (
                          <a
                            style={{ color: '#00A6A6' }}
                            href={dt.layer_name}
                            download
                          >
                            {dt.layer_name}.{dt.jenis}
                          </a>
                        )}
                      </td>
                      <td>{globalFunc.formatDateFromDT(dt.created_date)}</td>
                      <td>{dt.remarks}</td>
                      <td>
                        {dt.approval ? (
                          <FontAwesomeIcon
                            style={{ color: '#00A6A6' }}
                            icon={faCheck}
                          />
                        ) : (
                          <FontAwesomeIcon
                            style={{ color: 'red' }}
                            icon={faTimes}
                          />
                        )}
                      </td>
                    </tr>
                  ))}
                  {dataList.length === 0 && !isLoading && (
                    <tr>
                      <td colSpan="6">
                        <FontAwesomeIcon icon={faExclamationCircle} />
                        Data Belum ada
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
          </div>
        </div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Typography
            className={styleCss.downloadFormatData}
            onClick={onDownloadFormatClick}
          >
            Download format data excel
          </Typography>
        </div>
        <Button
          className={styleCss.uploadButtonadminProgram}
          onClick={toggleDialog}
        >
          Upload
        </Button>
      </div>
      <UploadProgramDialog
        getList={getList}
        isDialogOpen={isDialogOpen}
        toggleDialog={toggleDialog}
      />
    </>
  );
}

TabelValidasiBahaya.propTypes = {
  openNotification: PropTypes.func,
  userId: PropTypes.number,
};

export default TabelValidasiBahaya;

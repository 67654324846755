import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

// @material-ui/core
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

// @material-ui/icons
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

// core components
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import Navbar from 'components/Navbars/Navbar';

// Admin Profile
import ProfileSection from 'components/AdminProfile/ProfileSection';
import AdminActivities from 'components/AdminProfile/AdminActivities';

// assets
import styleCss from 'assets/css/views/global.module.css';

// context
import { UserContext } from 'App';

function AdminProfile({ children }) {
  const { userId, roles } = useContext(UserContext);

  const history = useHistory();

  useEffect(() => {
    if (!localStorage.getItem('token')) history.push('home');
  }, []);

  return (
    <div className={styleCss.Container}>
      <Navbar width={'100%'} />
      <div
        style={{
          marginTop: '90px',
          marginLeft: '-10px',
          marginBottom: '-10px',
        }}
      >
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Link
            color="inherit"
            onClick={() => history.push('home')}
            className={styleCss.Link}
          >
            <ArrowBackIosIcon className={styleCss.IconBreadcrumbs} />
            Kembali ke peta
          </Link>
        </Breadcrumbs>
      </div>

      <CardBody>
        <GridContainer>
          <GridItem md={3}>
            <Card className={styleCss.CardProfileRingkasan}>
              <div className={styleCss.headerCard}>Profile</div>
              <ProfileSection userId={userId} roles={roles} />
            </Card>
          </GridItem>

          <GridItem md={9}>
            <Card className={styleCss.CardProfileRingkasan}>
              <div className={styleCss.headerCard}>Ringkasan</div>
              <div>
                <GridContainer>
                  <GridItem md={8}>{children}</GridItem>

                  <GridItem md={4}>
                    <AdminActivities userId={userId} />
                  </GridItem>
                </GridContainer>
              </div>
            </Card>
          </GridItem>
        </GridContainer>
      </CardBody>
    </div>
  );
}

AdminProfile.propTypes = {
  children: PropTypes.node,
};

export default AdminProfile;

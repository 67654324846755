// @material-ui/icons
import Dashboard from '@material-ui/icons/Dashboard';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import BubbleChart from '@material-ui/icons/BubbleChart';
import LocationOn from '@material-ui/icons/LocationOn';
import EventIcon from '@material-ui/icons/Event';

// core components/views for Admin layout
import HomeApikPage from 'views/homeApik/homeApik';
import AdminProgramProfilePage from 'views/AdminProgram/adminProgramProfile';
import EditProgramProfilePage from 'views/AdminProgram/editProfilAdminProgram';
import adminDaerahProfilePage from 'views/AdminDaerah/adminDaerahProfile';
import Adaptasi from 'views/AdminDaerah/adaptation';
import DataKerentananPage from 'views/AdminDaerah/daftarDataKerentanan';
import EditProfileAdminDaerahPage from 'views/AdminDaerah/editProfilAdminDaerah';
import Validasi from 'views/AdminDaerah/validation';
import DesaDesi from 'views/DesaDesi/desaDesi';

const dashboardRoutes = [
  {
    path: '/home',
    name: 'Home Apik',
    rtlName: 'لوحة القيادة',
    icon: Dashboard,
    component: HomeApikPage,
    layout: '/map',
  },
  {
    path: '/validasi',
    name: 'Admin Daerah Validasi',
    rtlName: 'لوحة القيادة',
    icon: Dashboard,
    component: Validasi,
    layout: '/map',
  },
  {
    path: '/adaptasi',
    name: 'Admin Daerah Adaptasi',
    rtlName: 'لوحة القيادة',
    icon: Dashboard,
    component: Adaptasi,
    layout: '/map',
  },
  {
    path: '/profile',
    name: 'Admin Daerah Profile',
    rtlName: 'ملف تعريفي للمستخدم',
    icon: EventIcon,
    component: adminDaerahProfilePage,
    layout: '/admin',
  },
  {
    path: '/data-kerentanan',
    name: 'Data Kerentanan',
    rtlName: 'خرائط',
    icon: LocationOn,
    component: DataKerentananPage,
    layout: '/admin',
  },
  {
    path: '/profile-edit-admin-daerah',
    name: 'Edit Profil Admin Daerah',
    icon: LibraryBooks,
    component: EditProfileAdminDaerahPage,
    layout: '/admin',
  },
  {
    path: '/program-profile',
    name: 'Admin Program Profile',
    icon: LibraryBooks,
    component: AdminProgramProfilePage,
    layout: '/admin',
  },
  {
    path: '/edit-program-profile',
    name: 'EditProgramProfile',
    rtlName: 'الرموز',
    icon: BubbleChart,
    component: EditProgramProfilePage,
    layout: '/admin',
  },
  {
    path: '/desa-desi',
    name: 'Desa Desi',
    rtlName: 'خرائط',
    icon: LocationOn,
    component: DesaDesi,
    layout: '/admin',
  },
];

export default dashboardRoutes;

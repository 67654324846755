import React, { useEffect, useState } from 'react';

// @material-ui/core
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

// @material-ui/icons

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

// configs
import api from 'config/api';

import styleCss from 'assets/css/views/global.module.css';

function FormDesaDesi({ idDesa, openNotification, handleCloseForm }) {
  const length = [
    {
      id: 'f1',
      total: 4,
    },
    {
      id: 'f2',
      total: 5,
    },
    {
      id: 'f3',
      total: 7,
    },
    {
      id: 'f4',
      total: 7,
    },
    {
      id: 'f5',
      total: 4,
    },
    {
      id: 'f6',
      total: 3,
    },
    {
      id: 'f7',
      total: 3,
    },
    {
      id: 'f8',
      total: 1,
    },
  ];
  const [checklist, setChecklist] = useState({
    f1: false,
    f2: false,
    f3: false,
    f4: false,
    f5: false,
    f6: false,
    f7: false,
    f8: false,
  });

  const [listChecklist, setListChecklist] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const options = [
    {
      name: 'Pilih jawaban',
      value: '0',
    },
    {
      name: 'Rendah',
      value: '1',
    },
    {
      name: 'Sedang',
      value: '2',
    },
    {
      name: 'Tinggi',
      value: '3',
    },
  ];

  const handleChecklist = e => {
    const { name, value } = e.target;
    if (value === '0') return;

    if (listChecklist.find(item => item.id === name)) {
      setListChecklist(
        listChecklist.map(item => {
          if (item.id === name) return { ...item, value };
          return item;
        }),
      );
    } else {
      setListChecklist([...listChecklist, { id: name, value }]);
    }
  };

  const handleSubmit = () => {
    setLoading(true);
    const data = {
      desa_id: idDesa,
      answer: JSON.stringify(listChecklist),
    };
    api
      .createDesaDesi(data)
      .then(result => {
        if (result.data.error_code === 0) {
          openNotification('success', result.data.message);
          handleCloseForm();
        } else {
          openNotification('error', result.data.message);
        }
      })
      .catch(err => {
        console.log('Error:', err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getDesaDesiByDesa = () => {
    api.getDesaDesiByDesa(idDesa).then(res => {
      if (res.data?.data?.answer)
        setListChecklist(JSON.parse(res.data.data.answer));
    });
  };

  useEffect(() => {
    const check = {};
    length.forEach(item => {
      const { id, total } = item;
      const data = listChecklist.filter(item => item.id.includes(id));
      if (data.length === total) check[id] = true;
      else check[id] = false;
    });
    setChecklist(check);
  }, [listChecklist]);

  useEffect(() => {
    getDesaDesiByDesa();
  }, [idDesa]);

  return (
    <>
      <div>
        <Typography className={styleCss.JudulDataSaya}>
          <ArrowDropDownIcon style={{ color: '#00A6A6' }} />
          <p>Form Penilaian Desa Desi</p>
        </Typography>
        <div
          style={{
            overflowX: 'auto',
            overflowY: 'auto',
            margin: ' 15px 15px',
            marginBottom: '50px',
            maxHeight: '500px',
          }}
        >
          <table className={styleCss.TableValidasiBahaya}>
            <tbody>
              <tr>
                <th style={{ backgroundColor: '#1CF4D5' }}>Kode Indikator</th>
                <th>Indikator</th>
                <th>Kode Data</th>
                <th>Data</th>
                <th className={styleCss.AprvTh}>Action</th>
              </tr>
              <tr>
                <td rowSpan={4}>
                  <span>
                    <input
                      id="f1"
                      type="checkbox"
                      readOnly
                      checked={checklist.f1}
                    />
                  </span>
                  F.1
                </td>
                <td rowSpan={4}>Kepemimpinan dan pemerintahan (Tata Kelola)</td>
                <td>F.1.1</td>
                <td>
                  Pemerintah desa memiliki focal point khusus yang bertanggung
                  jawab atas isu perubahan iklim, bencana dan kesehatan
                </td>
                <td>
                  <Select
                    name="f11"
                    onChange={handleChecklist}
                    value={
                      listChecklist.find(item => item.id === 'f11')?.value ||
                      '0'
                    }
                    style={{ width: '100%' }}
                  >
                    {options.map((item, index) => (
                      <MenuItem key={`option_${index}`} value={item.value}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </td>
              </tr>
              <tr>
                <td>F.1.2</td>
                <td>
                  Terdapat perencanaan aksi desa/kelurahan berkaitan dengan
                  perubahan iklim berbasis dampak pada bidang kesehatan
                  ditingkat komunitas
                </td>
                <td>
                  <Select
                    name="f12"
                    onChange={handleChecklist}
                    value={
                      listChecklist.find(item => item.id === 'f12')?.value ||
                      '0'
                    }
                    style={{ width: '100%' }}
                  >
                    {options.map((item, index) => (
                      <MenuItem key={`option_${index}`} value={item.value}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </td>
              </tr>
              <tr>
                <td>F.1.3</td>
                <td>
                  Terdapat mekanisme kelembagaan berjenjang dan terlegitimasi
                  antara pemerintah desa dan pemerintah kabupaten/kota berkaitan
                  dengan pelaksanaan aksi adaptasi perubahan iklim terencana
                </td>
                <td>
                  <Select
                    name="f13"
                    onChange={handleChecklist}
                    value={
                      listChecklist.find(item => item.id === 'f13')?.value ||
                      '0'
                    }
                    style={{ width: '100%' }}
                  >
                    {options.map((item, index) => (
                      <MenuItem key={`option_${index}`} value={item.value}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </td>
              </tr>
              <tr>
                <td>F.1.4</td>
                <td>
                  Terdapat rencana kontijensi yang bertujuan melindungi
                  Kesehatan komunitas dari kondisi iklim ekstrim (fast onset)
                </td>
                <td>
                  <Select
                    name="f14"
                    onChange={handleChecklist}
                    value={
                      listChecklist.find(item => item.id === 'f13')?.value ||
                      '0'
                    }
                    style={{ width: '100%' }}
                  >
                    {options.map((item, index) => (
                      <MenuItem key={`option_${index}`} value={item.value}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </td>
              </tr>
              <tr>
                <td rowSpan={5}>
                  <span>
                    <input
                      id="f2"
                      type="checkbox"
                      readOnly
                      checked={checklist.f2}
                    />
                  </span>
                  F.2
                </td>
                <td rowSpan={5}>Petugas Kelurahan/Desa dan Kader</td>
                <td>F.2.1</td>
                <td>
                  Petugas kelurahan/desa atau kader terkait ditingkat
                  kelurahan/desa memiliki akses terhadap informasi iklim dan
                  peringatan dini terkait bahaya iklim (banjir, angin kencang,
                  kekeringan, Demam Berdarah Dengue, dan lainnya)
                </td>
                <td>
                  <Select
                    name="f21"
                    onChange={handleChecklist}
                    value={
                      listChecklist.find(item => item.id === 'f21')?.value ||
                      '0'
                    }
                    style={{ width: '100%' }}
                  >
                    {options.map((item, index) => (
                      <MenuItem key={`option_${index}`} value={item.value}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </td>
              </tr>
              <tr>
                <td>F.2.2</td>
                <td>
                  Petugas kelurahan/desa atau kader terkait ditingkat
                  kelurahan/desa pernah mendapatkan pelatihan mengenai risiko
                  iklim
                </td>
                <td>
                  <Select
                    name="f22"
                    onChange={handleChecklist}
                    value={
                      listChecklist.find(item => item.id === 'f22')?.value ||
                      '0'
                    }
                    style={{ width: '100%' }}
                  >
                    {options.map((item, index) => (
                      <MenuItem key={`option_${index}`} value={item.value}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </td>
              </tr>
              <tr>
                <td>F.2.3</td>
                <td>
                  Petugas kelurahan/desa atau kader terkait memahami potensi
                  dampak dan risiko iklim dimasa depan diwilayah kerjanya
                </td>
                <td>
                  <Select
                    name="f23"
                    onChange={handleChecklist}
                    value={
                      listChecklist.find(item => item.id === 'f23')?.value ||
                      '0'
                    }
                    style={{ width: '100%' }}
                  >
                    {options.map((item, index) => (
                      <MenuItem key={`option_${index}`} value={item.value}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </td>
              </tr>
              <tr>
                <td>F.2.4</td>
                <td>
                  Petugas kelurahan/desa atau kader terkait ditingkat
                  kelurahan/desa pernah mendapatkan pelatihan terkait penyusunan
                  rencana aksi responsive terhadap perubahan iklim
                </td>
                <td>
                  <Select
                    name="f24"
                    onChange={handleChecklist}
                    value={
                      listChecklist.find(item => item.id === 'f24')?.value ||
                      '0'
                    }
                    style={{ width: '100%' }}
                  >
                    {options.map((item, index) => (
                      <MenuItem key={`option_${index}`} value={item.value}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </td>
              </tr>
              <tr>
                <td>F.2.5</td>
                <td>
                  Terdapat petugas Kesehatan/desa atau kader yang melakukan aksi
                  adaptasi perubahan iklim dan melakukan pelaporan evaluasi
                  secara regular kepada Tim Iklim ditingkat Kabupaten/Kota
                </td>
                <td>
                  <Select
                    name="f25"
                    onChange={handleChecklist}
                    value={
                      listChecklist.find(item => item.id === 'f25')?.value ||
                      '0'
                    }
                    style={{ width: '100%' }}
                  >
                    {options.map((item, index) => (
                      <MenuItem key={`option_${index}`} value={item.value}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </td>
              </tr>
              <tr>
                <td rowSpan={7}>
                  <span>
                    <input
                      id="f3"
                      type="checkbox"
                      readOnly
                      checked={checklist.f3}
                    />
                  </span>
                  F.3
                </td>
                <td rowSpan={7}>Penilaian Kerentanan dan Adaptasi</td>
                <td>F.3.1</td>
                <td>
                  Populasi atau kelompok rentan risiko perubahan iklim
                  teridentifikasi dan dipetakan
                </td>
                <td>
                  <Select
                    name="f31"
                    onChange={handleChecklist}
                    value={
                      listChecklist.find(item => item.id === 'f31')?.value ||
                      '0'
                    }
                    style={{ width: '100%' }}
                  >
                    {options.map((item, index) => (
                      <MenuItem key={`option_${index}`} value={item.value}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </td>
              </tr>
              <tr>
                <td>F.3.2</td>
                <td>
                  informasi indikator berkontribusi terhadap risiko iklim
                  terindentifikasi dan terpetakan (sensitivitas dan kapasitas)
                </td>
                <td>
                  <Select
                    name="f32"
                    onChange={handleChecklist}
                    value={
                      listChecklist.find(item => item.id === 'f32')?.value ||
                      '0'
                    }
                    style={{ width: '100%' }}
                  >
                    {options.map((item, index) => (
                      <MenuItem key={`option_${index}`} value={item.value}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </td>
              </tr>
              <tr>
                <td>F.3.3</td>
                <td>
                  Informasi dampak perubahan iklim di masa depan teridentifikasi
                  baik secara kualitatif maupun kuantitatif berdasarkan berbagai
                  sumber yang dapat ditelusuri
                </td>
                <td>
                  <Select
                    name="f33"
                    onChange={handleChecklist}
                    value={
                      listChecklist.find(item => item.id === 'f33')?.value ||
                      '0'
                    }
                    style={{ width: '100%' }}
                  >
                    {options.map((item, index) => (
                      <MenuItem key={`option_${index}`} value={item.value}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </td>
              </tr>
              <tr>
                <td>F.3.4</td>
                <td>
                  Penilaian kerentanan dan risiko perubahan iklim melibatkan
                  keterwakilan populasi dan kelompok rentan
                </td>
                <td>
                  <Select
                    name="f34"
                    onChange={handleChecklist}
                    value={
                      listChecklist.find(item => item.id === 'f34')?.value ||
                      '0'
                    }
                    style={{ width: '100%' }}
                  >
                    {options.map((item, index) => (
                      <MenuItem key={`option_${index}`} value={item.value}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </td>
              </tr>
              <tr>
                <td>F.3.5</td>
                <td>
                  Informasi kerentanan dan risiko perubahan iklim terhadap
                  bidang kesehatan sudah menjadi pertimbangan dalam perencanaan
                  ditingkat desa/ditingkat kabupaten/kota
                </td>
                <td>
                  <Select
                    name="f35"
                    onChange={handleChecklist}
                    value={
                      listChecklist.find(item => item.id === 'f35')?.value ||
                      '0'
                    }
                    style={{ width: '100%' }}
                  >
                    {options.map((item, index) => (
                      <MenuItem key={`option_${index}`} value={item.value}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </td>
              </tr>
              <tr>
                <td>F.3.6</td>
                <td>
                  Terdapat mekanisme regular pengukuran dampak aksi terhadap
                  penurunan risiko iklim
                </td>
                <td>
                  <Select
                    name="f36"
                    onChange={handleChecklist}
                    value={
                      listChecklist.find(item => item.id === 'f36')?.value ||
                      '0'
                    }
                    style={{ width: '100%' }}
                  >
                    {options.map((item, index) => (
                      <MenuItem key={`option_${index}`} value={item.value}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </td>
              </tr>
              <tr>
                <td>F.3.7</td>
                <td>
                  Terdapat aksi yang merespon dan mendukung kebijakan adaptasi
                  dan mitigasi perubahan iklim
                </td>
                <td>
                  <Select
                    name="f37"
                    onChange={handleChecklist}
                    value={
                      listChecklist.find(item => item.id === 'f37')?.value ||
                      '0'
                    }
                    style={{ width: '100%' }}
                  >
                    {options.map((item, index) => (
                      <MenuItem key={`option_${index}`} value={item.value}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </td>
              </tr>
              <tr>
                <td rowSpan={7}>
                  <span>
                    <input
                      id="f4"
                      type="checkbox"
                      readOnly
                      checked={checklist.f4}
                    />
                  </span>
                  F.4
                </td>
                <td rowSpan={7}>
                  Pemantauan risiko dan peringatan dini terkait bahaya iklim
                </td>
                <td>F.4.1</td>
                <td>
                  Terdapat mekanisme pelaporan dampak responsif terhadap
                  informasi cuaca dan kejadian bahaya terkait iklim
                </td>
                <td>
                  <Select
                    name="f41"
                    onChange={handleChecklist}
                    value={
                      listChecklist.find(item => item.id === 'f41')?.value ||
                      '0'
                    }
                    style={{ width: '100%' }}
                  >
                    {options.map((item, index) => (
                      <MenuItem key={`option_${index}`} value={item.value}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </td>
              </tr>
              <tr>
                <td>F.4.2</td>
                <td>
                  Terdapat upaya pencegahan penularan penyakit responsif
                  terhadap informasi cuaca/musim
                </td>
                <td>
                  <Select
                    name="f42"
                    onChange={handleChecklist}
                    value={
                      listChecklist.find(item => item.id === 'f42')?.value ||
                      '0'
                    }
                    style={{ width: '100%' }}
                  >
                    {options.map((item, index) => (
                      <MenuItem key={`option_${index}`} value={item.value}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </td>
              </tr>
              <tr>
                <td>F.4.3</td>
                <td>
                  Terdapat akses informasi peringatan dini bahaya terkait iklim
                  dalam periode jangka pendek (kurang dari 14 hari)
                </td>
                <td>
                  <Select
                    name="f43"
                    onChange={handleChecklist}
                    value={
                      listChecklist.find(item => item.id === 'f43')?.value ||
                      '0'
                    }
                    style={{ width: '100%' }}
                  >
                    {options.map((item, index) => (
                      <MenuItem key={`option_${index}`} value={item.value}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </td>
              </tr>
              <tr>
                <td>F.4.4</td>
                <td>
                  Terdapat akses informasi peringatan dini bahaya terkait iklim
                  dalam periode jangka menengah (kurang dari 3 bulan)
                </td>
                <td>
                  <Select
                    name="f44"
                    onChange={handleChecklist}
                    value={
                      listChecklist.find(item => item.id === 'f44')?.value ||
                      '0'
                    }
                    style={{ width: '100%' }}
                  >
                    {options.map((item, index) => (
                      <MenuItem key={`option_${index}`} value={item.value}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </td>
              </tr>
              <tr>
                <td>F.4.5</td>
                <td>
                  Terdapat panduan atau SOP merespon informasi peringatan dini
                  bahaya terkait iklim
                </td>
                <td>
                  <Select
                    name="f45"
                    onChange={handleChecklist}
                    value={
                      listChecklist.find(item => item.id === 'f45')?.value ||
                      '0'
                    }
                    style={{ width: '100%' }}
                  >
                    {options.map((item, index) => (
                      <MenuItem key={`option_${index}`} value={item.value}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </td>
              </tr>
              <tr>
                <td>F.4.6</td>
                <td>
                  Terdapat mekanisme respon menindaklanjuti informasi peringatan
                  dini
                </td>
                <td>
                  <Select
                    name="f46"
                    onChange={handleChecklist}
                    value={
                      listChecklist.find(item => item.id === 'f46')?.value ||
                      '0'
                    }
                    style={{ width: '100%' }}
                  >
                    {options.map((item, index) => (
                      <MenuItem key={`option_${index}`} value={item.value}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </td>
              </tr>
              <tr>
                <td>F.4.7</td>
                <td>Informasi peringatan dini dapat diakses 24 jam</td>
                <td>
                  <Select
                    name="f47"
                    onChange={handleChecklist}
                    value={
                      listChecklist.find(item => item.id === 'f47')?.value ||
                      '0'
                    }
                    style={{ width: '100%' }}
                  >
                    {options.map((item, index) => (
                      <MenuItem key={`option_${index}`} value={item.value}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </td>
              </tr>
              <tr>
                <td rowSpan={4}>
                  <span>
                    <input
                      id="f5"
                      type="checkbox"
                      readOnly
                      checked={checklist.f5}
                    />
                  </span>
                  F.5
                </td>
                <td rowSpan={4}>
                  Teknologi dan infrastruktur yang tahan iklim dan berkelanjutan
                </td>
                <td>F.5.1</td>
                <td>
                  Infrastruktur bangunan penduduk memerhatikan tingkat ancaman
                  bahaya iklim
                </td>
                <td>
                  <Select
                    name="f51"
                    onChange={handleChecklist}
                    value={
                      listChecklist.find(item => item.id === 'f51')?.value ||
                      '0'
                    }
                    style={{ width: '100%' }}
                  >
                    {options.map((item, index) => (
                      <MenuItem key={`option_${index}`} value={item.value}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </td>
              </tr>
              <tr>
                <td>F.5.2</td>
                <td>
                  Keberadaan fasilitas komunal (misalnya toilet umum) tahan
                  terhadap bahaya iklim dan tidak terpengruhi oleh kondisi iklim
                  musiman
                </td>
                <td>
                  <Select
                    name="f52"
                    onChange={handleChecklist}
                    value={
                      listChecklist.find(item => item.id === 'f52')?.value ||
                      '0'
                    }
                    style={{ width: '100%' }}
                  >
                    {options.map((item, index) => (
                      <MenuItem key={`option_${index}`} value={item.value}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </td>
              </tr>
              <tr>
                <td>F.5.3</td>
                <td>
                  Terdapat akses telekomunikasi yang dapat dikonakan secara 24
                  jam pada kondisi iklim ekstrim berlangsung
                </td>
                <td>
                  <Select
                    name="f53"
                    onChange={handleChecklist}
                    value={
                      listChecklist.find(item => item.id === 'f53')?.value ||
                      '0'
                    }
                    style={{ width: '100%' }}
                  >
                    {options.map((item, index) => (
                      <MenuItem key={`option_${index}`} value={item.value}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </td>
              </tr>
              <tr>
                <td>F.5.4</td>
                <td>
                  Terdapat inftrastuktur yang rendah emisi karbon (ramah iklim)
                </td>
                <td>
                  <Select
                    name="f54"
                    onChange={handleChecklist}
                    value={
                      listChecklist.find(item => item.id === 'f54')?.value ||
                      '0'
                    }
                    style={{ width: '100%' }}
                  >
                    {options.map((item, index) => (
                      <MenuItem key={`option_${index}`} value={item.value}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </td>
              </tr>
              <tr>
                <td rowSpan={3}>
                  <span>
                    <input
                      id="f6"
                      type="checkbox"
                      readOnly
                      checked={checklist.f6}
                    />
                  </span>
                  F.6
                </td>
                <td rowSpan={3}>Manajemen Determinan Kesehatan Lingkungan</td>
                <td>F.6.1</td>
                <td>
                  Energi bersih (panel surya, nanohydro) dipromosikan dalam aksi
                  ramah iklim
                </td>
                <td>
                  <Select
                    name="f61"
                    onChange={handleChecklist}
                    value={
                      listChecklist.find(item => item.id === 'f61')?.value ||
                      '0'
                    }
                    style={{ width: '100%' }}
                  >
                    {options.map((item, index) => (
                      <MenuItem key={`option_${index}`} value={item.value}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </td>
              </tr>
              <tr>
                <td>F.6.2</td>
                <td>
                  Terdapat rencana pengelolaan air aman tahan kondisi
                  iklim/cuaca ekstrim untuk mengurasi dampak turunan bahaya
                  iklim
                </td>
                <td>
                  <Select
                    name="f62"
                    onChange={handleChecklist}
                    value={
                      listChecklist.find(item => item.id === 'f62')?.value ||
                      '0'
                    }
                    style={{ width: '100%' }}
                  >
                    {options.map((item, index) => (
                      <MenuItem key={`option_${index}`} value={item.value}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </td>
              </tr>
              <tr>
                <td>F.6.3</td>
                <td>Terdapat akses pangan yang tahan kondisi iklim ekstrim</td>
                <td>
                  <Select
                    name="f63"
                    onChange={handleChecklist}
                    value={
                      listChecklist.find(item => item.id === 'f63')?.value ||
                      '0'
                    }
                    style={{ width: '100%' }}
                  >
                    {options.map((item, index) => (
                      <MenuItem key={`option_${index}`} value={item.value}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </td>
              </tr>
              <tr>
                <td rowSpan={3}>
                  <span>
                    <input
                      id="f7"
                      type="checkbox"
                      readOnly
                      checked={checklist.f7}
                    />
                  </span>
                  F.7
                </td>
                <td rowSpan={3}>Kesiapsiagaan dan manajemen kedaruratan</td>
                <td>F.7.1</td>
                <td>
                  Terdapat strategi pengurangan risiko iklim merespon dampak
                  jangka pendek dan jangka panjang
                </td>
                <td>
                  <Select
                    name="f71"
                    onChange={handleChecklist}
                    value={
                      listChecklist.find(item => item.id === 'f71')?.value ||
                      '0'
                    }
                    style={{ width: '100%' }}
                  >
                    {options.map((item, index) => (
                      <MenuItem key={`option_${index}`} value={item.value}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </td>
              </tr>
              <tr>
                <td>F.7.2</td>
                <td>
                  Pemantauan cuaca, produk nowcasting, dan prakiraan cuaca
                  memicu rencana kesiapsiagaan dan Standar Operasi Prosedur
                  untuk peristiwa cuaca ekstrem
                </td>
                <td>
                  <Select
                    name="f72"
                    onChange={handleChecklist}
                    value={
                      listChecklist.find(item => item.id === 'f72')?.value ||
                      '0'
                    }
                    style={{ width: '100%' }}
                  >
                    {options.map((item, index) => (
                      <MenuItem key={`option_${index}`} value={item.value}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </td>
              </tr>
              <tr>
                <td>F.7.3</td>
                <td>
                  Peningkatan kapasitas sebagai pengetahuan masyarakat dalam
                  pengurangan resiko bencana melalui Mitigasi bencana yang
                  dilakukan oleh Dinas Kesehatan terkait.
                </td>
                <td>
                  <Select
                    name="f73"
                    onChange={handleChecklist}
                    value={
                      listChecklist.find(item => item.id === 'f73')?.value ||
                      '0'
                    }
                    style={{ width: '100%' }}
                  >
                    {options.map((item, index) => (
                      <MenuItem key={`option_${index}`} value={item.value}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </td>
              </tr>
              <tr>
                <td>
                  <span>
                    <input
                      id="f8"
                      type="checkbox"
                      readOnly
                      checked={checklist.f8}
                    />
                  </span>
                  F.8
                </td>
                <td>Pendanaan</td>
                <td>F.8.1</td>
                <td>
                  Terdapat pendanaan di tingkat desa/kelurahan berkaitan dengan
                  aksi adaptasi maupun mitigasi perubahan iklim merespon potensi
                  dampak pada bidang kesehatan
                </td>
                <td>
                  <Select
                    name="f81"
                    onChange={handleChecklist}
                    value={
                      listChecklist.find(item => item.id === 'f81')?.value ||
                      '0'
                    }
                    style={{ width: '100%' }}
                  >
                    {options.map((item, index) => (
                      <MenuItem key={`option_${index}`} value={item.value}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{
              backgroundColor: '#00A6A6',
              color: '#FFFFFF',
            }}
            onClick={handleSubmit}
            disabled={isLoading}
          >
            Simpan
          </Button>
        </div>
      </div>
    </>
  );
}

export default FormDesaDesi;

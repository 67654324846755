import React, { Children, cloneElement, isValidElement, useState } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import PropTypes from 'prop-types';

// Css & js Style
import styleCss from 'assets/css/views/global.module.css';

// Icons
import CheckIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/Error';

// core components
import Snackbar from 'components/Snackbar/Snackbar';
import Navbar from 'components/Navbars/Navbar';

// Home Component Definition
function MapLayout({ children, drawerToggle }) {
  // Notification
  const [notification, setAlert] = useState({});

  // - Information Bar
  const [isInformationBarVisible, setInformationBarVisible] = useState(false);

  // Loading
  const [isLoadOverlay, setIsLoadOverlay] = useState(false);

  // Event handlers
  // - Loading overlay
  const toggleLoadingOverlay = (forcedState = !isLoadOverlay) =>
    setIsLoadOverlay(forcedState);

  // - Information Bar
  const toggleInfomationbar = (forcedState = !isInformationBarVisible) =>
    setInformationBarVisible(forcedState);

  // - Notifications
  // -- close
  const closeNotification = () => {
    setAlert({
      isAlertOpen: false,
      alertState: 'danger',
    });
  };

  // -- open
  const openNotification = (condition, Message) => {
    if (condition === 'success') {
      setAlert({
        isAlertOpen: true,
        alertState: 'success',
        AlertMessage: Message,
      });
    } else {
      setAlert({
        isAlertOpen: true,
        alertState: 'danger',
        AlertMessage: Message,
      });
    }
    setTimeout(() => {
      closeNotification();
    }, 5000);
  };

  // clone children with props
  const childrenWithProps = Children.map(children, child =>
    isValidElement(child)
      ? cloneElement(child, {
          notification,
          closeNotification,
          openNotification,
          isLoadOverlay,
          toggleLoadingOverlay,
          isInformationBarVisible,
          toggleInfomationbar,
        })
      : child,
  );

  // const used for render
  const navbarWidth = isInformationBarVisible ? 'calc(100% - 400px)' : '100%';
  const snackbarIcon =
    notification.alertState === 'success' ? CheckIcon : ErrorIcon;

  return (
    <div className={styleCss.mapContainer}>
      <Navbar width={navbarWidth} handleDrawerToggle={drawerToggle} />
      <LoadingOverlay
        active={isLoadOverlay}
        spinner
        text="Sedang menyimpan data..."
        style={{ color: 'white' }}
      />
      <Snackbar
        place="tc"
        color={notification.alertState}
        icon={snackbarIcon}
        message={notification.AlertMessage || ''}
        open={notification.isAlertOpen}
        closeNotification={closeNotification}
        close
      />
      {childrenWithProps}
      <div className={styleCss.footerHome}>@2020 Copyright APIK</div>
    </div>
  );
}

MapLayout.propTypes = {
  children: PropTypes.node,
  drawerToggle: PropTypes.func,
};

export default MapLayout;

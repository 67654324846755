import React, { forwardRef, useEffect, useState } from 'react';
import api from 'config/api';
import LoadingOverlay from 'react-loading-overlay';
import { useHistory } from 'react-router-dom';
import Snackbar from 'components/Snackbar/Snackbar.js';

// @material-ui/core
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import { Typography } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';

// @material-ui/icons
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/Error';

// css
import styleCss from 'assets/css/views/global.module.css';

import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';

export default function EditProfilAdminDaerah() {
  const history = useHistory();
  // states
  const [alert, setAlert] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogSubmit, setOpenDialogSubmit] = useState(false);
  const [openDialogYa, setOpenDialogYa] = useState(false);
  const [dataUser, setDataUser] = useState({});
  const [loadUser, setLoadUser] = useState(true);
  const [informasiAkun, setInformasiAkun] = useState({
    kabkot: '',
    email: '',
    kontak: '',
  });
  const [informasiDinas, setInformasiDinas] = useState({
    kepdin: '',
    alamat: '',
    penanggungJawab: '',
  });
  const [informasiKesehatan, setInformasiKesehatan] = useState({});
  const [informasiWilayah, setInformasiWilayah] = useState({});

  const [state, setState] = useState({
    checkedA: false,
    checkedB: false,
    checkedF: false,
    checkedG: false,
  });
  const [values, setValues] = useState({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showPassword: false,
  });

  function closeNotification() {
    setAlert({
      isAlertOpen: false,
      alertState: 'danger',
    });
  }

  const handleChangePassword = prop => event => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  const handleMouseDownPassword = event => {
    event.preventDefault();
  };
  const handleChangeCheckBox = event => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleClose = () => {
    setOpenDialog(false);
    setOpenDialogYa(false);
    setOpenDialogSubmit(false);
  };

  const handleClickOpenSubmit = () => {
    setOpenDialogSubmit(true);
  };

  const handleCloseSubmit = () => {
    setOpenDialogSubmit(false);
  };

  const handleChangeIA = e => {
    setInformasiAkun({ ...informasiAkun, [e.target.name]: e.target.value });
  };

  const handleChangeID = e => {
    setInformasiDinas({ ...informasiDinas, [e.target.name]: e.target.value });
  };

  const handleChangeIK = e => {
    setInformasiKesehatan({
      ...informasiKesehatan,
      [e.target.name]: e.target.value,
    });
  };
  const handleChangeIW = e => {
    setInformasiWilayah({
      ...informasiWilayah,
      [e.target.name]: e.target.value,
    });
  };

  const getUserByEmail = email => {
    setLoadUser(true);
    setIsLoading(true);
    api
      .getUserByEmail(email)
      .then(res => {
        if (res.data.error_code === 0) {
          const datauser = res.data.data[0];
          const data = datauser.data ? datauser.data : {};

          if (Object.keys(data).length !== 0) {
            setInformasiAkun({
              kabkot: datauser.wilayah__name,
              email: datauser.email,
              kontak: data.kontak, // not yet exist
            });
            if (
              data.info_kesehatan !== undefined &&
              Object.keys(data.info_kesehatan).length !== 0
            ) {
              setInformasiKesehatan({
                rumahSakit: data.info_kesehatan.rumahSakit,
                klinik: data.info_kesehatan.klinik,
                puskesmas: data.info_kesehatan.puskesmas,
                lainnya: data.info_kesehatan.lainnya,
              });
            } else {
              setInformasiKesehatan({
                rumahSakit: '',
                klinik: '',
                puskesmas: '',
                lainnya: '',
              });
            }
            if (
              data.info_dinas !== undefined &&
              Object.keys(data.info_dinas).length !== 0
            ) {
              setInformasiDinas({
                kepdin: data.info_dinas.kepdin,
                alamat: data.info_dinas.alamat,
                penanggungJawab: data.info_dinas.penanggungJawab,
              });
            } else {
              setInformasiDinas({
                kepdin: '',
                alamat: '',
                penanggungJawab: '',
              });
            }
            if (
              data.info_geografi !== undefined &&
              Object.keys(data.info_geografi).length !== 0
            ) {
              setInformasiWilayah({
                bb: data.info_geografi.bb,
                bu: data.info_geografi.bu,
                bs: data.info_geografi.bs,
                bt: data.info_geografi.bt,
              });
            } else {
              setInformasiWilayah({
                bb: '',
                bu: '',
                bs: '',
                bt: '',
              });
            }
            if (
              data.administrasi_wilayah !== undefined &&
              Object.keys(data.administrasi_wilayah).length !== 0
            ) {
              const { administrasi_wilayah } = data;

              setInformasiWilayah({
                ...informasiWilayah,
                desa: parseInt(administrasi_wilayah.total_desa, 10) || 0,
                kec: parseInt(administrasi_wilayah.total_kec, 10) || 0,
                kel: parseInt(administrasi_wilayah.total_kel, 10) || 0,
              });
            } else {
              setInformasiWilayah({
                ...informasiWilayah,
                kec: '-',
                kel: '-',
                desa: '-',
              });
            }
            if (
              data.penyakit !== undefined &&
              Object.keys(data.penyakit).length === 0
            ) {
              setState({
                checkedA: false,
                checkedB: false,
                checkedC: false,
                checkedD: false,
              });
            } else {
              setState({
                checkedC: data.penyakit.includes('Pneumania'),
                checkedD: data.penyakit.includes('Diare'),
                checkedA: data.penyakit.includes('Malaria'),
                checkedB: data.penyakit.includes('DBD'),
              });
            }
            datauser.data = data;
          }
          console.log(datauser, 'isi data user');
          setDataUser(datauser);
          setLoadUser(false);
          setIsLoading(false);
        } else {
          history.push('home');
        }
      })
      .catch(err => {
        setLoadUser(false);
        console.log('Errorr:', err);
      });
  };

  const doUpdate = () => {
    let penyakit = [];
    if (state.checkedA) {
      penyakit.push('Malaria');
    }
    if (state.checkedB) {
      penyakit.push('DBD');
    }
    if (state.checkedC) {
      penyakit.push('Pneumania');
    }
    if (state.checkedD) {
      penyakit.push('Diare');
    }
    console.log(informasiWilayah);
    let dUpdate = {
      id: dataUser.id,
      name: 'Administrator Daerah',
      email: informasiAkun.email,
      image: null,
      data: {
        kontak: informasiAkun.kontak,
        info_geografi: {
          bb: informasiWilayah.bb,
          bs: informasiWilayah.bs,
          bu: informasiWilayah.bu,
          bt: informasiWilayah.bt,
        },
        info_kesehatan: {
          rumahSakit: informasiKesehatan.rumahSakit,
          klinik: informasiKesehatan.klinik,
          puskesmas: informasiKesehatan.puskesmas,
          lainnya: informasiKesehatan.lainnya,
        },
        administrasi_wilayah: {
          total_kec: informasiWilayah.kec,
          total_kel: informasiWilayah.kel,
          total_desa: informasiWilayah.desa,
        },
        penyakit: penyakit,
        info_dinas: {
          kepdin: informasiDinas.kepdin,
          alamat: informasiDinas.alamat,
          penanggungJawab: informasiDinas.penanggungJawab,
        },
      },
      wilayah_id: dataUser.wilayah_id,
      active: true,
    };
    setIsLoading(true);
    api.updateProfile(dUpdate).then(res => {
      if (res.data.error_code === 0) {
        setOpenDialogYa(true);
        setOpenDialogSubmit(false);
        setIsLoading(false);
      }
    });
  };

  const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  useEffect(() => {
    if (!localStorage.getItem('token')) history.push('home');
    getUserByEmail(localStorage.getItem('email'));
  }, []);

  return (
    <div className={styleCss.ContainerEditProfil}>
      <Snackbar
        place="tc"
        color={alert.alertState}
        icon={alert.alertState === 'success' ? CheckIcon : ErrorIcon}
        message={alert.AlertMessage}
        open={alert.isAlertOpen}
        closeNotification={() => closeNotification()}
        close
      />
      <LoadingOverlay
        active={isLoading}
        spinner
        text="Sedang melakukan pengecekan..."
        style={{ color: 'white' }}
      ></LoadingOverlay>

      <div
        style={{
          marginTop: '90px',
          marginLeft: '-170px',
          marginBottom: '-10px',
        }}
      >
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Link
            color="inherit"
            onClick={() => history.push('/profile')}
            className={styleCss.Link}
          >
            <ArrowBackIosIcon className={styleCss.IconBreadcrumbs} />
            Kembali ke Profil
          </Link>
        </Breadcrumbs>
      </div>

      <CardBody>
        <Card className={styleCss.CardEditProfil}>
          <div style={{ margin: '0px 30px' }}>
            <div className={styleCss.DivJudulEditUbah}>
              <Typography className={styleCss.EditProfil}>
                Edit Profil
              </Typography>
              <label>Ubah informasi profil akunmu di bawah ini</label>
            </div>
            <GridContainer>
              <GridItem md={6}>
                <div style={{ margin: '10px 10px' }}>
                  <div>
                    <div className={styleCss.borderLabel}>
                      <h5 className={styleCss.LabelEditProfilAkun}>
                        Informasi Akun
                      </h5>
                      <GridContainer>
                        <GridItem md={6}>
                          <div
                            style={{ marginLeft: '30px', marginBottom: '30px' }}
                          >
                            <IconButton className={styleCss.IconEditProfil}>
                              <Typography className={styleCss.nameEditProfil}>
                                B
                              </Typography>
                            </IconButton>
                          </div>
                        </GridItem>
                        <GridItem md={6}>
                          <Button className={styleCss.ButtonCopot}>
                            Copot
                          </Button>
                          <br />
                          <Button className={styleCss.ButtonUbah}>Ubah</Button>
                        </GridItem>
                      </GridContainer>
                      <div style={{ margin: '0px 11px' }}>
                        <Typography
                          className={styleCss.TypographyInformasiAkun}
                        >
                          Kota / Kabupaten
                        </Typography>
                        <Input
                          className={styleCss.InputInformasiAkun}
                          name="kabkot"
                          value={informasiAkun.kabkot}
                          readOnly
                          placeholder="Kota/Kabupaten"
                        ></Input>
                        <Typography
                          className={styleCss.TypographyInformasiAkun}
                        >
                          Email
                        </Typography>
                        <Input
                          className={styleCss.InputInformasiAkun}
                          name="email"
                          value={informasiAkun.email}
                          onChange={e => handleChangeIA(e)}
                          placeholder="Email"
                        ></Input>
                        <Typography
                          className={styleCss.TypographyInformasiAkun}
                        >
                          Nomor Kontak
                        </Typography>
                        <Input
                          className={styleCss.InputInformasiAkun}
                          name="kontak"
                          value={informasiAkun.kontak}
                          onChange={e => handleChangeIA(e)}
                          placeholder="Nomor Kontak"
                        ></Input>

                        <div>
                          {/* <Typography onClick={handleClickOpen} className={styleCss.TypographyUbahPassword}>Ubah Password</Typography> */}

                          <Dialog
                            className={styleCss.dialogPopUpUbahPassword}
                            open={openDialog}
                            // TransitionComponent={Transition}
                            // keepMounted
                            aria-labelledby="alert-dialog-slide-title"
                            aria-describedby="alert-dialog-slide-description"
                          >
                            <DialogTitle id="alert-dialog-slide-title">
                              <IconButton
                                aria-label="close"
                                className={styleCss.closeButtonDialog}
                                onClick={handleClose}
                              >
                                <CloseIcon />
                              </IconButton>
                            </DialogTitle>
                            <div className={styleCss.divDataUploadDataJudul}>
                              <Typography
                                className={styleCss.JudulDialogUploadData}
                              >
                                Edit Password
                              </Typography>
                              <label>Silahkan ketik email dan password</label>
                            </div>

                            <DialogContent
                              className={styleCss.dialogPopUpConntenDataUpload}
                            >
                              <Typography
                                className={styleCss.TypographyInformasiAkun}
                              >
                                Username/Email
                              </Typography>
                              <Input
                                className={styleCss.InputInformasiAkun}
                                placeholder="Type here..."
                              ></Input>
                              <Typography
                                className={styleCss.TypographyInformasiAkun}
                              >
                                Password Lama
                              </Typography>
                              <FormControl style={{ width: '100%' }}>
                                <Input
                                  placeholder="Type here..."
                                  id="standard-adornment-password"
                                  type={
                                    values.showPassword ? 'text' : 'password'
                                  }
                                  value={values.password}
                                  onChange={handleChangePassword('password')}
                                  endAdornment={
                                    <InputAdornment position="end">
                                      <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                      >
                                        {values.showPassword ? (
                                          <Visibility />
                                        ) : (
                                          <VisibilityOff />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  }
                                />
                              </FormControl>

                              <Typography
                                className={styleCss.TypographyInformasiAkun}
                              >
                                Password Baru
                              </Typography>
                              <FormControl style={{ width: '100%' }}>
                                <Input
                                  placeholder="Type here..."
                                  id="standard-adornment-password"
                                  type={
                                    values.showPassword ? 'text' : 'password'
                                  }
                                  value={values.password}
                                  onChange={handleChangePassword('password')}
                                  endAdornment={
                                    <InputAdornment position="end">
                                      <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                      >
                                        {values.showPassword ? (
                                          <Visibility />
                                        ) : (
                                          <VisibilityOff />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  }
                                />
                              </FormControl>
                              <Typography
                                className={styleCss.TypographyInformasiAkun}
                              >
                                Ketik Ulang Password Baru
                              </Typography>
                              <FormControl style={{ width: '100%' }}>
                                <Input
                                  placeholder="Type here..."
                                  id="standard-adornment-password"
                                  type={
                                    values.showPassword ? 'text' : 'password'
                                  }
                                  value={values.password}
                                  onChange={handleChangePassword('password')}
                                  endAdornment={
                                    <InputAdornment position="end">
                                      <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                      >
                                        {values.showPassword ? (
                                          <Visibility />
                                        ) : (
                                          <VisibilityOff />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  }
                                />
                              </FormControl>
                              <Button className={styleCss.submitUbahPassword}>
                                Submit
                              </Button>
                            </DialogContent>
                          </Dialog>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div style={{ marginTop: '30px', marginBottom: '30px' }}>
                    <div className={styleCss.borderLabel}>
                      <h5 className={styleCss.LabelEditProfilInformasiDinas}>
                        Informasi Dinas
                      </h5>
                      <div style={{ margin: '0px 11px' }}>
                        <Typography
                          className={styleCss.TypographyInformasiAkun}
                        >
                          Pengelola
                        </Typography>
                        <Input
                          className={styleCss.InputInformasiAkun}
                          name="kepdin"
                          value={informasiDinas.kepdin}
                          onChange={e => handleChangeID(e)}
                          placeholder="Nama Pengelola"
                        ></Input>
                        <Typography
                          className={styleCss.TypographyInformasiAkun}
                        >
                          Jabatan
                        </Typography>
                        <Input
                          className={styleCss.InputInformasiAkun}
                          name="alamat"
                          value={informasiDinas.alamat}
                          onChange={e => handleChangeID(e)}
                          placeholder="Jabatan"
                        ></Input>
                        <Typography
                          className={styleCss.TypographyInformasiAkun}
                        >
                          NIP
                        </Typography>
                        <Input
                          className={styleCss.InputInformasiAkun}
                          name="penanggungJawab"
                          value={informasiDinas.penanggungJawab}
                          onChange={e => handleChangeID(e)}
                          placeholder="Nama NIP"
                        ></Input>
                      </div>
                    </div>
                  </div>
                </div>
              </GridItem>
              <GridItem md={6}>
                <div style={{ margin: '10px 10px' }}>
                  <div className={styleCss.borderLabel}>
                    <h5 className={styleCss.LabelEditProfil}>
                      Informasi Kesehatan
                    </h5>

                    <div style={{ margin: '0px 11px' }}>
                      <Typography className={styleCss.TypografiJumlahFasyankes}>
                        Jumlah Fasyankes
                      </Typography>
                      <div style={{ marginLeft: ' 11px' }}>
                        <GridContainer>
                          <GridItem md={4} xs={12}>
                            <label className={styleCss.LabelFasyankes}>
                              Rumah Sakit
                            </label>
                          </GridItem>
                          <GridItem md={8} xs={12}>
                            <input
                              className={styleCss.InputJumlahFasyankes}
                              type="number"
                              name="rumahSakit"
                              value={informasiKesehatan.rumahSakit}
                              onChange={e => handleChangeIK(e)}
                              id="outlined-basic"
                              variant="outlined"
                              placeholder="X"
                            ></input>
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem md={4} xs={12}>
                            <label className={styleCss.LabelFasyankes}>
                              Klinik
                            </label>
                          </GridItem>
                          <GridItem md={8} xs={12}>
                            <input
                              className={styleCss.InputJumlahFasyankes}
                              type="number"
                              name="klinik"
                              value={informasiKesehatan.klinik}
                              onChange={e => handleChangeIK(e)}
                              id="outlined-basic"
                              variant="outlined"
                              placeholder="X"
                            ></input>
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem md={4} xs={12}>
                            <label className={styleCss.LabelFasyankes}>
                              Puskesmas
                            </label>
                          </GridItem>
                          <GridItem md={8} xs={12}>
                            <input
                              className={styleCss.InputJumlahFasyankes}
                              id="outlined-basic"
                              type="number"
                              name="puskesmas"
                              value={informasiKesehatan.puskesmas}
                              onChange={e => handleChangeIK(e)}
                              variant="outlined"
                              placeholder="X"
                            ></input>
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem md={4} xs={12}>
                            <label className={styleCss.LabelFasyankes}>
                              Lainnya
                            </label>
                          </GridItem>
                          <GridItem md={8} xs={12}>
                            <input
                              className={styleCss.InputJumlahFasyankes}
                              type="number"
                              name="lainnya"
                              value={informasiKesehatan.lainnya}
                              onChange={e => handleChangeIK(e)}
                              id="outlined-basic"
                              variant="outlined"
                              placeholder="X"
                            ></input>
                          </GridItem>
                        </GridContainer>
                      </div>
                      <Typography className={styleCss.TypografiPrioritas}>
                        Penyakit Prioritas
                      </Typography>

                      <div style={{ margin: '0px 11px' }}>
                        <GridContainer>
                          <GridItem md={6} xs={12}>
                            <FormGroup>
                              <FormControlLabel
                                className={styleCss.labelForm}
                                control={
                                  <Checkbox
                                    onChange={handleChangeCheckBox}
                                    color="default"
                                    name="checkedA"
                                    checked={state.checkedA}
                                  />
                                }
                                label="Malaria"
                              />
                              <FormControlLabel
                                className={styleCss.labelForm}
                                control={
                                  <Checkbox
                                    onChange={handleChangeCheckBox}
                                    color="default"
                                    name="checkedB"
                                    checked={state.checkedB}
                                  />
                                }
                                label="DBD"
                              />
                            </FormGroup>
                          </GridItem>
                          <GridItem md={6} xs={12}>
                            <FormGroup>
                              <FormControlLabel
                                className={styleCss.labelForm}
                                control={
                                  <Checkbox
                                    onChange={handleChangeCheckBox}
                                    color="default"
                                    name="checkedC"
                                    checked={state.checkedC}
                                  />
                                }
                                label="Pneumania"
                              />
                              <FormControlLabel
                                className={styleCss.labelForm}
                                control={
                                  <Checkbox
                                    onChange={handleChangeCheckBox}
                                    color="default"
                                    name="checkedD"
                                    checked={state.checkedD}
                                  />
                                }
                                label="Diare"
                              />
                            </FormGroup>
                          </GridItem>
                        </GridContainer>
                      </div>
                    </div>
                  </div>

                  <div style={{ marginTop: '30px ' }}>
                    <div className={styleCss.borderLabel}>
                      <h5 className={styleCss.LabelEditProfil}>
                        Informasi Wilayah
                      </h5>

                      <div style={{ margin: '0px 11px' }}>
                        <Typography
                          className={styleCss.TypografiJumlahFasyankes}
                        >
                          Administrasi Wilayah
                        </Typography>
                        <div style={{ marginLeft: ' 11px' }}>
                          <GridContainer>
                            <GridItem md={4} xs={12}>
                              <label className={styleCss.LabelFasyankes}>
                                Jumlah Kecamatan
                              </label>
                            </GridItem>
                            <GridItem md={8} xs={12}>
                              <input
                                className={styleCss.InputJumlahFasyankes}
                                type="number"
                                name="kec"
                                value={informasiWilayah.kec}
                                onChange={e => handleChangeIW(e)}
                                id="outlined-basic"
                                variant="outlined"
                                placeholder="X"
                              ></input>
                            </GridItem>
                          </GridContainer>
                          <GridContainer>
                            <GridItem md={4} xs={12}>
                              <label className={styleCss.LabelFasyankes}>
                                Jumlah Kelurahan
                              </label>
                            </GridItem>
                            <GridItem md={8} xs={12}>
                              <input
                                className={styleCss.InputJumlahFasyankes}
                                type="number"
                                name="kel"
                                value={informasiWilayah.kel}
                                onChange={e => handleChangeIW(e)}
                                type="number"
                                id="outlined-basic"
                                variant="outlined"
                                placeholder="X"
                              ></input>
                            </GridItem>
                          </GridContainer>
                          <GridContainer>
                            <GridItem md={4} xs={12}>
                              <label className={styleCss.LabelFasyankes}>
                                Jumlah Desa
                              </label>
                            </GridItem>
                            <GridItem md={8} xs={12}>
                              <input
                                className={styleCss.InputJumlahFasyankes}
                                name="desa"
                                value={informasiWilayah.desa}
                                onChange={e => handleChangeIW(e)}
                                type="number"
                                id="outlined-basic"
                                variant="outlined"
                                placeholder="X"
                              ></input>
                            </GridItem>
                          </GridContainer>
                        </div>
                        <Typography className={styleCss.TypografiPrioritas}>
                          Geografis Wilayah
                        </Typography>

                        <div style={{ margin: '0px 11px' }}>
                          {/* <FormControlLabel
                                                        className={styleCss.labelForm}
                                                        control={<Checkbox onChange={handleChangeCheckBox} color="default" name="checkedInputOtomatis" />}
                                                        label="Input Otomatis"
                                                        style={{ color: '#aaaaaa !important' }}
                                                    /> */}
                          <div
                            style={{
                              margin: ' 0px 11px',
                              marginBottom: '11px',
                            }}
                          >
                            <GridContainer>
                              <GridItem md={6} xs={12}>
                                <input
                                  className={styleCss.inputGeografis}
                                  placeholder="Batas Utara"
                                  name="bu"
                                  value={informasiWilayah.bu}
                                  onChange={e => handleChangeIW(e)}
                                />
                                <input
                                  className={styleCss.inputGeografis}
                                  placeholder="Batas Selatan"
                                  name="bs"
                                  value={informasiWilayah.bs}
                                  onChange={e => handleChangeIW(e)}
                                />
                              </GridItem>
                              <GridItem md={6} xs={12}>
                                <input
                                  className={styleCss.inputGeografis}
                                  placeholder="Batas Barat"
                                  name="bb"
                                  value={informasiWilayah.bb}
                                  onChange={e => handleChangeIW(e)}
                                />
                                <input
                                  className={styleCss.inputGeografis}
                                  placeholder="Batas Timur"
                                  name="bt"
                                  value={informasiWilayah.bt}
                                  onChange={e => handleChangeIW(e)}
                                />
                              </GridItem>
                            </GridContainer>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Button
                  className={styleCss.submmiteditProfilAdminDaerah}
                  onClick={handleClickOpenSubmit}
                >
                  Submit
                </Button>

                <div>
                  <Dialog
                    className={styleCss.dialogPopUpUbahPassword}
                    open={openDialogSubmit}
                    // TransitionComponent={Transition}
                    // keepMounted
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                  >
                    <DialogTitle id="alert-dialog-slide-title"></DialogTitle>
                    <div className={styleCss.divKonfirmasiTindakan}>
                      <Typography className={styleCss.JudulDialogUploadData}>
                        Konfirmasi Tindakan
                      </Typography>
                      <label>Apakah anda yakin melanjutkan tindakan ?</label>
                    </div>

                    <DialogContent
                      className={styleCss.dialogPopUpConntenDataUpload}
                    >
                      <div className={styleCss.divButtonKonfirmasi}>
                        <Button
                          onClick={handleCloseSubmit}
                          className={styleCss.buttonKonfirmasiTidak}
                        >
                          Tidak
                        </Button>
                        <Button
                          onClick={() => doUpdate()}
                          className={styleCss.buttonKonfirmasiYa}
                        >
                          Ya
                        </Button>
                      </div>
                    </DialogContent>
                  </Dialog>
                  <div>
                    <Dialog
                      className={styleCss.dialogPopUpUbahPassword}
                      open={openDialogYa}
                      // TransitionComponent={Transition}
                      // keepMounted
                      aria-labelledby="alert-dialog-slide-title"
                      aria-describedby="alert-dialog-slide-description"
                    >
                      <DialogTitle id="alert-dialog-slide-title">
                        <IconButton
                          aria-label="close"
                          className={styleCss.closeButtonDialog}
                          onClick={handleClose}
                        >
                          <CloseIcon />
                        </IconButton>
                      </DialogTitle>
                      <div className={styleCss.divKonfirmasiTindakan}>
                        <Typography className={styleCss.JudulDialogUploadData}>
                          Pembaharuan Profil Berhasil
                        </Typography>
                        <label>
                          Terima Kasih telah memperbaharui profil daerah anda.
                        </label>
                      </div>

                      <DialogContent
                        className={styleCss.dialogPopUpConntenDataUpload}
                      ></DialogContent>
                    </Dialog>
                  </div>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </Card>
      </CardBody>
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// core components
import Admin from 'layouts/Admin.js';
import MapLayout from 'layouts/MapLayout';

const LayoutSwitch = ({ mainPanelRef, layout, children }) => {
  // states and functions
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', resizeFunction);
    return () => {
      window.removeEventListener('resize', resizeFunction);
    };
  }, [mainPanelRef]);

  if (layout === '/admin') {
    return <Admin drawerToggle={handleDrawerToggle}>{children}</Admin>;
  }
  if (layout === '/map') {
    return <MapLayout drawerToggle={handleDrawerToggle}>{children}</MapLayout>;
  }
  return <div>Not Found</div>;
};

LayoutSwitch.propTypes = {
  mainPanelRef: PropTypes.object,
  layout: PropTypes.string,
  children: PropTypes.node,
};

export default LayoutSwitch;

import React, { useContext } from 'react';

// Admin Profile
import ValidasiDataBahaya from 'components/AdminProfile/ValidasiDataBahaya';
import DataKerentanan from 'components/AdminProfile/DataKerentanan';
import VisualisasiDataBahaya from 'components/AdminProfile/VisualisasiDataBahaya';
import AdaptasiDataBahaya from 'components/AdminProfile/AdaptasiDataBahaya';

// context
import { UserContext } from 'App';
import AdminProfile from 'components/AdminProfile';

export default function AdminDaerahProfile() {
  const { userId } = useContext(UserContext);

  return (
    <AdminProfile>
      <DataKerentanan userId={userId} />
      <VisualisasiDataBahaya userId={userId} />
      <ValidasiDataBahaya userId={userId} />
      <AdaptasiDataBahaya userId={userId} />
    </AdminProfile>
  );
}

import React from 'react';
import PropTypes from 'prop-types';

// leaflet
import {
  Map as LeafletMap,
  Marker,
  Popup,
  TileLayer,
  ZoomControl,
  GeoJSON,
  WMSTileLayer,
} from 'react-leaflet';
import LeafletControl from 'react-leaflet-control';

// @material-ui/core
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

// Css & js Style
import styleCss from 'assets/css/views/global.module.css';

// Icons
import NotesIcon from '@material-ui/icons/Notes';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

// core components
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';

// Home Component Definition
function Map({
  baseLayer,
  isLoading,
  geoJsonData,
  isGeoJSONLoaded,
  dropdownCodeMap,
  dataLegend,
  listLegend,
  markers,
  polygons,
  rightMap,
  mapRef,
}) {
  return (
    <LeafletMap
      center={[0.7893, 120.9213]}
      zoomControl={false}
      zoom={5}
      animate={false}
      className={rightMap ? styleCss.Maphalf : styleCss.Map}
      scrollWheelZoom={false}
      ref={mapRef}
    >
      {isLoading ? (
        <div>
          <CircularProgress className={styleCss.spinnerMap} />
        </div>
      ) : (
        <div>
          <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            zIndex={-2}
          />

          {baseLayer && (
            <WMSTileLayer
              layers="apik:apik_desa"
              url="https://geoserver.piarea.co.id/geoserver/gwc/service/wms/"
              format="image/PNG"
              styles={dropdownCodeMap}
            />
          )}

          {markers &&
            markers.map(({ id, coordinate, form }) => (
              <Marker
                position={[coordinate.long, coordinate.lat]}
                key={`marker-${id}`}
              >
                <Popup>
                  {form.name}: {form.value}
                </Popup>
              </Marker>
            ))}

          {polygons &&
            polygons.map((polygon, index) => (
              <GeoJSON
                data={polygon}
                key={`layer-polygon-${index + 1}`}
                style={{ color: '#00A6A6', weight: 1, opacity: 1 }}
              />
            ))}

          {listLegend.length > 0 && (
            <LeafletControl position="bottomleft">
              <div className={styleCss.BoxLegend}>
                <div className={styleCss.LegendCms}>
                  <GridContainer>
                    <GridItem xs={4}>
                      <NotesIcon className={styleCss.IconInfoLegendHeader} />
                    </GridItem>
                    <GridItem xs={8}>
                      <Typography className={styleCss.TextLegend}>
                        Legend
                      </Typography>
                    </GridItem>
                  </GridContainer>
                </div>

                {dataLegend ? (
                  <table>
                    <tbody>
                      {listLegend.map(dl => (
                        <tr key={`legend-${dl.id}`}>
                          <td>
                            <div
                              style={{
                                width: 14,
                                height: 14,
                                background: dl.fill,
                                border: '1px solid' + dl.outline,
                                marginLeft: 20,
                                marginBottom: 5,
                                marginTop: 5,
                              }}
                            ></div>
                          </td>
                          <td>{dl.range}</td>
                          <td>{dl.category}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div style={{ padding: 20, textAlign: 'center' }}>
                    <FontAwesomeIcon icon={faExclamationCircle} /> Belum ada
                    data terpilih
                  </div>
                )}
              </div>
            </LeafletControl>
          )}

          {isGeoJSONLoaded && (
            <GeoJSON
              data={geoJsonData}
              key={'geojson-gambar'}
              style={{ color: '#00A6A6', weight: 1, opacity: 0.65 }}
            />
          )}

          <div style={{ marginTop: 50 }}>
            <ZoomControl position="topright" />
          </div>
        </div>
      )}
    </LeafletMap>
  );
}

Map.propTypes = {
  baseLayer: PropTypes.bool,
  isLoading: PropTypes.bool,
  geoJsonData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  isGeoJSONLoaded: PropTypes.bool,
  dropdownCodeMap: PropTypes.string,
  dataLegend: PropTypes.bool,
  listLegend: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  markers: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  polygons: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  rightMap: PropTypes.bool,
  mapRef: PropTypes.object,
};

export default Map;

import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';

// Css & js Style
import styleCss from 'assets/css/views/global.module.css';

// Icons
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ReplayIcon from '@material-ui/icons/Replay';

// core components
import GridContainer from 'components/Grid/GridContainer.js';

// custom styles Input
import BootstrapInput from './BootstrapInput';
// Home Component Definition
function Filter({
  onResetClick,
  onFilterData,
  onChangeSelect,
  dropdown,
  listDropdown,
  isFilterDisabled,
  isFilterResetVisible,
}) {
  // check include apik or sidik
  const listJenis = listDropdown.jenis;
  const isKerentananAndApik = dropdown.level === '3' && dropdown.jenis === '0';
  const isKeterpaparanAndApik =
    dropdown.level === '2' && dropdown.jenis === '0';
  const isApikOrSidik =
    listJenis.find(item => item.name == 'APIK') ||
    listJenis.find(item => item.name == 'SIDIK');

  return (
    <div className={styleCss.selectHome}>
      <FormControl>
        <GridContainer>
          <NativeSelect
            id="level-select"
            className={styleCss.SelectCari}
            value={dropdown.level}
            name="level"
            onChange={onChangeSelect}
            input={<BootstrapInput />}
            disabled={isFilterDisabled}
          >
            <option
              style={{ color: '#000000', fontWeight: 300 }}
              aria-label="None"
              value={null}
            >
              Pilihan Tingkat
            </option>
            {listDropdown.level.map((drp, index) => (
              <option style={{ color: '#000000' }} key={drp.name} value={index}>
                {drp.name}
              </option>
            ))}
          </NativeSelect>

          <NativeSelect
            id="jenis-select"
            style={{ marginRight: '10px' }}
            value={dropdown.jenis}
            name="jenis"
            onChange={onChangeSelect}
            input={<BootstrapInput />}
            disabled={isFilterDisabled}
          >
            <option style={{ color: '#000000' }} aria-label="None" value="">
              Pilihan Jenis
            </option>
            {listDropdown.jenis.map((drp, index) => (
              <option style={{ color: '#000000' }} key={drp.name} value={index}>
                {drp.name}
              </option>
            ))}
          </NativeSelect>

          <NativeSelect
            id="period-select"
            style={{ marginRight: '10px' }}
            value={dropdown.period}
            name="period"
            onChange={onChangeSelect}
            input={<BootstrapInput />}
            disabled={isFilterDisabled}
          >
            <option style={{ color: '#000000' }} aria-label="None" value="">
              Pilihan Tahun
            </option>
            {listDropdown.period.map((drp, index) => (
              <option style={{ color: '#000000' }} key={drp.name} value={index}>
                {drp.name}
              </option>
            ))}
          </NativeSelect>

          {!isApikOrSidik && (
            <NativeSelect
              id="model-select"
              value={dropdown.model}
              name="model"
              onChange={onChangeSelect}
              input={<BootstrapInput />}
              disabled={isFilterDisabled}
            >
              <option style={{ color: '#000000' }} aria-label="None" value="">
                Pilihan Model
              </option>
              {listDropdown.model.map((drp, index) => (
                <option
                  style={{ color: '#000000' }}
                  key={drp.name}
                  value={index}
                >
                  {drp.name}
                </option>
              ))}
            </NativeSelect>
          )}

          {(isKerentananAndApik || isKeterpaparanAndApik) && (
            <NativeSelect
              id="zone-select"
              value={dropdown.zone}
              name="zone"
              onChange={onChangeSelect}
              input={<BootstrapInput />}
              disabled={isFilterDisabled}
            >
              <option style={{ color: '#000000' }} aria-label="None" value="">
                Pilihan Wilayah
              </option>
              {listDropdown.zone.map((drp, index) => (
                <option
                  style={{ color: '#000000' }}
                  key={drp.name}
                  value={index}
                >
                  {drp.name}
                </option>
              ))}
            </NativeSelect>
          )}
        </GridContainer>
      </FormControl>
      <IconButton
        aria-label="Cari"
        className={styleCss.ButtonCari}
        onClick={onFilterData}
      >
        <ArrowForwardIcon />
      </IconButton>
      {isFilterResetVisible && (
        <IconButton
          aria-label="Cari"
          className={styleCss.ButtonReset}
          onClick={onResetClick}
        >
          <ReplayIcon />
        </IconButton>
      )}
    </div>
  );
}

Filter.propTypes = {
  onResetClick: PropTypes.func,
  onFilterData: PropTypes.func,
  onChangeSelect: PropTypes.func,
  dropdown: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  listDropdown: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  isFilterDisabled: PropTypes.bool,
  isFilterResetVisible: PropTypes.bool,
};

export default Filter;

import React, { useContext } from 'react';

// core components
import AdminProfile from 'components/AdminProfile';
import TabelValidasiBahaya from 'components/AdminProfile/TabelValidasiBahaya';

// Context
import { AdminLayoutContext } from 'layouts/Admin';
import { UserContext } from 'App';

function AdminProgramProfile() {
  const { userId } = useContext(UserContext);
  const { openNotification } = useContext(AdminLayoutContext);

  return (
    <AdminProfile>
      <TabelValidasiBahaya
        userId={userId}
        openNotification={openNotification}
      />
    </AdminProfile>
  );
}

export default AdminProgramProfile;

import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

// leaflet
import Leaflet from 'leaflet';

// configs
import api from 'config/api';
import globalFn from 'config/globalFunc';

// constants
import {
  initialDropdown,
  initialInfobarTitle,
  initialValueSlider,
  initialResult,
} from './constants';

// custom styles Input
import Map from 'components/ApikMap/Map';
import Filter from 'components/ApikMap/Filter';
import InformationBar from 'components/ApikMap/InformationBar';
import LayerSidebar from 'components/ApikMap/LayerSidebar';
import ProductKnowledgeSidebar from 'components/ApikMap/ProductKnowledgeSidebar';
import { UserContext } from 'App';

// Home Component Definition
function Home({
  openNotification,
  toggleLoadingOverlay,
  isInformationBarVisible,
  toggleInfomationbar,
}) {
  const { email, roles, userId } = useContext(UserContext);
  const rolesid = roles ? roles[0].id : '';

  // refs
  const mapRef = useRef(null);

  // states definitions
  const [dataBahayaParams, setDataBahayaParams] = useState({});
  const [dataKeterpaparanParams, setDataKeterpaparanParams] = useState({});
  const [dataSensitivitasParams, setDataSensitivitasParams] = useState({});
  const [
    dataKapasitasAdaptasiParams,
    setDataKapasitasAdaptasiParams,
  ] = useState({});
  const [isCreate, setIsCreate] = useState(false);
  const [selectedDesa, setSelectedDesa] = useState('');
  const [idEdit, setIdEdit] = useState('');
  const [isCanEdit, setIsCanEdit] = useState(false);
  const [result, setResult] = useState(initialResult);

  // User
  const [userProfile, setUserProfile] = useState();

  // Loading
  const [isLoading, setIsLoading] = useState(false);

  // Infobar - Side bars - Right
  const [infobarData, setInfobarData] = useState([]);
  const [infobarTitle, setInfobarTitle] = useState(initialInfobarTitle);
  const [valueSlider, setValueSlider] = useState(initialValueSlider);

  // Map
  // -- geo JSON
  const [geoJsonData, setGeoJsonData] = useState();
  const [isGeoJSONLoaded, setLoadGeoJSON] = useState(true);
  // -- legend
  const [listLegend, setListLegend] = useState([]);
  const [dataLegend, setDataLegend] = useState(false);
  const [baseLayer, setBaseLayer] = useState(false);
  // -- layers
  const [listLayer, setListLayer] = useState([]);
  const [listProductKnowledge, setListProductKnowledge] = useState([]);
  const [markers, setMarkers] = useState([]);
  const [polygons, setPolygons] = useState([]);
  const [isOpenLeftLayer, setOpenLeftLayer] = useState('');

  // Top Widget - Filter States
  const [isFilterDisabled, setDisableFilter] = useState(false);
  const [isFilterResetVisible, setFilterResetVisible] = useState(false);
  const [dropdownCodeMap, setDropdownCodeMap] = useState('');
  const [dropdown, setDropdown] = useState(initialDropdown);
  const [listDropdown, setListDropdown] = useState({
    level: [],
    jenis: [],
    model: [],
    period: [],
    zone: [],
  });

  const doSubmit = () => {
    if (isCreate) {
      createVisual();
    } else {
      editVisual();
    }
  };

  // Visual data
  // -- create visual
  const createVisual = () => {
    toggleLoadingOverlay(true);
    const params = {
      wilayah_id: selectedDesa,
      user_id: userId,
      title: 'Visual data',
      ch_tahunan: result.CHT,
      ch_bulanan: result.CHM,
      ch_optimum: result.CHO,
      su_tahunan: result.SUT,
      su_bulanan: result.SUM,
      su_optimum: result.SUO,
      level_id: 1,
      penyakit_id: infobarTitle.jenis.type_id,
      model_id: infobarTitle.model.type_id,
      period_id: infobarTitle.tahun.type_id,
    };

    api
      .createVisual(params)
      .then(res => {
        toggleLoadingOverlay(false);
        if (res.data.error_code === 0) {
          openNotification('success', 'Berhasil Menyimpan data.');
          setIsCreate(false);
        } else {
          openNotification('danger', res.data.message);
        }
      })
      .catch(err => {
        toggleLoadingOverlay(false);
        console.error(err);
      });
  };

  // -- edit visual
  const editVisual = () => {
    toggleLoadingOverlay(true);
    const params = {
      id: idEdit,
      wilayah_id: selectedDesa,
      user_id: userId,
      title: 'Visual data',
      ch_tahunan: result.CHT,
      ch_bulanan: result.CHM,
      ch_optimum: result.CHO,
      su_tahunan: result.SUT,
      su_bulanan: result.SUM,
      su_optimum: result.SUO,
      level_id: 1,
      penyakit_id: infobarTitle.jenis.type_id,
      model_id: infobarTitle.model.type_id,
      period_id: infobarTitle.tahun.type_id,
    };
    api
      .editVisual(params)
      .then(res => {
        toggleLoadingOverlay(false);
        if (res.data.error_code === 0) {
          openNotification('success', 'Berhasil Mengubah data.');
        } else {
          openNotification('danger', res.data.message);
        }
      })
      .catch(err => {
        toggleLoadingOverlay(false);
        console.error(err);
      });
  };

  const getBahayaByParam = (iddesa, dtingkat, djenis, dtahun, dmodel) => {
    api
      .getBahayaByParam(iddesa, dtingkat, djenis, dtahun, dmodel)
      .then(res => {
        if (res.data.error_code === 0) {
          let dats = res.data.data;
          if (dats.length !== 0) {
            setValueSlider({
              CHT: dats[0].ch_tahunan,
              CHM: dats[0].ch_bulanan,
              CHO: dats[0].ch_optimum,
              SUT: dats[0].su_tahunan,
              SUM: dats[0].su_bulanan,
              SUO: dats[0].su_optimum,
              defCHT: dats[0].ch_tahunan,
              defCHM: dats[0].ch_bulanan,
              defCHO: dats[0].ch_optimum,
              defSUT: dats[0].su_tahunan,
              defSUM: dats[0].su_bulanan,
              defSUO: dats[0].su_optimum,
            });

            let val = globalFn.calculateRadar(djenis.type_id, dats);

            let dradar = {
              labels: ['SU O', 'SU T', 'SU M', 'CH O', 'CH T', 'CH M'],
              label2: [
                'Suhu Udara Optimum Bulanan',
                'Suhu Udara Tahunan',
                'Suhu Udara Musiman',
                'Curah Hujan Optimum Bulanan',
                'Curah Hujan Tahunan',
                'Curah Hujan Musiman',
              ],
              datasets: [
                {
                  data: [val.sumFinal, val.sutFinal, val.suoFinal, 0, 0, 0],
                  backgroundColor: 'rgba(0,166,166,0.5)',
                  borderColor: 'rgba(0,166,166,1)',
                  pointBackgroundColor: 'rgba(0,166,166,1)',
                  pointBorderColor: '#fff',
                  pointHoverBackgroundColor: '#fff',
                  pointHoverBorderColor: 'rgba(0,166,166,1)',
                  label: 'SU',
                },
                {
                  data: [0, 0, 0, val.dataCHM, val.dataCHT, val.dataCHO],
                  backgroundColor: 'rgba(255,99,132,0.5)',
                  borderColor: 'rgba(255,99,132,1)',
                  pointBackgroundColor: 'rgba(255,99,132,1)',
                  pointBorderColor: '#fff',
                  pointHoverBackgroundColor: '#fff',
                  pointHoverBorderColor: 'rgba(255,99,132,1)',
                  label: 'CH',
                },
              ],
            };
            let options = {
              scale: {
                ticks: {
                  beginAtZero: true,
                  max: 1,
                  min: 0,
                  stepSize: 0.2,
                },
              },
              responsive: true,
              maintainAspectRatio: true,
              aspectRatio: 1,
              tooltips: {
                callbacks: {
                  label: (item, data) => {
                    return `${data.label2[item.index]}: ${item.value}`;
                  },
                },
              },
            };
            setDataBahayaParams({ data: dradar, options: options });
          }
        }
      })
      .catch(err => {
        setIsLoading(false);
        console.error(err);
      });
  };

  const getKerentananByParam = (iddesa, djenis, dtahun) => {
    api
      .getKerentananByParam(iddesa, dtahun)
      .then(res => {
        if (res.data.error_code === 0) {
          let dats = res.data.data;
          if (dats.length !== 0) {
            let options = {
              responsive: true,
              maintainAspectRatio: true,
              aspectRatio: 1,

              scale: {
                ticks: {
                  beginAtZero: true,
                  max: 1,
                  min: 0,
                  stepSize: 0.2,
                },
              },
              tooltips: {
                callbacks: {
                  label: (item, data) => {
                    return `${data.label2[item.index]}: ${item.value}`;
                  },
                },
              },
            };
            if (
              (listDropdown.level[dropdown.level].name == 'Keterpaparan' &&
                listDropdown.jenis[dropdown.jenis].name == 'APIK') ||
              listDropdown.level[dropdown.level].name == 'Resiko'
            ) {
              const keterpaparan = {
                labels: ['demo', 'kel_adat', 'usia'],
                label2: ['Demografi', 'Kelompok Adat', 'Usia'],
                datasets: [
                  {
                    data: [dats[0].demo, dats[0].kel_adat, dats[0].usia],
                    backgroundColor: '#F5AF8F',
                    borderColor: '#DCB59A',
                    pointBackgroundColor: '#F5AF8F',
                    pointBorderColor: '#fff',
                    pointHoverBackgroundColor: '#fff',
                    pointHoverBorderColor: 'rgba(0,166,166,1)',
                    pointRadius: 5,
                    label: 'Keterpaparan',
                  },
                ],
              };

              setDataKeterpaparanParams({
                data: keterpaparan,
                options,
              });
            }

            if (
              (listDropdown.level[dropdown.level].name == 'Kerentanan' &&
                listDropdown.jenis[dropdown.jenis].name == 'APIK') ||
              listDropdown.level[dropdown.level].name == 'Resiko'
            ) {
              const sensitivitas = {
                labels: [
                  'peny_iklim',
                  'tat_gun_lah',
                  'luas_raw_ikl',
                  'ben_ikl_his',
                  'rt_per_kum',
                  'per_pek_ikl',
                  'kon_eko_pel',
                  'pencemar',
                ],
                label2: [
                  'Penyakit Peka Iklim',
                  'Tata Guna Lahan',
                  'Luas Rawan Iklim Ekstrim',
                  'Bencana Iklim Historis',
                  'Rumah Tangga di Permukiman Kumuh',
                  'Permukiman Peka Iklim',
                  'Kondisi Ekonomi Penduduk',
                  'Pencemaran',
                ],
                datasets: [
                  {
                    data: [
                      dats[0].peny_iklim,
                      dats[0].tat_gun_lah,
                      dats[0].luas_raw_ikl,
                      dats[0].ben_ikl_his,
                      dats[0].rt_per_kum,
                      dats[0].per_pek_ikl,
                      dats[0].kon_eko_pel,
                      dats[0].pencemar,
                    ],
                    backgroundColor: '#B6D4A5',
                    borderColor: '#79AB65',
                    pointBackgroundColor: '#B6D4A5',
                    pointBorderColor: '#fff',
                    pointHoverBackgroundColor: '#fff',
                    pointHoverBorderColor: 'rgba(0,166,166,1)',
                    label: 'Sensitivitas',
                  },
                ],
              };

              setDataSensitivitasParams({
                data: sensitivitas,
                options,
              });

              const kapasitasAdaptasi = {
                labels: [
                  'per_kes_ben',
                  'keb_ews_ben',
                  'keb_ram_jal',
                  'ting_pendi',
                  'jum_ten_kes',
                  'jum_fas_kes',
                  'aks_fas_kes',
                  'faskes_res_ikl',
                  'fas_peng_ling',
                  'peng_fas_bab',
                  'tem_pem_tinj',
                  'tem_pem_sam',
                  'bah_bak',
                  'aks_air_min',
                  'san_kel',
                  'hig_kel',
                ],
                label2: [
                  'Perlengkapan Keselamatan Terhadap Bencana',
                  'Keberadaan Sistem Peringatan Dini Bencana Alam',
                  'Keberadaan Rambu-Rambu dan Jalur Evakuasi Bencana',
                  'Tingkat Pendidikan',
                  'Jumlah Tenaga Kesehatan',
                  'Jumlah Fasilitas Kesehatan',
                  'Akses ke Fasilitas Kesehatan dari Pusat Kota',
                  'Faskes Resiliensi Iklim',
                  'Fasilitas Pengelolaan Lingkungan',
                  'Penggunaan Fasilitas Tempat Buang Air Besar Sebagian Besar Keluarga (Desa)',
                  'Tempat Pembuangan Akhir Tinja Sebagian Besar Penduduk',
                  'Tempat Pembuangan Sampah Sebagian Besar Keluarga',
                  'Bahan Bakar Untuk Memasak Sebagian Besar Keluarga',
                  'Akses Air Minum Keluarga',
                  'Sanitasi Keluarga',
                  'Hygiene Keluarga',
                ],
                datasets: [
                  {
                    data: [
                      dats[0].per_kes_ben,
                      dats[0].keb_ews_ben,
                      dats[0].keb_ram_jal,
                      dats[0].ting_pendi,
                      dats[0].jum_ten_kes,
                      dats[0].jum_fas_kes,
                      dats[0].aks_fas_kes,
                      dats[0].faskes_res_ikl,
                      dats[0].fas_peng_ling,
                      dats[0].peng_fas_bab,
                      dats[0].tem_pem_tinj,
                      dats[0].tem_pem_sam,
                      dats[0].bah_bak,
                      dats[0].aks_air_min,
                      dats[0].san_kel,
                      dats[0].hig_kel,
                    ],
                    backgroundColor: '#96AAD5',
                    borderColor: '#8196CD',
                    pointBackgroundColor: '#96AAD5',
                    pointBorderColor: '#fff',
                    pointHoverBackgroundColor: '#fff',
                    pointHoverBorderColor: 'rgba(0,166,166,1)',
                    label: 'Kapasitas Adaptasi',
                  },
                ],
              };

              setDataKapasitasAdaptasiParams({
                data: kapasitasAdaptasi,
                options,
              });
            }
          }
        }
      })
      .catch(err => {
        setIsLoading(false);
        console.error(err);
      });
  };

  const getVisual = (iddesa, dtingkat, djenis, dtahun, dmodel) => {
    api
      .getVisual(iddesa, dtingkat, djenis, dtahun, dmodel)
      .then(res => {
        if (res.data.error_code === 0) {
          let dats = res.data.data;
          if (dats.length === 0) {
            setIsCreate(true);
          } else {
            setIsCreate(false);
            setIdEdit(dats[0].id);
          }
        }
      })
      .catch(err => {
        setIsLoading(false);
        console.error(err);
      });
  };

  // Geo JSON
  const getGeojson = (type, filter) => {
    setLoadGeoJSON(true);
    setIsLoading(true);
    api
      .getGeojson(type, filter)
      .then(res => {
        setIsLoading(false);
        if (res.data.error_code === 0) {
          const { data } = res.data;
          setGeoJsonData(data);

          const map = mapRef.current.leafletElement;

          const geoJSON = Leaflet.geoJson(data, {
            style: {
              color: '#00A6A6',
              weight: 1,
              opacity: 1,
              fillOpacity: 0,
            },
          }).addTo(map);

          geoJSON.eachLayer(layer => {
            layer.addEventListener('click', () => {
              const types =
                type === 'provinsi'
                  ? 'kabupaten'
                  : type === 'kabupaten'
                  ? 'desa'
                  : '';

              const layerProps = layer.feature.properties;
              const dataSend =
                type === 'provinsi'
                  ? layerProps.PROVNO
                  : type === 'kabupaten'
                  ? layerProps.KABKOTNO + '&prov_no=' + layerProps.PROVNO
                  : layerProps.DESA;

              if (types) {
                localStorage.setItem('iddes', null);
                geoJSON.remove();
                map.fitBounds(layer.getBounds());
                getGeojson(types, dataSend);
              } else {
                setSelectedDesa(layerProps.IDDESA);

                localStorage.setItem('dprov', layerProps.PROVINSI);
                localStorage.setItem('dkab', layerProps.KABKOT);
                localStorage.setItem('dkec', layerProps.KECAMATAN);
                localStorage.setItem('ddes', layerProps.DESA);

                const tingkat = listDropdown.level[dropdown.level];
                const jenis = listDropdown.jenis[dropdown.jenis];
                const tahun = listDropdown.period[dropdown.period];
                const model = dropdown.model
                  ? listDropdown.model[dropdown.model]
                  : '';

                const provkab = layerProps.PROVNO + '' + layerProps.KABKOTNO;

                if (userProfile) {
                  const { wilayah__prov_no, wilayah__kab_kot_no } = userProfile;
                  const duprokab = `${wilayah__prov_no}${wilayah__kab_kot_no}`;
                  if (duprokab === provkab) {
                    setIsCanEdit(true);
                    getVisual(layerProps.IDDESA, tingkat, jenis, tahun, model);
                    if (
                      ['Kerentanan', 'Keterpaparan'].includes(
                        listDropdown.level[dropdown.level].name,
                      ) &&
                      listDropdown.jenis[dropdown.jenis].name == 'APIK'
                    ) {
                      getKerentananByParam(layerProps.IDDESA, jenis, tahun);
                    } else if (
                      listDropdown.level[dropdown.level].name == 'Bahaya' &&
                      listDropdown.jenis[dropdown.jenis].name == 'APIK'
                    ) {
                      getBahayaByParam(
                        layerProps.IDDESA,
                        tingkat,
                        jenis,
                        tahun,
                        model,
                      );
                    } else {
                      console.log('masuk resiko');
                      getKerentananByParam(layerProps.IDDESA, jenis, tahun);
                      getBahayaByParam(
                        layerProps.IDDESA,
                        tingkat,
                        jenis,
                        tahun,
                        model,
                      );
                    }
                  } else {
                    setIsCanEdit(false);
                  }
                } else {
                  setIsCanEdit(false);
                }

                getDataSide(!types ? layerProps.IDDESA : '');

                localStorage.setItem('iddes', layerProps.IDDESA);
              }
            });

            layer.addEventListener('mouseover', () => {
              const layerProps = layer.feature.properties;
              let dataSend =
                type === 'provinsi'
                  ? layerProps.PROVINSI
                  : type === 'kabupaten'
                  ? layerProps.KABKOT
                  : layerProps.DESA;
              layer.bindTooltip('<div>' + dataSend + '</div>').openTooltip();
            });
          });
        }
        setLoadGeoJSON(false);
        setIsLoading(false);
      })
      .catch(err => {
        setIsLoading(false);
        console.error(err);
      });
  };

  const toggleInformationBar = () => {
    if (
      !dropdown.period ||
      !dropdown.jenis ||
      !dropdown.level ||
      !dropdown.model
    ) {
      openNotification(
        'danger',
        'Mohon lengkapi data dropdown dan pilih peta dahulu.',
      );
      return;
    }

    const parsemodel = listDropdown.level[dropdown.level];
    if (parsemodel.type_id === 3 || parsemodel.type_id === 4) {
      toggleInfomationbar(!isInformationBarVisible);
      return;
    }

    if (dropdown.model) {
      toggleInfomationbar(!isInformationBarVisible);
      return;
    }
  };

  const onChangeSlider = (val, type) => {
    // if (type === 'CHT') {
    let vale =
      valueSlider['def' + type] * (val / 100) + valueSlider['def' + type];
    setValueSlider({
      ...valueSlider,
      [type]: vale,
    });

    let jenis = listDropdown.jenis[dropdown.jenis];

    let dats = [
      {
        ch_tahunan: valueSlider.CHT,
        ch_bulanan: valueSlider.CHM,
        ch_optimum: valueSlider.CHO,
        su_tahunan: valueSlider.SUT,
        su_bulanan: valueSlider.SUM,
        su_optimum: valueSlider.SUO,
      },
    ];

    let total = globalFn.calculateRadar(jenis.type_id, dats);

    setResult({
      SUM: total.sumFinal,
      SUT: total.sutFinal,
      SUO: total.suoFinal,
      CHM: total.dataCHM,
      CHT: total.dataCHT,
      CHO: total.dataCHO,
    });

    let dradar = {
      labels: ['SU O', 'SU T', 'SU M', 'CH O', 'CH T', 'CH M'],
      datasets: [
        {
          data: [total.sumFinal, total.sutFinal, total.suoFinal, 0, 0, 0],
          backgroundColor: 'rgba(0,166,166,0.5)',
          borderColor: 'rgba(0,166,166,1)',
          pointBackgroundColor: 'rgba(0,166,166,1)',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgba(0,166,166,1)',
          label: 'SU',
        },
        {
          data: [0, 0, 0, total.dataCHM, total.dataCHT, total.dataCHO],
          backgroundColor: 'rgba(255,99,132,0.5)',
          borderColor: 'rgba(255,99,132,1)',
          pointBackgroundColor: 'rgba(255,99,132,1)',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgba(255,99,132,1)',
          label: 'CH',
        },
      ],
    };
    let options = {
      responsive: true,
      maintainAspectRatio: false,
    };
    setDataBahayaParams({ data: dradar, options: options });

    // }
  };

  // general utils
  // -- reset window
  const reset = () => {
    window.location.reload();
  };

  // user config
  // -- get user by email
  const getUserByEmail = id => {
    api.getUserByEmail(id).then(res => {
      if (res.data.error_code === 0) {
        const [datauser] = res.data.data;
        setUserProfile(datauser);
      } else {
        openNotification('danger', res.data.message);
      }
    });
  };

  // Layers
  // -- markers
  const onUpdateMarkers = locations => {
    setMarkers([...markers, ...locations]);
  };
  // -- geojson
  const onUpdateGeoJSON = geoJSON => {
    setPolygons([...polygons, geoJSON]);
  };
  // -- update layerList
  const onUpdateLayerList = updatedLayerList => {
    setListLayer(updatedLayerList);
  };

  const onUpdateProductKnowledge = updatedProductKnowledge => {
    setListProductKnowledge(updatedProductKnowledge);
  };

  // Information sidebar
  // -- get data
  const getDataSide = idDesa => {
    setIsLoading(true);
    api
      .searchData(
        idDesa,
        listDropdown.level[dropdown.level].type_id,
        listDropdown.jenis[dropdown.jenis].type_id,
        listDropdown.period[dropdown.period].type_id,
        listDropdown.model[dropdown.model]?.type_id || '',
      )
      .then(res => {
        setIsLoading(false);
        if (res.data.error_code === 0) {
          setInfobarData(res.data.data === null ? [] : res.data.data);
          toggleInfomationbar(true);
        }
      })
      .catch(err => {
        setIsLoading(false);
        console.error(err);
      });
  };

  // Filters
  // -- get master type for dropdown
  const getMasterType = (type, group) => {
    api
      .getMasterType(type, group)
      .then(res => {
        const responseBody = res.data;
        if (responseBody.error_code === 0) {
          const { data } = responseBody;
          if (type === 'Legend') {
            const [listLegend] = responseBody.data;
            setListLegend(listLegend.data.reverse());
          } else {
            const targetKey = `${type}`.toLowerCase();
            setListDropdown(prevState => ({
              ...prevState,
              [targetKey]: data,
            }));
          }
        }
      })
      .catch(err => {
        console.error(err);
      });
  };

  // -- handle filter data
  const handleFilterData = () => {
    if (!dropdown.level) {
      openNotification('danger', 'Tingkat harus dipilih!');
      return;
    }

    if (!dropdown.jenis) {
      openNotification('danger', 'Jenis harus dipilih!');
      return;
    }

    if (!dropdown.period) {
      openNotification('danger', 'Tahun harus dipilih!');
      return;
    }

    const { level, jenis, period, model, zone } = dropdown;

    setDisableFilter(true);
    const geoJSONCode =
      'apik:' +
      listDropdown.level[level].code +
      listDropdown.jenis[jenis].code +
      listDropdown.period[period].code +
      (listDropdown.level[level].name !== 'Keterpaparan' &&
      listDropdown.level[level].name !== 'Kerentanan'
        ? listDropdown.model[model].code
        : '') +
      (listDropdown.level[level].name === 'Kerentanan' &&
      listDropdown.jenis[jenis].name === 'APIK'
        ? listDropdown.zone[zone].code
        : '') +
      (listDropdown.level[level].name === 'Keterpaparan' &&
      listDropdown.jenis[jenis].name === 'APIK'
        ? listDropdown.zone[zone].code
        : '');

    setDropdownCodeMap(geoJSONCode);
    setInfobarTitle({
      tingkat: listDropdown.level[level],
      jenis: listDropdown.jenis[jenis],
      tahun: listDropdown.period[period],
      model: listDropdown.jenis[jenis],
      zone: listDropdown.zone[zone],
    });
    setBaseLayer(true);
    setDataLegend(true);
    getGeojson('provinsi', geoJSONCode, true);
  };

  // -- handle filter change
  const handleChangeSelect = e => {
    setFilterResetVisible(true);

    const { name, value } = e.target;
    if (name === 'level') {
      const selected = listDropdown.level[value];
      getMasterType('Jenis', selected.key);
      getMasterType('Model', selected.key);
      getMasterType('Period', selected.key);
      getMasterType('Zone', selected.key);
    }

    setDropdown(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  // component life-cycle
  // -- did mount
  useEffect(() => {
    localStorage.setItem('iddes', null);

    getMasterType('Level');
    getMasterType('Legend');

    if (localStorage.getItem('token')) {
      getUserByEmail(email);
    }
  }, []);

  return (
    <>
      <Filter
        onResetClick={reset}
        onFilterData={handleFilterData}
        onChangeSelect={handleChangeSelect}
        dropdown={dropdown}
        listDropdown={listDropdown}
        isFilterDisabled={isFilterDisabled}
        isFilterResetVisible={isFilterResetVisible}
      />
      {(isOpenLeftLayer === 'productKnowledge' || isOpenLeftLayer === '') && (
        <ProductKnowledgeSidebar
          openNotification={openNotification}
          onUpdateProductKnowledge={onUpdateProductKnowledge}
          listProductKnowledge={listProductKnowledge}
          setOpenLeftLayer={setOpenLeftLayer}
        />
      )}
      {(isOpenLeftLayer === 'layer' || isOpenLeftLayer === '') && (
        <LayerSidebar
          openNotification={openNotification}
          onUpdateMarkers={onUpdateMarkers}
          onUpdateGeoJSON={onUpdateGeoJSON}
          onUpdateLayerList={onUpdateLayerList}
          listLayer={listLayer}
          setOpenLeftLayer={setOpenLeftLayer}
        />
      )}
      <Map
        baseLayer={baseLayer}
        isLoading={isLoading}
        geoJsonData={geoJsonData}
        isGeoJSONLoaded={isGeoJSONLoaded}
        dropdownCodeMap={dropdownCodeMap}
        dataLegend={dataLegend}
        listLegend={listLegend}
        markers={markers}
        polygons={polygons}
        mapRef={mapRef}
      />
      <InformationBar
        infobarTitle={infobarTitle}
        infobarData={infobarData}
        valueSlider={valueSlider}
        isCanEdit={isCanEdit}
        isInformationBarVisible={isInformationBarVisible}
        dataBahayaParams={dataBahayaParams}
        dataKeterpaparanParams={dataKeterpaparanParams}
        dataSensitivitasParams={dataSensitivitasParams}
        dataKapasitasAdaptasiParams={dataKapasitasAdaptasiParams}
        rolesid={rolesid}
        doSubmit={doSubmit}
        onChangeSlider={onChangeSlider}
        toggleInformationBar={toggleInformationBar}
      />
    </>
  );
}

Home.propTypes = {
  openNotification: PropTypes.func,
  toggleLoadingOverlay: PropTypes.func,
  isInformationBarVisible: PropTypes.bool,
  toggleInfomationbar: PropTypes.func,
};

export default Home;

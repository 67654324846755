export const labelSH = [
  {
    value: -100,
    label: '-100%',
  },
  {
    value: 0,
    label: '0',
  },

  {
    value: 100,
    label: '100%',
  },
];

export const labelSU = [
  {
    value: -2,
    label: '-2°C',
  },
  {
    value: 0,
    label: '0°C',
  },

  {
    value: 2,
    label: '2°C',
  },
];

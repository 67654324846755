import React, { createContext, createRef, useEffect, useState } from 'react';

// creates a beautiful scrollbar
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// core components

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';

// routes
import routes from 'routes';

// layout
import LayoutSwitch from 'layouts/LayoutSwitch';

// config
import api from 'config/api';

// assets
import 'assets/css/material-dashboard-react.css?v=1.8.0';
import styles from 'assets/jss/material-dashboard-react/layouts/adminStyle';

const useStyles = makeStyles(styles);

export const UserContext = createContext(null);

function App() {
  // styles
  const classes = useStyles();

  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = createRef();

  // state
  const [userState, setUserState] = useState({
    name: localStorage.getItem('name'),
    email: localStorage.getItem('email'),
    roles: JSON.parse(localStorage.getItem('roles')),
    userId: localStorage.getItem('userId'),
    isLogin: !!localStorage.getItem('token'),
    wilayahId: localStorage.getItem('wilayahId'),
  });

  const checker = () => {
    const token = localStorage.getItem('token');
    // window.alert('test')

    if (token) {
      validateToken();
    }
  };

  const validateToken = () => {
    api
      .validateToken()
      .then(res => {
        localStorage.setItem('email', res.data.email);
        localStorage.setItem('name', res.data.name);
        localStorage.setItem('roles', JSON.stringify(res.data.roles));
        localStorage.setItem('userId', res.data.id);
        localStorage.setItem('sdstate', 'visualisasi');
        localStorage.setItem('wilayahId', res.data.wilayah_id);
        setUserState({
          name: res.data.name,
          email: res.data.email,
          roles: res.data.roles,
          userId: res.data.id,
          isLogin: true,
          wilayahId: res.data.wilayah_id,
        });
      })
      .catch(() => {
        localStorage.clear();
        window.location.reload();
      });
  };

  // initialize and destroy the PerfectScrollbar plugin
  useEffect(() => {
    const isWindowExist = navigator.platform.indexOf('Win') > -1;

    const ps =
      isWindowExist &&
      new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });

    document.body.style.overflow = 'auto';

    checker();
    // Specify how to clean up after this effect:
    return () => {
      if (isWindowExist) {
        ps.destroy();
      }
    };
  }, []);

  return (
    <UserContext.Provider value={userState}>
      <div className={classes.wrapper}>
        <div className={classes.mainPanel} ref={mainPanel}>
          <Router basename={'/'}>
            <Switch>
              {routes.map(({ path, layout, component: Component }, key) => (
                <Route
                  path={path}
                  component={() => (
                    <LayoutSwitch layout={layout} mainPanelRef={mainPanel}>
                      <Component />
                    </LayoutSwitch>
                  )}
                  key={key}
                />
              ))}
              <Redirect from="/" to="/home" />
            </Switch>
          </Router>
        </div>
      </div>
    </UserContext.Provider>
  );
}

export default App;

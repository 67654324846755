export const initialDropdown = {
  tingkat: null,
  jenis: null,
  tahun: null,
  model: null,
};

export const initialInfobarTitle = {
  tingkat: { name: null },
  jenis: { name: '' },
};

export const initialValueSlider = {
  CHT: 0,
  CHM: 0,
  CHO: 0,
  SUT: 0,
  SUM: 0,
  SUO: 0,
  defCHT: 0,
  defCHM: 0,
  defCHO: 0,
  defSUT: 0,
  defSUM: 0,
  defSUO: 0,
};

export const initialResult = {
  CHT: 0,
  CHM: 0,
  CHO: 0,
  SUT: 0,
  SUM: 0,
  SUO: 0,
};

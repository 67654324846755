import React, { useContext } from 'react';

// core components
import AdminProfile from 'components/AdminProfile';
import TabelDesaDesi from 'components/AdminProfile/TabelDesaDesi';

// Context
import { AdminLayoutContext } from 'layouts/Admin';
import { UserContext } from 'App';

function DesaDesi() {
  const { userId } = useContext(UserContext);

  const { openNotification } = useContext(AdminLayoutContext);

  return (
    <AdminProfile>
      <TabelDesaDesi userId={userId} openNotification={openNotification} />
    </AdminProfile>
  );
}

export default DesaDesi;

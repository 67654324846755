import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// @material-ui/core
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';

// @material-ui/icons

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import SearchIcon from '@material-ui/icons/Search';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// configs
import api from 'config/api';

import useUserProfile from 'hooks/useUserProfile';

import styleCss from 'assets/css/views/global.module.css';
import FormDesaDesi from './FormDesaDesi';
import { IconButton, InputBase, Paper } from '@material-ui/core';

function TabelDesaDesi({ openNotification, userId }) {
  // states
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [isOpenForm, setOpenForm] = useState(false);
  const [selectedDesa, setSelectedDesa] = useState(null);
  const { profile, getUserById } = useUserProfile(userId);
  const [search, setSearch] = useState('');

  const getList = id => {
    setLoading(true);
    api
      .getDesaByKabupaten(id, search)
      .then(res => {
        if (res.data.error_code === 0) {
          let dataa = res.data.data;
          setDataList(dataa);
        } else {
          openNotification('danger', res.data.message);
        }
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        console.log('Errorr:', err);
      });
  };

  const handleCloseForm = () => {
    setOpenForm(false);
    setSelectedDesa(null);
  };

  const handleSearch = () => {
    getList(profile.wilayah_id);
  };

  useEffect(() => {
    getUserById(userId);
  }, []);

  useEffect(() => {
    if (profile.wilayah_id) getList(profile.wilayah_id);
  }, [profile]);

  return (
    <>
      <div className={styleCss.DataSaya}>
        <div>
          <Typography className={styleCss.JudulDataSaya}>
            <ArrowDropDownIcon style={{ color: '#00A6A6' }} />
            <p>Desa Desi</p>
          </Typography>
          <div style={{ margin: '0 2em' }}>
            {!isLoading && (
              <Paper className={styleCss.paperSearch}>
                <InputBase
                  className={styleCss.inputSearch}
                  placeholder="Cari Desa..."
                  value={search}
                  onChange={e => setSearch(e.target.value)}
                />
                <IconButton
                  type="button"
                  className={styleCss.iconButton}
                  aria-label="search"
                  onClick={handleSearch}
                >
                  <SearchIcon />
                </IconButton>
              </Paper>
            )}
          </div>
          <div
            style={{
              overflowX: 'auto',
              overflowY: 'auto',
              margin: ' 15px 15px',
              marginBottom: '50px',
              maxHeight: '500px',
            }}
          >
            {isLoading ? (
              <Skeleton variant="rect" width="100%">
                <div style={{ height: 200 }} />
              </Skeleton>
            ) : (
              <table className={styleCss.TableValidasiBahaya}>
                <thead>
                  <tr>
                    <th style={{ backgroundColor: '#1CF4D5' }}>No</th>
                    <th>Desa</th>
                    <th>Kecamatan</th>
                    <th className={styleCss.AprvTh}>Action </th>
                  </tr>
                </thead>
                <tbody>
                  {dataList.map((dt, index) => (
                    <tr key={`data-list_${dt.id}`}>
                      <td style={{ backgroundColor: '#c4fbf2 ' }}>
                        {index + 1}
                      </td>
                      <td>{dt.name}</td>
                      <td>{dt.kecamatan__name}</td>
                      <td>
                        {dt.max_resiko && dt.max_resiko > 0.6 && (
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            style={{
                              backgroundColor: '#00A6A6',
                              color: '#FFFFFF',
                            }}
                            onClick={() => {
                              setOpenForm(true);
                              setSelectedDesa(dt.id);
                            }}
                          >
                            Form
                          </Button>
                        )}
                      </td>
                    </tr>
                  ))}
                  {dataList.length === 0 && !isLoading && (
                    <tr>
                      <td colSpan="4">
                        <FontAwesomeIcon icon={faExclamationCircle} />
                        Data Belum ada
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
          </div>
          {isOpenForm && (
            <FormDesaDesi
              idDesa={selectedDesa}
              openNotification={openNotification}
              handleCloseForm={handleCloseForm}
            />
          )}
        </div>
      </div>
    </>
  );
}

TabelDesaDesi.propTypes = {
  openNotification: PropTypes.func,
  userId: PropTypes.number,
};

export default TabelDesaDesi;

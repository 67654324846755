import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// @material-ui/core
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';

// @material-ui/icons
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// core components
import GridContainer from 'components/Grid/GridContainer.js';

// configs
import api from 'config/api';
import globalFunc from 'config/globalFunc';

import styleCss from 'assets/css/views/global.module.css';

function AdminActivities({ userId }) {
  // states
  const [loadActivity, setLoadActivity] = useState(true);
  const [dataActivity, setDataActivity] = useState([]);

  const convertDate = newDate =>
    newDate.map(item => {
      let temp = Object.assign({}, item);
      temp.created_date = temp.created_date.split(' ')[0];
      return temp;
    });

  const getActivity = id => {
    setLoadActivity(true);
    api
      .getActivityById(id)
      .then(res => {
        const responseBody = res.data;
        if (responseBody.error_code === 0) {
          let { data } = responseBody;
          data.reverse();
          data = convertDate(data);
          data =
            data.length === 0
              ? []
              : globalFunc.groupBy(data, c => c.created_date);

          setDataActivity(Object.entries(data));
        }
        setLoadActivity(false);
      })
      .catch(err => {
        setLoadActivity(false);
        console.log('an error occured when get activities:', err);
      });
  };

  useEffect(() => {
    getActivity(userId);
  }, []);

  return (
    <div className={styleCss.RecentActivities}>
      <div className={styleCss.TextRecentActivities}>
        <GridContainer>
          <ArrowDropDownIcon style={{ color: '#00A6A6' }} />
          <Typography className={styleCss.TextRecentActivitiesTypo}>
            Recent Activities
          </Typography>
        </GridContainer>
        {loadActivity ? (
          <div>
            <div style={{ width: 100 }}>
              <Skeleton variant="text" />{' '}
            </div>
            <Skeleton variant="text" width="200" />
            <Skeleton variant="text" width="200" />
            <Skeleton variant="text" width="200" />
            <Skeleton variant="text" width="200" />
            <div style={{ width: 100 }}>
              <Skeleton variant="text" />{' '}
            </div>
            <Skeleton variant="text" width="200" />
            <Skeleton variant="text" width="200" />
            <Skeleton variant="text" width="200" />
            <Skeleton variant="text" width="200" />
            <div style={{ width: 100 }}>
              <Skeleton variant="text" />{' '}
            </div>
            <Skeleton variant="text" width="200" />
            <Skeleton variant="text" width="200" />
            <Skeleton variant="text" width="200" />
            <Skeleton variant="text" width="200" />

            <div style={{ width: 100 }}>
              <Skeleton variant="text" />{' '}
            </div>
            <Skeleton variant="text" width="200" />
            <Skeleton variant="text" width="200" />
            <Skeleton variant="text" width="200" />
            <Skeleton variant="text" width="200" />
          </div>
        ) : (
          dataActivity.map((dact, index) => {
            return (
              <div key={'da' + index}>
                <div className={styleCss.TextRecentActivitiesBorder}>
                  <span className={styleCss.TextRecentActivitiesSPan}>
                    {globalFunc.formatDate(dact[0])}
                  </span>
                </div>

                <div className={styleCss.marginToday}>
                  <GridContainer>
                    {dact[1].map((dactC, index2) => {
                      return (
                        <div
                          key={'dac' + index + '' + index2}
                          className={styleCss.activityContainer}
                        >
                          <FiberManualRecordIcon
                            className={styleCss.IconToday}
                          />
                          <span className={styleCss.textToday}>
                            {dactC.action}
                          </span>
                          <span className={styleCss.labelToday}>
                            {' '}
                            - {dactC.remarks}{' '}
                          </span>
                        </div>
                      );
                    })}
                  </GridContainer>
                </div>
              </div>
            );
          })
        )}
        {dataActivity.length === 0 && !loadActivity && (
          <div
            className={styleCss.textToday}
            style={{ textAlign: 'center', marginTop: 20 }}
          >
            <FontAwesomeIcon icon={faExclamationCircle} />
            Belum ada aktivitas
          </div>
        )}
      </div>
    </div>
  );
}

AdminActivities.propTypes = {
  userId: PropTypes.number,
};

export default AdminActivities;

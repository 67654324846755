import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

// @material-ui/core
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

// @material-ui/icons
import CloseIcon from '@material-ui/icons/Close';
import {
  faCity,
  faLandmark,
  faBuilding,
  faMarker,
  faMapMarkerAlt,
  faMapPin,
  faTint,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Context
import { AdminLayoutContext } from 'layouts/Admin';

// configs
import api from 'config/api';

import styleCss from 'assets/css/views/global.module.css';
import { UserContext } from 'App';

function UploadProgramDialog({ getList, isDialogOpen, toggleDialog }) {
  // context
  const { openNotification, setLayoutLoading } = useContext(AdminLayoutContext);
  const { name } = useContext(UserContext);
  // constants
  const listIcon = [
    { name: 'faMarker', icon: faMarker },
    { name: 'faMapMarkerAlt', icon: faMapMarkerAlt },
    { name: 'faMapPin', icon: faMapPin },
    { name: 'faTint', icon: faTint },
    { name: 'faBuilding', icon: faBuilding },
    { name: 'faLandmark', icon: faLandmark },
    { name: 'faCity', icon: faCity },
  ];

  // states
  const [layerType, setLayerType] = useState('api');
  const [layerName, setLayerName] = useState('');
  const [apiName, setApiName] = useState('');
  const [excelInput, setExcelInput] = useState('');
  const [selectedIcon, setSelectedIcon] = useState('');

  const doUpload = () => {
    setLayoutLoading(true);

    if (layerType === 'api') {
      const data = {
        layer_name: layerName,
        program_name: name,
        api_target: apiName,
        url_legend: apiName,
      };
      api
        .uploadProgramAPI(data)
        .then(res => {
          if (res.data.error_code === 0) {
            openNotification('success', res.data.message);
            getList();
            toggleDialog();
          } else {
            openNotification('danger', res.data.message);
          }
        })
        .catch(err => {
          console.log('upload program - api error:', err);
        })
        .finally(() => setLayoutLoading(false));
    } else {
      const formData = new FormData();
      formData.append('file', excelInput);
      formData.append('layer_name', layerName);
      formData.append('program_name', localStorage.getItem('name'));
      formData.append('map_pin', selectedIcon);
      api
        .uploadProgramExcel(formData)
        .then(res => {
          if (res.data.error_code === 0) {
            openNotification('success', res.data.message);
            getList();
            toggleDialog();
          } else {
            openNotification('danger', res.data.message);
          }
        })
        .catch(err => {
          console.log('Upload program - excel error:', err);
        })
        .finally(() => setLayoutLoading(false));
    }
  };

  return (
    <Dialog
      className={styleCss.dialogPopUpUbahPassword}
      open={isDialogOpen}
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle id="notification-dialog-slide-title">
        <IconButton
          aria-label="close"
          className={styleCss.closeButtonDialog}
          onClick={toggleDialog}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <div className={styleCss.divKonfirmasiTindakan}>
        <Typography className={styleCss.JudulDialogUploadData}>
          Upload data APIK
        </Typography>
      </div>
      <DialogContent className={styleCss.dialogPopUpConntenDataUpload}>
        <div>
          <div>
            <div className={styleCss.uploadInputTitle}>Nama File</div>
            <Input
              className={styleCss.uploadInputText}
              onChange={e => setLayerName(e.target.value)}
              placeholder="Ketik disini..."
            ></Input>
          </div>
          <div style={{ marginTop: 20, marginBottom: 20 }}>
            <div
              className={styleCss.uploadRadioChoice}
              onClick={e => setLayerType(e.target.value)}
            >
              <input type="radio" name="site_name" value="api" defaultChecked />{' '}
              API / URL
            </div>
            <div>
              <Input
                className={styleCss.uploadInputText2}
                value={apiName}
                onChange={e => setApiName(e.target.value)}
                disableUnderline
                placeholder="Ketik disini..."
                disabled={layerType === 'api' ? false : true}
              />
            </div>
            <div
              className={styleCss.uploadRadioChoice}
              onClick={e => setLayerType(e.target.value)}
            >
              <input type="radio" name="site_name" value="excel" /> File Excel
            </div>
            <div>
              <input
                type="file"
                className={styleCss.uploadInputText}
                onChange={e => setExcelInput(e.target.files[0])}
                disabled={layerType === 'api' ? true : false}
              />
            </div>
            {layerType === 'excel' && (
              <div style={{ float: 'right' }}>
                <span className={styleCss.uploadInputIcon}>
                  pilih Icon lokasi titik :{' '}
                </span>
                <Select
                  className={styleCss.iconSelector}
                  disableUnderline
                  onChange={e => setSelectedIcon(e.target.value)}
                  name="selectedIcon"
                >
                  <MenuItem value="" disabled />
                  {listIcon.map(data => (
                    <MenuItem key={data.id} value={data.name}>
                      <FontAwesomeIcon
                        className={styleCss.uploadInputIcon}
                        icon={data.icon}
                      />
                    </MenuItem>
                  ))}
                </Select>
              </div>
            )}
          </div>
        </div>
        <div style={{ marginTop: 50 }}>
          <Button className={styleCss.buttonLogin} onClick={doUpload}>
            Upload
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

UploadProgramDialog.propTypes = {
  getList: PropTypes.func,
  isDialogOpen: PropTypes.bool,
  toggleDialog: PropTypes.func,
};

export default UploadProgramDialog;

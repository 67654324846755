import React, { useEffect, useState } from 'react';
import api from 'config/api';
import PropTypes from 'prop-types';

// @material-ui/core
import Typography from '@material-ui/core/Typography';

// @material-ui/icons
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

// @material-ui/lab
import Skeleton from '@material-ui/lab/Skeleton';

// assets
import styleCss from 'assets/css/views/global.module.css';

function AdaptasiDataBahaya({ userId }) {
  const [loadAdaptasi, setLoadAdaptasi] = useState(true);
  const [dataAdaptasi, setDataAdaptasi] = useState([]);
  const [head, setHead] = useState([]);
  const [body, setbody] = useState([]);
  const [bodyLength, setBodyLength] = useState([]);

  const getAdaptasi = id => {
    setLoadAdaptasi(true);
    api
      .getAdaptasiProfile(id)
      .then(res => {
        if (res.data.error_code === 0) {
          let dk = res.data.data;
          let dHead = [];
          let dBody = [];
          let lengthh = [];
          for (let i = 0; i < dk.length; i++) {
            dHead.push(dk[i].wilayah__name);
            for (let j = 0; j < dk[i].details.length; j++) {
              if (dBody[i] === undefined) {
                dBody.push([
                  Object.keys(dk[i].details[j]).length !== 0
                    ? dk[i].details[j].adaptation
                    : '',
                ]);
              } else {
                dBody[i].push(
                  Object.keys(dk[i].details[j]).length !== 0
                    ? dk[i].details[j].adaptation
                    : '',
                );
              }
              if (lengthh[j] === undefined) {
                lengthh.push(1);
              }
            }
          }
          if (dBody.length < dHead.length) {
            for (let i = 0; i < dHead.length; i++) {
              if (dBody[i] === undefined) {
                dBody.push([]);
              }
            }
          }
          for (let i = 0; i < dBody.length; i++) {
            for (let j = 0; j < lengthh.length; j++) {
              if (dBody[i][j] === undefined) {
                dBody[i].push('-');
              }
            }
          }
          setBodyLength(lengthh);
          setbody(dBody);
          setHead(dHead);
          setDataAdaptasi(dk);
        }
        setLoadAdaptasi(false);
      })
      .catch(err => {
        setLoadAdaptasi(false);
        console.log('Errorr:', err);
      });
  };

  useEffect(() => {
    getAdaptasi(userId);
  }, []);

  return (
    <div>
      <Typography className={styleCss.JudulIsiRingkasanValidasiData}>
        <ArrowDropDownIcon style={{ color: '#00A6A6' }} />
        Pilihan Adaptasi
      </Typography>
      <div style={{ overflowX: 'auto', marginLeft: '50px' }}>
        {loadAdaptasi ? (
          <Skeleton variant="rect" width="100%">
            <div style={{ height: 200 }} />
          </Skeleton>
        ) : (
          <table className={styleCss.TableValidasiBahaya}>
            <tr>
              <th>No</th>
              {head.length === 0 ? (
                <th>Data Desa</th>
              ) : (
                head.map(dh => {
                  return <th>{dh} </th>;
                })
              )}
            </tr>
            {body.length === 0 ? (
              <tr>
                <td colSpan="2">
                  <FontAwesomeIcon icon={faExclamationCircle} /> Belum ada data
                  Adaptasi
                </td>
              </tr>
            ) : (
              bodyLength.map((da, index) => {
                return (
                  <tr>
                    <td>{index + 1}</td>
                    {head.map((dh, indexC) => {
                      return <td>{body[indexC][index]}</td>;
                    })}
                  </tr>
                );
              })
            )}
          </table>
        )}
      </div>
    </div>
  );
}

AdaptasiDataBahaya.propTypes = {
  userId: PropTypes.number,
};

export default AdaptasiDataBahaya;

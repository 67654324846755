import React, { useEffect, useState } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { Radar, Chart } from 'react-chartjs-2';
// @material-ui/core
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

// core components
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Paper from '@material-ui/core/Paper';
import Snackbar from 'components/Snackbar/Snackbar.js';

import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Checkbox from '@material-ui/core/Checkbox';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import Navbar from 'components/Navbars/Navbar.js';

// Css & js Style
import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import styleCss from '../../assets/css/views/global.module.css';

// Icons
import CheckIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/Error';
import NotesIcon from '@material-ui/icons/Notes';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import SearchIcon from '@material-ui/icons/Search';
import ReplayIcon from '@material-ui/icons/Replay';
import PrintIcon from '@material-ui/icons/Print';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCity,
  faLandmark,
  faArchway,
  faBuilding,
  faLayerGroup,
  faExclamationCircle,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';

import { Divider, Typography } from '@material-ui/core';

import {
  Map,
  TileLayer,
  ZoomControl,
  GeoJSON,
  WMSTileLayer,
} from 'react-leaflet';
import L from 'leaflet';
import api from 'config/api';
import gf from 'config/globalFunc';
import Control from 'react-leaflet-control';
import CircularProgress from '@material-ui/core/CircularProgress';

Chart.defaults.global.legend.display = false;
const useStyles = makeStyles(styles);
const BootstrapInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
    cursor: 'pointer !important',
  },
  input: {
    cursor: 'pointer !important',
    position: 'relative',
    backgroundColor: '#00A6A6 ',
    border: '1px solid #ced4da',
    borderRadius: '30px',
    fontSize: 12,
    height: 17,
    color: '#FFFFFF',
    padding: '9px 10px 10px 18px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: ['"Helvetica Neue"', 'Arial', 'Helvetica', '"sans-serif"'].join(
      ',',
    ),
    '&:focus': {
      borderRadius: '30px',
      color: '#00000',
      borderColor: '#80bdff',
      backgroundColor: '#00A6A6 ',
      // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
    '&:disabled': {
      cursor: 'not-allowed !important',
    },
  },
}))(InputBase);

const mapRef = React.createRef(null);

export default function Home({ isInformationBarVisible, toggleInfomationbar }) {
  const history = useHistory();

  const [alert, setAlert] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadOverlay, setIsLoadOverlay] = useState(false);
  const [geoJsonData, setGeoJsonData] = useState();
  const [loadGeoJson, setLoadGeoJson] = useState(true);
  const [dropdownCodeMap, setDropdownCodeMap] = useState('');

  const [leftMap, setLeftMap] = useState(false);
  const [rightMap, setRightMap] = useState(false);
  const [btnValidasi, setBtnValidasi] = useState(false);
  const [valueValidasi, setValueValidasi] = useState(false);
  const [baseLayer, setBaseLayer] = useState(false);
  const [disabledDd, setDisabledDd] = useState(false);
  const [selectedDesa, setSelectedDesa] = useState('');
  const [dataDaerah, setDataDaerah] = useState({
    prov: '',
    kabkot: '',
    kec: '',
    desa: '',
  });

  const [dropdown, setDropdown] = useState({
    tingkat: '',
    jenis: '',
    tahun: '',
    model: '',
  });
  const [listTahun, setListTahun] = useState([]);
  const [listTingkat, setListTingkat] = useState([]);
  const [listJenis, setListJenis] = useState([]);
  const [listModel, setListModel] = useState([]);
  const [listLayer, setListLayer] = useState([]);
  const [dataLegend, setDataLegend] = useState(false);
  const [listLegend, setListLegend] = useState([]);
  const [rightSideTitle, setRightSideTitle] = useState({
    tingkat: { name: null },
    jenis: { name: '' },
  });
  const [isReset, setIsReset] = useState(false);
  const [dataUser, setDataUser] = useState(false);
  const [isValidated, setIsValidated] = useState(false);
  const [isCreate, setIsCreate] = useState(false);
  const [dataBahayaParams, setDataBahayaParams] = useState({});
  const [idEdit, setIdEdit] = useState('');

  const handleChangeSelect = e => {
    setIsReset(true);

    let states = e.target.name;
    let values = JSON.parse(e.target.value);
    console.log(values);
    if (states === 'tingkat') {
      getMasterType('Jenis', values.key);
      getMasterType('Model', values.key);
      getMasterType('Period', values.key);
    }
    setDropdown({
      ...dropdown,
      jenis: states === 'tingkat' ? '' : dropdown.jenis,
      tahun: states === 'tingkat' || states === 'jenis' ? '' : dropdown.tahun,
      model:
        states === 'tingkat' || states === 'jenis' || states === 'tahun'
          ? ''
          : dropdown.model,
      [states]: e.target.value,
    });
  };

  const getMasterType = (type, group) => {
    api
      .getMasterType(type, group)
      .then(res => {
        // setIsLoading(true);
        if (res.data.error_code === 0) {
          if (type === 'Level') {
            let dataL = res.data.data.filter(function(el) {
              return el.type_id === 1;
            });
            setListTingkat(dataL);
          } else if (type === 'Jenis') {
            setListJenis(res.data.data);
          } else if (type === 'Period') {
            setListTahun(res.data.data);
          } else if (type === 'Model') {
            setListModel(res.data.data);
          } else if (type === 'Legend') {
            res.data.data[0].data.reverse();
            setListLegend(res.data.data[0].data);
          }
        }
      })
      .catch(err => {
        setIsLoading(false);

        console.log('Errorr:', err);
      });
  };

  const getGeojson = (dtingkat, djenis, dtahun, dmodel) => {
    setLoadGeoJson(true);
    setIsReset(true);
    setIsLoading(true);
    console.log(dataUser, 'isi DATA USER');
    let comb = dataUser.wilayah__prov_no + '' + dataUser.wilayah__kab_kot_no;
    console.log(comb, 'isi comb');
    api
      .getGeojsonDesa('desa', comb)
      .then(res => {
        setIsLoading(false);
        if (res.data.error_code === 0) {
          let datae = res.data.data;
          setGeoJsonData(datae);

          const map = mapRef.current.leafletElement;
          let gjson = L.geoJson(res.data.data, {
            style: {
              color: '#00A6A6',
              weight: 1,
              opacity: 1,
              fillOpacity: 0,
            },
          }).addTo(map);
          let bounds = gjson.getBounds();
          map.fitBounds(bounds);

          gjson.eachLayer(function(layer) {
            //  initMaps(data);
            layer.addEventListener('click', function() {
              let lay = layer.feature.properties;
              console.log(dtingkat);

              getBahayaByParam(lay.IDDESA, dtingkat, djenis, dtahun, dmodel);
              getDataSide(lay.IDDESA, dtingkat, djenis, dtahun, dmodel);
              setSelectedDesa(lay.IDDESA);
              setDataDaerah({
                prov: lay.PROVINSI,
                kabkot: lay.KABKOT,
                kec: lay.KECAMATAN,
                desa: lay.DESA,
              });
            });
            layer.addEventListener('mouseover', () => {
              let lay = layer.feature.properties;
              let dataSend = lay.DESA;
              layer.bindTooltip('<div>' + dataSend + '</div>').openTooltip();
            });
          });
        }
        setLoadGeoJson(false);
      })
      .catch(err => {
        setIsLoading(false);
        console.log('Errorr:', err);
      });
  };

  const setSidebar = state => {
    if (state === 'left') {
      setLeftMap(!leftMap);
    } else {
      if (dropdown.tahun && dropdown.jenis && dropdown.tingkat && isValidated) {
        // let parsemodel = JSON.parse(dropdown.model)

        if (dropdown.model) {
          setRightMap(!rightMap);
          toggleInfomationbar(!isInformationBarVisible);
        }
      } else {
        openNotification(
          'danger',
          'Mohon lengkapi data dropdown dan pilih peta dahulu.',
        );
      }
    }
  };
  const getMapLayer = () => {
    api
      .getLayers()
      .then(res => {
        // setIsLoading(true);
        if (res.data.error_code === 0) {
          setListLayer(res.data.data);
        } else {
        }
      })
      .catch(err => {
        setIsLoading(false);
        // alert('gagal load peta')
        console.log('Errorr:', err);
      });
  };
  const reset = () => {
    window.location.reload();
  };

  const searchData = () => {
    if (!dropdown.tingkat) {
      openNotification('danger', 'Tingkat harus dipilih!');
      return;
    } else if (!dropdown.jenis) {
      openNotification('danger', 'Jenis harus dipilih!');
      return;
    } else if (!dropdown.tahun) {
      openNotification('danger', 'Tahun harus dipilih!');
      return;
    } else {
      let dtingkat = JSON.parse(dropdown.tingkat);
      let djenis = JSON.parse(dropdown.jenis);
      let dtahun = JSON.parse(dropdown.tahun);
      let dmodel = JSON.parse(dropdown.model);
      let comb = '';
      if (dtingkat.name === 'Keterpaparan' || dtingkat.name === 'Kerentanan') {
        comb = dtingkat.code + djenis.code + dtahun.code + djenis.code;
      } else {
        comb = dtingkat.code + djenis.code + dtahun.code + dmodel.code;
      }
      console.log(dmodel, 'isi model');
      setRightSideTitle({
        tingkat: dtingkat,
        jenis: djenis,
        tahun: dtahun,
        model: dmodel,
      });
      setDropdownCodeMap('apik:' + comb);
      setDisabledDd(true);
      setBaseLayer(true);
      setDataLegend(true);
      getGeojson(dtingkat, djenis, dtahun, dmodel);
    }
  };
  const getBahayaByParam = (iddesa, dtingkat, djenis, dtahun, dmodel) => {
    api
      .getBahayaByParam(iddesa, dtingkat, djenis, dtahun, dmodel)
      .then(res => {
        if (res.data.error_code === 0) {
          let dats = res.data.data;

          if (dats.length !== 0) {
            let val = gf.calculateRadar(djenis.type_id, dats);

            let dradar = {
              labels: ['SU M', 'SU T', 'SU O', 'CH M', 'CH T', 'CH T'],
              datasets: [
                {
                  data: [val.sumFinal, val.sutFinal, val.suoFinal, 0, 0, 0],
                  backgroundColor: 'rgba(0,166,166,0.5)',
                  borderColor: 'rgba(0,166,166,1)',
                  pointBackgroundColor: 'rgba(0,166,166,1)',
                  pointBorderColor: '#fff',
                  pointHoverBackgroundColor: '#fff',
                  pointHoverBorderColor: 'rgba(0,166,166,1)',
                  label: 'SU',
                },
                {
                  data: [0, 0, 0, val.dataCHM, val.dataCHT, val.dataCHO],
                  backgroundColor: 'rgba(255,99,132,0.5)',
                  borderColor: 'rgba(255,99,132,1)',
                  pointBackgroundColor: 'rgba(255,99,132,1)',
                  pointBorderColor: '#fff',
                  pointHoverBackgroundColor: '#fff',
                  pointHoverBorderColor: 'rgba(255,99,132,1)',
                  label: 'CH',
                },
              ],
            };
            let options = {
              responsive: true,
              maintainAspectRatio: false,
            };
            setDataBahayaParams({ data: dradar, options: options });
          }
        }
      })
      .catch(err => {
        setIsLoading(false);
        console.log('Errorr:', err);
      });
  };
  const doSubmit = () => {
    if (isCreate) {
      createValidasi();
    } else {
      editValidasi();
    }
  };
  const createValidasi = () => {
    setIsLoadOverlay(true);
    let dats = {
      wilayah_id: selectedDesa,
      level_id: rightSideTitle.tingkat.type_id,
      penyakit_id: rightSideTitle.jenis.type_id,
      model_id: rightSideTitle.model.type_id,
      period_id: rightSideTitle.tahun.type_id,
      validation_value: btnValidasi,
      kejadian: btnValidasi ? valueValidasi : 0,
    };
    api
      .createValidasi(dats)
      .then(res => {
        setIsLoadOverlay(false);
        if (res.data.error_code === 0) {
          openNotification('success', 'Berhasil Menyimpan data.');
        } else {
          openNotification('danger', res.data.message);
        }
      })
      .catch(err => {
        setIsLoadOverlay(false);
        console.log('Errorr:', err);
      });
  };
  const editValidasi = () => {
    setIsLoadOverlay(true);
    let dats = {
      id: idEdit,
      wilayah_id: selectedDesa,
      level_id: rightSideTitle.tingkat.type_id,
      penyakit_id: rightSideTitle.jenis.type_id,
      model_id: rightSideTitle.model.type_id,
      period_id: rightSideTitle.tahun.type_id,
      validation_value: btnValidasi,
      kejadian: btnValidasi ? valueValidasi : 0,
    };
    api
      .editValidasi(dats)
      .then(res => {
        setIsLoadOverlay(false);
        if (res.data.error_code === 0) {
          openNotification('success', 'Berhasil Mengubah data.');
        } else {
          openNotification('danger', res.data.message);
        }
      })
      .catch(err => {
        setIsLoadOverlay(false);
        console.log('Errorr:', err);
      });
  };
  const getDataSide = (iddesa, dtingkat, djenis, dtahun, dmodel) => {
    api
      .getValidasi(iddesa, dtingkat, djenis, dtahun, dmodel)
      .then(res => {
        if (res.data.error_code === 0) {
          setIsValidated(true);
          let dats = res.data.data;
          if (dats.length === 0) {
            setValueValidasi(0);
            setBtnValidasi(false);
            setRightMap(true);
            toggleInfomationbar(true);
            setIsCreate(true);
          } else {
            const dataDesa = dats.find(item => item.wilayah_id === iddesa);
            if (dataDesa) {
              setIsCreate(false);
              setValueValidasi(dataDesa.kejadian);
              setBtnValidasi(dataDesa.validation_value);
              setRightMap(true);
              toggleInfomationbar(true);
              setIdEdit(dataDesa.id);
            } else {
              setValueValidasi(0);
              setBtnValidasi(false);
              setRightMap(true);
              toggleInfomationbar(true);
              setIsCreate(true);
            }
          }
        }
      })
      .catch(err => {
        setIsLoading(false);
        console.log('Errorr:', err);
      });
  };

  function closeNotification() {
    setAlert({
      isAlertOpen: false,
      alertState: 'danger',
    });
  }
  function openNotification(condition, Message) {
    if (condition === 'success') {
      setAlert({
        isAlertOpen: true,
        alertState: 'success',
        AlertMessage: Message,
      });
    } else {
      setAlert({
        isAlertOpen: true,
        alertState: 'danger',
        AlertMessage: Message,
      });
    }
    setTimeout(
      function() {
        closeNotification();
      }.bind(this),
      5000,
    );
  }

  const getUserByEmail = id => {
    setIsLoading(true);
    api
      .getUserByEmail(id)
      .then(res => {
        if (res.data.error_code === 0) {
          setDataUser(res.data.data[0]);
        }
        setIsLoading(false);
      })
      .catch(err => {
        setIsLoading(false);
        console.log('Errorr:', err);
      });
  };

  useEffect(() => {
    if (!localStorage.getItem('token')) history.push('home');
    getMasterType('Level');
    getMasterType('Legend');
    getMapLayer();
    getUserByEmail(localStorage.getItem('email'));

    //get native Map instance
  }, []);

  return (
    <div className={styleCss.mapContainer}>
      <LoadingOverlay
        active={isLoadOverlay}
        spinner
        text="Sedang menyimpan data..."
        style={{ color: 'white' }}
      ></LoadingOverlay>
      <Navbar
        // routes={routes}
        // handleDrawerToggle={handleDrawerToggle}
        // {...rest}
        width={rightMap ? 'calc(100% - 400px)' : '100%'}
      />
      <Snackbar
        place="tc"
        color={alert.alertState}
        icon={alert.alertState === 'success' ? CheckIcon : ErrorIcon}
        message={alert.AlertMessage}
        open={alert.isAlertOpen}
        closeNotification={() => closeNotification()}
        close
      />
      <div className={styleCss.selectHome}>
        <FormControl>
          <GridContainer>
            <NativeSelect
              className={styleCss.SelectCari}
              style={{ marginRight: '10px' }}
              id="demo-customized-select-native"
              value={dropdown.tingkat}
              name="tingkat"
              onChange={handleChangeSelect}
              input={<BootstrapInput />}
              disabled={disabledDd}
            >
              <option
                style={{ color: '#000000', fontWeight: 300 }}
                aria-label="None"
                value=""
                disabled
              >
                Pilihan Tingkat
              </option>
              {listTingkat.map((drp, index) => {
                return (
                  <option
                    style={{ color: '#000000' }}
                    key={'tingkat' + index}
                    value={JSON.stringify(drp)}
                  >
                    {drp.name}
                  </option>
                );
              })}
            </NativeSelect>

            <NativeSelect
              style={{ marginRight: '10px' }}
              id="demo-customized-select-native"
              value={dropdown.jenis}
              name="jenis"
              onChange={handleChangeSelect}
              input={<BootstrapInput />}
              disabled={disabledDd}
            >
              <option style={{ color: '#000000' }} aria-label="None" value="">
                Pilihan Jenis
              </option>
              {listJenis.map((drp, index) => {
                return (
                  <option
                    style={{ color: '#000000' }}
                    key={'jenis' + index}
                    value={JSON.stringify(drp)}
                  >
                    {drp.name}
                  </option>
                );
              })}
            </NativeSelect>

            <NativeSelect
              style={{ marginRight: '10px' }}
              id="demo-customized-select-native"
              value={dropdown.tahun}
              name="tahun"
              onChange={handleChangeSelect}
              input={<BootstrapInput />}
              disabled={disabledDd}
            >
              <option style={{ color: '#000000' }} aria-label="None" value="">
                Pilihan Tahun
              </option>
              {listTahun.map((drp, index) => {
                return (
                  <option
                    style={{ color: '#000000' }}
                    key={'tahun' + index}
                    value={JSON.stringify(drp)}
                  >
                    {drp.name}
                  </option>
                );
              })}
            </NativeSelect>

            {dropdown.jenis.includes('APIK') ||
            dropdown.jenis.includes('SIDIK') ? (
              ''
            ) : (
              <NativeSelect
                style={{ marginRight: '10px' }}
                id="demo-customized-select-native"
                value={dropdown.model}
                name="model"
                onChange={handleChangeSelect}
                input={<BootstrapInput />}
                disabled={disabledDd}
              >
                <option style={{ color: '#000000' }} aria-label="None" value="">
                  Pilihan Model
                </option>
                {listModel.map((drp, index) => {
                  return (
                    <option
                      style={{ color: '#000000' }}
                      key={'model' + index}
                      value={JSON.stringify(drp)}
                    >
                      {drp.name}
                    </option>
                  );
                })}
              </NativeSelect>
            )}
          </GridContainer>
        </FormControl>
        <IconButton
          aria-label="Cari"
          className={styleCss.ButtonCari}
          onClick={() => searchData()}
        >
          <ArrowForwardIcon />
        </IconButton>
        {isReset ? (
          <IconButton
            aria-label="Cari"
            className={styleCss.ButtonReset}
            onClick={() => reset()}
          >
            <ReplayIcon />
          </IconButton>
        ) : (
          ''
        )}
      </div>

      <div
        className={leftMap ? styleCss.sidebarLeft : styleCss.sidebarLeftNone}
      >
        <div
          className={
            leftMap ? styleCss.sidebarLeftIconActive : styleCss.sidebarLeftIcon
          }
          onClick={() => setSidebar('left')}
        >
          <span className={styleCss.layerspan}>
            <FontAwesomeIcon icon={faLayerGroup} /> Layer
          </span>
        </div>
        <div
          className={
            leftMap ? styleCss.sidebarLeftContent : styleCss.leftSidebarDataNone
          }
        >
          <div className={styleCss.sidebarLeftContentTitle}>
            Layer Properties
          </div>
          <div>
            <Paper className={styleCss.paperSearch}>
              <InputBase
                // type="button"
                className={styleCss.inputSearch}
                placeholder="Search Layer..."
              />
              <IconButton
                type="button"
                className={styleCss.iconButton}
                aria-label="search"
              >
                <SearchIcon />
              </IconButton>
            </Paper>
          </div>
          <div>
            <FormControlLabel
              style={{ width: '100%', padding: 5 }}
              control={
                <Checkbox
                  name="BaseLayer"
                  checked={baseLayer}
                  onClick={() => setBaseLayer(!baseLayer)}
                  className={styleCss.checkboxTextFormat}
                />
              }
              label="Base Layer"
            />
            {listLayer.map((lyr, index) => {
              return (
                <div>
                  <FormControl
                    component="fieldset"
                    className={styleCss.checkboxTextFormat}
                  >
                    <FormLabel
                      component="legend"
                      className={styleCss.checkboxTextFormatTitle}
                      style={{ fontWeight: '600 !important', width: '100%' }}
                    >
                      {lyr.created_by__name}
                    </FormLabel>
                    <FormGroup>
                      {lyr.layers.map((lyrC, index) => {
                        return (
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="gilad"
                                className={styleCss.checkboxTextFormat}
                              />
                            }
                            label={lyrC.program_name + ' - ' + lyrC.layer_name}
                          />
                        );
                      })}
                    </FormGroup>
                  </FormControl>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <Map
        center={[0.7893, 120.9213]}
        zoomControl={false}
        zoom={5}
        animate={false}
        className={rightMap ? styleCss.Maphalf : styleCss.Map}
        scrollWheelZoom={false}
        // onlayeradd={esrimap()}

        ref={mapRef}
      >
        {isLoading ? (
          <div>
            <CircularProgress className={styleCss.spinnerMap} />
          </div>
        ) : (
          <div>
            <TileLayer
              attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              zIndex={-2}
            />

            {baseLayer ? (
              <WMSTileLayer
                layers="apik:apik_desa"
                url="https://geoserver.piarea.co.id/geoserver/gwc/service/wms/"
                format="image/PNG"
                // opacity= "0.7"
                styles={dropdownCodeMap}
              />
            ) : (
              ''
            )}

            {listLegend.length > 0 ? (
              <Control position="bottomleft">
                <div className={styleCss.BoxLegend}>
                  <div className={styleCss.LegendCms}>
                    <GridContainer>
                      <GridItem xs={4}>
                        <NotesIcon className={styleCss.IconInfoLegendHeader} />
                      </GridItem>
                      <GridItem xs={8}>
                        <Typography className={styleCss.TextLegend}>
                          Legend
                        </Typography>
                      </GridItem>
                    </GridContainer>
                  </div>

                  {dataLegend ? (
                    <table>
                      {listLegend.map((dl, index) => {
                        return (
                          <tr>
                            <td>
                              {' '}
                              <div
                                style={{
                                  width: 14,
                                  height: 14,
                                  background: dl.fill,
                                  border: '1px solid' + dl.outline,
                                  marginLeft: 20,
                                  marginBottom: 5,
                                  marginTop: 5,
                                }}
                              ></div>
                            </td>
                            <td>{dl.range}</td>
                            <td>{dl.category}</td>
                          </tr>
                        );
                      })}
                    </table>
                  ) : (
                    <div style={{ padding: 20, textAlign: 'center' }}>
                      <FontAwesomeIcon icon={faExclamationCircle} /> Belum ada
                      data terpilih
                    </div>
                  )}
                </div>
              </Control>
            ) : (
              ''
            )}
            {loadGeoJson ? (
              <GeoJSON
                data={geoJsonData}
                style={{ color: '#00A6A6', weight: 1, opacity: 0.65 }}
              />
            ) : (
              ''
            )}

            <div style={{ marginTop: 50 }}>
              <ZoomControl position="topright"></ZoomControl>
            </div>
          </div>
        )}
      </Map>

      <div
        className={rightMap ? styleCss.sidebarRight : styleCss.sidebarRightNone}
      >
        <div className={styleCss.sidebarRightIcon} onClick={() => setSidebar()}>
          <FontAwesomeIcon
            icon={faInfoCircle}
            className={styleCss.sidebarRightIconPos}
          />
        </div>
        <div
          className={
            rightMap
              ? styleCss.sidebarRightContent
              : styleCss.sidebarRightContentNone
          }
        >
          <div className={styleCss.sidebarRightContentTitle}>
            Validasi Data {rightSideTitle.tingkat.name}
            {' - ' + rightSideTitle.jenis.name}
          </div>

          <table>
            <tr>
              <td style={{ fontWeight: 600 }}>Info Wilayah</td>
            </tr>
            <Divider />
            <tr>
              <td>
                <FontAwesomeIcon icon={faLandmark} /> Provinsi
              </td>
              <td>: {dataDaerah.prov}</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon icon={faCity} /> Kab/Kota
              </td>
              <td>: {dataDaerah.kabkot}</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon icon={faBuilding} /> Kecamatan
              </td>
              <td>: {dataDaerah.kec}</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon icon={faArchway} /> Desa/Kel
              </td>
              <td>: {dataDaerah.desa}</td>
            </tr>
            <tr>
              <td></td>
            </tr>
            <tr>
              <td></td>
            </tr>
            <tr>
              <td></td>
            </tr>
          </table>

          <div>
            <Radar
              data={dataBahayaParams.data}
              options={dataBahayaParams.options}
            />
          </div>

          <div>
            <div>
              {/* <Chart options={chartv.options} series={chartv.series} type="radar" height={350} /> */}
            </div>
            <div className={styleCss.validasiQuestion}>
              Apakah pernah terjadi Penyakit {rightSideTitle.jenis.name}?
            </div>
            <div>
              <Button
                style={{
                  marginRight: 10,
                  border: '1px solid #00A6A6',
                  width: 90,
                  borderRadius: 10,
                  color: !btnValidasi ? 'white' : '#00A6A6',
                  background: !btnValidasi ? '#00A6A6' : 'white',
                }}
                onClick={() => setBtnValidasi(false)}
              >
                Tidak
              </Button>
              <Button
                style={{
                  border: '1px solid #00A6A6',
                  width: 90,
                  borderRadius: 10,
                  color: btnValidasi ? 'white' : '#00A6A6',
                  background: btnValidasi ? '#00A6A6' : 'white',
                }}
                onClick={() => setBtnValidasi(true)}
              >
                Ya
              </Button>
            </div>

            {!btnValidasi ? (
              ''
            ) : (
              <div>
                <div className={styleCss.validasiQuestion}>
                  Berapa kali terjadi Penyakit {rightSideTitle.jenis.name} ?
                </div>
                <input
                  className={styleCss.validasiInput}
                  onChange={e => setValueValidasi(e.target.value)}
                  value={valueValidasi}
                />{' '}
                Kali
              </div>
            )}
          </div>

          <div style={{ marginTop: 10, textAlign: 'center' }}>
            <Button
              variant="outlined"
              onClick={() => doSubmit()}
              className={styleCss.btnSaveValidasi}
            >
              Save
            </Button>
            <IconButton className={styleCss.IconPrintBahayaTersimpans}>
              <PrintIcon />
            </IconButton>
          </div>
        </div>
      </div>

      <div className={styleCss.footerHome}>@2020 Copyright APIK</div>
    </div>
  );
}

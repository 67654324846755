import axios from 'axios';

// constants
// -- proxy for handling cors

// -- urls
const config = {
    headers: {
        'Access-Control-Allow-Origin': '*',
        // 'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
    }
};
// const base = "https://pi-dev.co.id/apik/api/"
const base = "https://api.apikkemkes.id/api/"
// const base = `http://localhost:8000/api/`;
const sso = `https://pi-dev.co.id/apik/sso/api/`;
const ssoLogin = `https://pi-dev.co.id/apik/sso/`;
const kalfor = `https://pi-dev.co.id/kalfor/`;

// header definitions
axios.defaults.baseURL = base;
axios.defaults.headers.common['Authorization'] =
  'Bearer ' + localStorage.getItem('token');

// request handlers
// -- layers
const getLayers = () => axios.get('map-layers');

// -- master
const getMasterType = (type, group) => {
  let hasKey = '';
  if (group) {
    hasKey = ',group:' + group;
  }
  return axios.get('master-type?criteria=type:' + type + hasKey);
};

// -- activity
const getActivityById = creatorId =>
  axios.get('activities', { params: { criteria: `created_by:${creatorId}` } });

// -- geo location
const getGeojson = (type, filter) => {
  let hasKey = '';
  if (filter) {
    hasKey = '&filter=' + filter;
  }

  console.log('FILTER_GEOJSON', filter);

  return axios.get('data-geo-json?layer=' + type + hasKey);
};

const getGeojsonDesa = (type, id) => {
  const idProv = id.substring(0, 2);
  const idKab = id.substring(2, 4);

  return axios.get('data-geo-json', {
    params: { layer: type, filter: idKab, prov_no: idProv, a: 111 },
  });
};

// -- locations
const getProvinsi = () => axios.get('provinsi');

const getKabupaten = provinceName =>
  axios.get('kab-kot', {
    params: { criteria: `provinsi__name:${provinceName}` },
  });

const getKecamatan = idDistrict =>
  axios.get('subdistrict/get', {
    params: { id_district: idDistrict },
  });

const getKelurahan = subdistrictId =>
  axios.get('village/get', {
    params: { id_subdistrict: subdistrictId },
  });

const getDesaByKabupaten = (idKabupaten, search) =>
  axios.get('desa-desi/by-kabupaten', {
    params: { kab_kot_id: idKabupaten, search },
  });

// -- search
const searchData = (
  wilayahId,
  levelTypeId,
  jenisTypeId,
  periodTypeId,
  modelTypeId,
) =>
  axios.get('faktor', {
    params: {
      wilayah_id: wilayahId,
      level_id: levelTypeId,
      jenis_id: jenisTypeId,
      period_id: periodTypeId,
      model_id: modelTypeId ? periodTypeId : '',
    },
  });

// -- bahaya
const getBahayaByDataVisual = userId =>
  axios.get('visualization-data', {
    params: { criteria: `user_id:${userId}` },
  });

const getBahayaByParam = (id, dtingkat, djenis, dtahun, dmodel) =>
  axios.get('bahaya-params', {
    params: {
      criteria:
        `wilayah_id:${id}` +
        `,penyakit_id:${djenis.type_id}` +
        `,period_id:${dtahun.type_id}` +
        (dmodel ? ',model_id:' + dmodel.type_id : ''),
    },
  });

const getKerentananByParam = (id, dtahun) =>
  axios.get('kerentanan-params-apik', {
    params: {
      criteria:
        `wilayah_id:${id}` +
        `,tahun:${
          dtahun.type_id === 1 ? new Date().getFullYear() : dtahun.type_id
        }`,
    },
  });

const getLayerPrograms = () => axios.get('list-layer-program');

// -- validasi
const getValidasi = (id, dtingkat, djenis, dtahun, dmodel) =>
  axios.get('ad-validations', {
    params: {
      criteria:
        `wilayah_id:${id}` +
        `,level_id:${dtingkat.type_id}` +
        `,penyakit_id:${djenis.type_id}` +
        `,period_id:${dtahun.type_id}` +
        dmodel
          ? `,model_id:${dmodel.type_id}`
          : '',
    },
  });

const getValidasiProfile = id =>
  axios.get('ad-validations', { params: { criteria: `created_by:${id}` } });

const createValidasi = data => axios.post('ad-validations', data);

const editValidasi = data => axios.put('ad-validations', data);

// -- adaptasi
const createAdaptasi = data => axios.post('ad-adaptations', data);

const editAdaptasi = data => axios.put('ad-adaptations', data);

const getAdaptasi = wilayahId =>
  axios.get('ad-adaptations', {
    params: {
      criteria: `wilayah_id:${wilayahId}`,
    },
  });

const getDefaultAdaptasi = (iddesa, levelId, jenisId, periodId, modelId) =>
  axios.get('master-adaptations-filtered', {
    params: {
      wilayah_id: iddesa,
      level_id: levelId,
      jenis_id: jenisId,
      period_id: periodId,
      model_id: modelId,
    },
  });

const getAdaptasiProfile = id =>
  axios.get('ad-adaptations', {
    params: { criteria: `created_by:${id}` },
  });

// -- kerentanan
const getKerentananParamUser = collectionId =>
  axios.get('kerentanan-params-user', {
    params: { criteria: `data_collection_id:${collectionId}` },
  });

const getKerentananTemplate = () =>
  `${base}kerentanan-params-user/download-template`;

const importDataKerentanan = data =>
  axios.post('kerentanan-params-user/import', data);

const deleteDataKerentanan = data_collection_id =>
  axios.delete('kerentanan-params-user', {
    params: { data_collection_id },
  });

// -- collection
const getDataCollection = id =>
  axios.get('data-collection', {
    params: { criteria: `created_by:${id}` },
  });

// -- visual
const getVisual = (id, dtingkat, djenis, dtahun, dmodel) => {
  console.log('model', dmodel);
  const criteria =
    'wilayah_id:' +
    id +
    ',penyakit_id:' +
    djenis.type_id +
    ',period_id:' +
    dtahun.type_id +
    dmodel
      ? ',model_id:' + dmodel.type_id
      : '';

  return axios.get('visualization-data', {
    params: {
      criteria,
    },
  });
};

const createVisual = data => axios.post('visualization-data', data);

const editVisual = data => axios.put('visualization-data', data);

// -- user auth
const getUserByEmail = email =>
  axios.get('user', { params: { criteria: `email:${email}` } });

const getUserById = id => {
  return axios.get('user', { params: { id } });
};

const updateProfile = data => axios.put('user', data);

const login = (username, password) => {
  const newData = new FormData();

  newData.append('grant_type', 'password');
  newData.append('client_id', '1');
  newData.append('client_secret', 'W3kbyNIgXyKrbh5QB9lRADsonRfzWUqOPmr9F2JS');
  newData.append('username', username);
  newData.append('password', password);

  return axios.post('oauth/token', newData, {
    baseURL: ssoLogin,
  });
};

const validateToken = () =>
  axios.get('user', {
    baseURL: sso,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  });

const register = data => axios.post('user/register', data);

const changePassword = data =>
  axios.post('password/change', data, { baseURL: sso });

// -- program
const getProgramExcelTemplate = () => `${base}form-layers/download-template`;

const getlayerProgramExcel = form_layer_id =>
  axios.get('form-data-layers', { params: { form_layer_id } });

const uploadProgramExcel = data => axios.post('form-layers/import', data);

const uploadProgramAPI = data => axios.post('api-layers', data);

// Markers
const fetchMarkerByAPI = (url, jenis, id) => {
  if (jenis === 'xlsx') {
    return axios.get('form-data-layers', { params: { form_layer_id: id } });
  }
  return axios.get(`${url}`);
};

const getProgramExcelPin = form_layer_id =>
  axios.get('form-data-layers', { params: { form_layer_id } });

// -- not used
const createProgram = data => {
  var newData = new FormData();
  newData.append('name_program', data.name_program);
  newData.append('id_level_program', data.programLevel);
  newData.append('description_program', data.description_program);
  newData.append('pj_program', data.pj_program);
  newData.append('from_date', data.from_date);
  newData.append('to_date', data.to_date);
  // newData.append("province_id", data.idProvinsi);
  // newData.append("district_id", data.idKabupaten);
  // newData.append("subdistrict_id", data.idKecamatan);
  // newData.append("village_id", data.idKelurahan);
  newData.append('created_by', 1);
  newData.append('photo', data.upload);

  return axios.post('program/post', newData);
};

// -- -- not used
const editProgram = data => {
  var newData = new FormData();
  newData.append('_method', 'PATCH');
  newData.append('id_program', data.id_program);
  newData.append('name_program', data.name_program);
  newData.append('id_level_program', data.programLevel);
  newData.append('description_program', data.description_program);
  newData.append('pj_program', data.pj_program);
  newData.append('from_date', data.from_date);
  newData.append('to_date', data.to_date);
  // newData.append("province_id", data.idProvinsi);
  // newData.append("district_id", data.idKabupaten);
  // newData.append("subdistrict_id", data.idKecamatan);
  // newData.append("village_id", data.idKelurahan);
  newData.append('image_survey', data.upload);
  newData.append('created_by', 1);

  if (data.upload) {
    newData.append('photo', data.upload);
  }
  return axios.post('program/update', newData);
};

const deleteProgram = id => {
  return axios.delete('program/delete?id_program=' + id);
};

const getKnowledgeProducts = (page, type, search) => {
  return axios.get(
    `knowledge-product?criteria=${type &&
      `${type}:`}${search}&page=${page}&limit=1000`,
  );
};

const createDesaDesi = data => {
  var newData = new FormData();
  newData.append('desa_id', data.desa_id);
  newData.append('answer', data.answer);

  return axios.post('desa-desi', newData);
};

const getDesaDesiByDesa = desa_id => {
  return axios.get('desa-desi/desa', { params: { desa_id } });
};

// -- legend
// -- -- not used
const dataLegend = () =>
  axios.get('https://pi-dev.co.id/kalfor/be/pi/api/data/legend');

export default {
  getProvinsi,
  getKabupaten,
  getKecamatan,
  getKelurahan,
  getLayers,
  getMasterType,
  getGeojson,
  searchData,
  getUserByEmail,
  getUserById,
  getActivityById,
  getKerentananParamUser,
  validateToken,
  updateProfile,
  getValidasi,
  getValidasiProfile,
  createAdaptasi,
  editValidasi,
  getBahayaByParam,
  getAdaptasi,
  getAdaptasiProfile,
  createValidasi,
  editAdaptasi,
  getGeojsonDesa,
  getBahayaByDataVisual,
  getVisual,
  createVisual,
  editVisual,
  getDefaultAdaptasi,
  getKerentananTemplate,
  getDataCollection,
  importDataKerentanan,
  deleteDataKerentanan,
  login,
  register,
  getLayerPrograms,
  uploadProgramAPI,
  uploadProgramExcel,
  getProgramExcelTemplate,
  getlayerProgramExcel,
  getProgramExcelPin,
  changePassword,
  fetchMarkerByAPI,
  dataLegend,
  getKerentananByParam,
  getKnowledgeProducts,
  getDesaByKabupaten,
  createDesaDesi,
  getDesaDesiByDesa,
};

import React, { useEffect, useState } from 'react';
import api from 'config/api';
import LoadingOverlay from 'react-loading-overlay';
import { useHistory } from 'react-router-dom';
import Snackbar from 'components/Snackbar/Snackbar.js';

// @material-ui/core
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

// @material-ui/icons
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/Error';

// core components
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';

import styleCss from 'assets/css/views/global.module.css';

export default function EditProfilAdminProgram() {
  const history = useHistory();

  // states
  const [isLoading, setIsLoading] = useState(true);
  const [alert, setAlert] = useState({});
  const [open, setOpen] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [loadUser, setLoadUser] = useState(false);
  const [dataUser, setDataUser] = useState(false);
  const [openDialogSubmit, setOpenDialogSubmit] = useState(false);
  const [openDialogYa, setOpenDialogYa] = useState(false);
  const [email, setEmail] = useState('');
  const [nomorKontak, setNomorKontak] = useState('');
  const [kabkot, setKabkot] = useState('');
  const [kepalaDinas, setKepalaDinas] = useState('');
  const [alamat, setAlamat] = useState('');
  const [penJawab, setPenJawab] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [rePassword, setRePassword] = useState('');
  const [nameChar, setNameChar] = useState('');

  const [values, setValues] = useState({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showPassword: false,
  });

  const [userProfile, setUserProfile] = useState({});

  function closeNotification() {
    setAlert({
      isAlertOpen: false,
      alertState: 'danger',
    });
  }

  function openNotification(condition, Message) {
    if (condition === 'success') {
      setAlert({
        isAlertOpen: true,
        alertState: 'success',
        AlertMessage: Message,
      });
    } else {
      setAlert({
        isAlertOpen: true,
        alertState: 'danger',
        AlertMessage: Message,
      });
    }
    setTimeout(
      function() {
        closeNotification();
      }.bind(this),
      5000,
    );
  }

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
    setOpenDialogYa(false);
  };
  const handleClickOpenSubmit = () => {
    setOpenDialogSubmit(true);
  };

  const handleCloseSubmit = () => {
    setOpenDialogSubmit(false);
  };
  const handleClickOpenYA = () => {
    setOpenDialogYa(true);
  };

  const handleClick = () => {
    setOpen(!open);
  };

  const getUserByEmail = email => {
    setLoadUser(true);
    setIsLoading(true);
    api
      .getUserByEmail(email)
      .then(res => {
        console.log('res.data', res);

        if (res.data.error_code === 0) {
          const datauser = res.data.data[0];
          const data = datauser.data;

          setNameChar(datauser.name.charAt(0));
          setEmail(datauser.email);
          setKabkot(datauser.wilayah__name);

          if (typeof data === 'object' && data !== null) {
            setNomorKontak(data.nomor ? data.nomor : '');
            setKepalaDinas(data.kepalaDinas ? data.kepalaDinas : '');
            setAlamat(data.alamat ? data.alamat : '');
            setPenJawab(data.penJawab ? data.penJawab : '');
          } else {
            setNomorKontak();
            setKepalaDinas('');
            setAlamat('');
            setPenJawab('');
          }

          datauser.data = data;

          console.log(datauser, 'isi data user');

          setDataUser(datauser);
          setLoadUser(false);
          setIsLoading(false);
        } else {
          history.push('home');
        }
      })
      .catch(err => {
        setLoadUser(false);
        console.log('Errorr:', err);
      });
  };

  const doUpdate = () => {
    if (newPassword !== rePassword) {
      return;
    }
    let dUpdate = {
      id: dataUser.id,
      name: kabkot,
      email: email,
      image: null,
      data: {
        kepalaDinas: kepalaDinas,
        nomor: nomorKontak,
        alamat: alamat,
        penJawab: penJawab,
      },
      active: true,
    };
    setIsLoading(true);
    api.updateProfile(dUpdate).then(res => {
      if (res.data.error_code === 0) {
        setOpenDialogYa(true);
        setOpenDialogSubmit(false);
        setIsLoading(false);
      }
    });
  };

  const doChangePassword = () => {
    let dUpdate = {
      id: dataUser.id,
      current_password: currentPassword,
      new_password: newPassword,
      new_confirm_passoword: newPassword,

      active: true,
    };
    setIsLoading(true);
    api
      .changePassword(dUpdate)
      .then(res => {
        if (res.data.error_code === 0) {
          setOpenDialogYa(true);
          setOpenDialogSubmit(false);
          setIsLoading(false);
        }
      })
      .catch(err => {
        setIsLoading(false);
        openNotification('danger', 'Password lama tidak sesuai');
        console.log('Errorr:', err.response);
      });
  };

  useEffect(() => {
    if (!localStorage.getItem('token')) history.push('home');
    getUserByEmail(localStorage.getItem('email'));
    //get native Map instance
  }, []);

  return (
    <div className={styleCss.ContainerEditProgramProfil}>
      <div
        style={{
          marginTop: '90px',
          marginLeft: '-70px',
          marginBottom: '-10px',
        }}
      >
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Link
            color="inherit"
            href="/program-profile"
            onClick={handleClick}
            className={styleCss.Link}
          >
            <ArrowBackIosIcon className={styleCss.IconBreadcrumbs} />
            Kembali ke Profil
          </Link>
        </Breadcrumbs>
      </div>

      <CardBody>
        <Card className={styleCss.CardEditProfilAdminProgram}>
          <div style={{ margin: '0px 30px' }}>
            <div className={styleCss.DivJudulEditUbah}>
              <Typography className={styleCss.EditProfil}>
                Edit Profil
              </Typography>
              <label>Ubah informasi profil akunmu di bawah ini</label>
            </div>
            <GridContainer>
              <GridItem md={12}>
                <div style={{ margin: '10px 10px' }}>
                  <div>
                    <div className={styleCss.borderLabel}>
                      <h5 className={styleCss.LabelEditProfilAkun}>
                        Informasi Akun
                      </h5>
                      <GridContainer>
                        <GridItem md={6}>
                          <div
                            style={{ marginLeft: '30px', marginBottom: '30px' }}
                          >
                            <IconButton className={styleCss.IconEditProfil}>
                              <Typography>
                                <div className={styleCss.nameEditProfil}>
                                  {' '}
                                  {nameChar}
                                </div>
                              </Typography>
                            </IconButton>
                          </div>
                        </GridItem>
                        <GridItem md={6}>
                          <Button className={styleCss.ButtonCopot}>
                            Copot
                          </Button>
                          <br />
                          <Button className={styleCss.ButtonUbah}>Ubah</Button>
                        </GridItem>
                      </GridContainer>
                      <div style={{ margin: '0px 11px' }}>
                        <Typography
                          className={styleCss.TypographyInformasiAkun}
                        >
                          Nama Program
                        </Typography>
                        <Input
                          className={styleCss.InputInformasiAkun}
                          value={kabkot}
                          onChange={e => setKabkot(e.target.value)}
                          placeholder="Kota/Kabupaten"
                        />
                        <Typography
                          className={styleCss.TypographyInformasiAkun}
                        >
                          Email
                        </Typography>
                        <Input
                          className={styleCss.InputInformasiAkun}
                          readOnly
                          value={email}
                          placeholder="Email"
                        ></Input>
                        <Typography
                          className={styleCss.TypographyInformasiAkun}
                        >
                          Nomor Kontak
                        </Typography>
                        <Input
                          className={styleCss.InputInformasiAkun}
                          value={nomorKontak}
                          onChange={e => setNomorKontak(e.target.value)}
                          placeholder="Nomor Kontak"
                        ></Input>
                        <div>
                          <Typography
                            onClick={handleClickOpen}
                            className={styleCss.TypographyUbahPassword}
                          >
                            Ubah Password
                          </Typography>

                          <Dialog
                            className={styleCss.dialogPopUpUbahPassword}
                            open={openDialog}
                            maxWidth="xs"
                            fullWidth={true}
                            aria-labelledby="alert-dialog-slide-title"
                            aria-describedby="alert-dialog-slide-description"
                          >
                            <DialogTitle id="alert-dialog-slide-title">
                              <IconButton
                                aria-label="close"
                                className={styleCss.closeButtonDialog}
                                onClick={handleClose}
                              >
                                <CloseIcon />
                              </IconButton>
                            </DialogTitle>
                            <div className={styleCss.divDataUploadDataJudul}>
                              <Typography
                                className={styleCss.JudulDialogUploadData}
                              >
                                Edit Password
                              </Typography>
                              <label>Silahkan ketik email dan password</label>
                            </div>

                            <DialogContent
                              className={styleCss.dialogPopUpConntenDataUpload}
                              style={{ textAlign: 'left' }}
                            >
                              <Typography
                                className={styleCss.TypographyInformasiAkun}
                              >
                                Password Lama
                              </Typography>
                              <FormControl
                                style={{
                                  width: '100%',
                                  fontSize: '12px !important',
                                }}
                              >
                                <Input
                                  // placeholder="Type here..."
                                  style={{
                                    width: '100%',
                                    fontSize: '12px !important',
                                  }}
                                  id="standard-adornment-password"
                                  type={
                                    values.showPassword ? 'text' : 'password'
                                  }
                                  value={currentPassword}
                                  onChange={e =>
                                    setCurrentPassword(e.target.value)
                                  }
                                  endAdornment={
                                    <InputAdornment position="end">
                                      <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                      >
                                        {values.showPassword ? (
                                          <Visibility />
                                        ) : (
                                          <VisibilityOff />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  }
                                />
                              </FormControl>

                              <Typography
                                className={styleCss.TypographyInformasiAkun}
                              >
                                Password Baru
                              </Typography>
                              <FormControl style={{ width: '100%' }}>
                                <Input
                                  // placeholder="Type here..."
                                  id="standard-adornment-password"
                                  type={
                                    values.showPassword ? 'text' : 'password'
                                  }
                                  value={newPassword}
                                  onChange={e => setNewPassword(e.target.value)}
                                  endAdornment={
                                    <InputAdornment position="end">
                                      <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                      >
                                        {values.showPassword ? (
                                          <Visibility />
                                        ) : (
                                          <VisibilityOff />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  }
                                />
                              </FormControl>
                              <Typography
                                className={styleCss.TypographyInformasiAkun}
                              >
                                Ketik Ulang Password Baru
                              </Typography>
                              <FormControl style={{ width: '100%' }}>
                                <Input
                                  // placeholder="Type here..."
                                  id="standard-adornment-password"
                                  type={
                                    values.showPassword ? 'text' : 'password'
                                  }
                                  value={rePassword}
                                  onChange={e => setRePassword(e.target.value)}
                                  endAdornment={
                                    <InputAdornment position="end">
                                      <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                      >
                                        {values.showPassword ? (
                                          <Visibility />
                                        ) : (
                                          <VisibilityOff />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  }
                                />
                              </FormControl>
                              {newPassword !== rePassword && (
                                <div style={{ color: 'red', fontSize: 12 }}>
                                  ketik ulang password belum sama
                                </div>
                              )}
                              <div
                                style={{ textAlign: 'center', width: '100%' }}
                              >
                                <Button
                                  className={styleCss.submitUbahPassword}
                                  onClick={() => doChangePassword()}
                                >
                                  Submit
                                </Button>
                              </div>
                            </DialogContent>
                          </Dialog>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div style={{ marginTop: '30px', marginBottom: '30px' }}>
                    <div className={styleCss.borderLabel}>
                      <h5 className={styleCss.LabelEditProfilInformasiDinas}>
                        Informasi Dinas
                      </h5>
                      <div style={{ margin: '0px 11px' }}>
                        <Typography
                          className={styleCss.TypographyInformasiAkun}
                        >
                          Nama Pengelola
                        </Typography>
                        <Input
                          className={styleCss.InputInformasiAkun}
                          value={kepalaDinas}
                          onChange={e => setKepalaDinas(e.target.value)}
                          placeholder="Nama Pengelola"
                        />
                        <Typography
                          className={styleCss.TypographyInformasiAkun}
                        >
                          Jabatan
                        </Typography>
                        <Input
                          className={styleCss.InputInformasiAkun}
                          value={alamat}
                          onChange={e => setAlamat(e.target.value)}
                          placeholder="Jabatan"
                        />
                        <Typography
                          className={styleCss.TypographyInformasiAkun}
                        >
                          NIP
                        </Typography>
                        <Input
                          className={styleCss.InputInformasiAkun}
                          value={penJawab}
                          onChange={e => setPenJawab(e.target.value)}
                          placeholder="NIP"
                        />
                      </div>
                    </div>
                  </div>
                  <div style={{ textAlign: 'center', width: '100%' }}>
                    <Button
                      className={styleCss.submmiteditProfilAdminProgram}
                      onClick={handleClickOpenSubmit}
                    >
                      Submit
                    </Button>
                  </div>
                  <div>
                    <Dialog
                      className={styleCss.dialogPopUpUbahPassword}
                      open={openDialogSubmit}
                      // TransitionComponent={Transition}
                      // keepMounted
                      aria-labelledby="alert-dialog-slide-title"
                      aria-describedby="alert-dialog-slide-description"
                    >
                      <DialogTitle id="alert-dialog-slide-title"></DialogTitle>
                      <div className={styleCss.divKonfirmasiTindakan}>
                        <Typography className={styleCss.JudulDialogUploadData}>
                          Konfirmasi Tindakan
                        </Typography>
                        <label>Apakah anda yakin melanjutkan tindakan ?</label>
                      </div>

                      <DialogContent
                        className={styleCss.dialogPopUpConntenDataUpload}
                      >
                        <div className={styleCss.divButtonKonfirmasi}>
                          <Button
                            onClick={handleCloseSubmit}
                            className={styleCss.buttonKonfirmasiTidak}
                          >
                            Tidak
                          </Button>
                          <Button
                            onClick={handleClickOpenYA}
                            onClick={() => doUpdate()}
                            className={styleCss.buttonKonfirmasiYa}
                          >
                            Ya
                          </Button>
                        </div>
                      </DialogContent>
                    </Dialog>
                    <div>
                      <Dialog
                        className={styleCss.dialogPopUpUbahPassword}
                        open={openDialogYa}
                        // TransitionComponent={Transition}
                        // keepMounted
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                      >
                        <DialogTitle id="alert-dialog-slide-title">
                          <IconButton
                            aria-label="close"
                            className={styleCss.closeButtonDialog}
                            onClick={handleClose}
                          >
                            <CloseIcon />
                          </IconButton>
                        </DialogTitle>
                        <div className={styleCss.divKonfirmasiTindakan}>
                          <Typography
                            className={styleCss.JudulDialogUploadData}
                          >
                            Pembaharuan Profil Berhasil
                          </Typography>
                          <label>
                            Terima Kasih telah memperbaharui profil daerah anda.
                          </label>
                        </div>

                        <DialogContent
                          className={styleCss.dialogPopUpConntenDataUpload}
                        />
                      </Dialog>
                    </div>
                  </div>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </Card>
      </CardBody>

      <Snackbar
        place="tc"
        color={alert.alertState}
        icon={alert.alertState === 'success' ? CheckIcon : ErrorIcon}
        message={alert.AlertMessage}
        open={alert.isAlertOpen}
        closeNotification={() => closeNotification()}
        close
      />
      <LoadingOverlay
        active={isLoading}
        spinner
        text="Sedang melakukan pengecekan..."
        style={{ color: 'white' }}
      ></LoadingOverlay>
    </div>
  );
}

import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';
import LoadingOverlay from 'react-loading-overlay';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// icons
import CheckIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/Error';
import Snackbar from '@material-ui/core/Snackbar';

// core components
import Navbar from 'components/Navbars/Navbar.js';

// routes
import routes from 'routes';

// styles
import styles from 'assets/jss/material-dashboard-react/layouts/adminStyle';

const useStyles = makeStyles(styles);

export const AdminLayoutContext = createContext(null);

function Admin({ children, drawerToggle, ...rest }) {
  // styles
  const classes = useStyles();
  // Notification
  const [notification, setAlert] = useState({});
  const [isLayoutLoading, setLayoutLoading] = useState(false);

  // - Notifications
  // -- close
  const closeNotification = () => {
    setAlert({
      isAlertOpen: false,
      alertState: 'danger',
    });
  };

  // -- open
  const openNotification = (condition, Message) => {
    if (condition === 'success') {
      setAlert({
        isAlertOpen: true,
        alertState: 'success',
        AlertMessage: Message,
      });
    } else {
      setAlert({
        isAlertOpen: true,
        alertState: 'danger',
        AlertMessage: Message,
      });
    }
    setTimeout(() => {
      closeNotification();
    }, 5000);
  };

  const snackbarIcon =
    notification.alertState === 'success' ? CheckIcon : ErrorIcon;

  const layoutProviderValues = {
    notification,
    openNotification,
    closeNotification,
    isLayoutLoading,
    setLayoutLoading,
  };

  return (
    <AdminLayoutContext.Provider value={layoutProviderValues}>
      <LoadingOverlay
        active={isLayoutLoading}
        spinner
        text="Mohon tunggu..."
        style={{ color: 'white' }}
      />
      <Snackbar
        place="tc"
        color={notification.alertState}
        icon={snackbarIcon}
        message={notification.AlertMessage || ''}
        open={notification.isAlertOpen}
        closeNotification={closeNotification}
        close
      />
      <Navbar routes={routes} handleDrawerToggle={drawerToggle} {...rest} />
      <div className={classes.content}>
        <div className={classes.container}>{children}</div>
      </div>
    </AdminLayoutContext.Provider>
  );
}

Admin.propTypes = {
  children: PropTypes.node,
  drawerToggle: PropTypes.func,
};

export default Admin;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// @material-ui/core
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import InputBase from '@material-ui/core/InputBase';

// Css & js Style
import styleCss from 'assets/css/views/global.module.css';

// Icons
import SearchIcon from '@material-ui/icons/Search';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLayerGroup } from '@fortawesome/free-solid-svg-icons';

// configs
import api from 'config/api';

// Home Component Definition
function LayerSidebar({
  listLayer,
  openNotification,
  onUpdateMarkers,
  onUpdateGeoJSON,
  onUpdateLayerList,
  setOpenLeftLayer,
}) {
  // states definitions
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [baseLayer, setBaseLayer] = useState(false);

  const toggleSidebar = () => {
    !isSidebarOpen ? setOpenLeftLayer('layer') : setOpenLeftLayer('');
    setSidebarOpen(!isSidebarOpen);
  };

  const onLayerSelected = selectedLayer => {
    const { api_target, checked, id, jenis } = selectedLayer;

    if (checked) {
      const updatedListLayer = listLayer.map(layer => ({
        ...layer,
        layers: {
          ...layer.layers,
          checked: false,
        },
      }));

      onUpdateLayerList(updatedListLayer);
    } else {
      api
        .fetchMarkerByAPI(api_target, jenis, id)
        .then(({ data: responseBody }) => {
          if (jenis === 'api') {
            onUpdateGeoJSON(responseBody.features);
          }

          if (jenis === 'xlsx') {
            // marker locations
            const locations = responseBody.data.map(body => ({
              id: body.id,
              form: {
                value: body.data_value,
                name: body.form_layer__layer_name,
                id: body.form_layer_id,
              },
              coordinate: {
                lat: body.lat,
                long: body.long,
              },
            }));
            onUpdateMarkers(locations);
          }

          // update list layers
          const updatedListLayer = listLayer.map(layer => ({
            ...layer,
            layers: layer.layers.map(item => {
              if (selectedLayer.id === item.id) {
                return { ...item, checked: true };
              }
              return item;
            }),
          }));
          onUpdateLayerList(updatedListLayer);
        })
        .catch(error => {
          console.log('check this error', error.message);
          openNotification('danger', 'Gagal mengambil data, URL bermasalah');
        });
    }
  };

  const getMapLayer = () => {
    api
      .getLayers()
      .then(res => {
        if (res.data.error_code === 0) {
          onUpdateLayerList(res.data.data);
        } else {
          throw new Error(res.data.message);
        }
      })
      .catch(error => {
        openNotification('danger', error.message);
      });
  };

  useEffect(() => {
    getMapLayer();
  }, []);

  // conditional class names
  const containerCN = isSidebarOpen
    ? styleCss.sidebarLeft
    : styleCss.sidebarLeftNone;

  const leftIconCN = isSidebarOpen
    ? styleCss.sidebarLeftIconActive
    : styleCss.sidebarLeftIcon;

  const contentCN = isSidebarOpen
    ? styleCss.sidebarLeftContent
    : styleCss.leftSidebarDataNone;

  return (
    <div className={containerCN}>
      <div className={leftIconCN} onClick={toggleSidebar}>
        <span className={styleCss.layerspan}>
          <FontAwesomeIcon icon={faLayerGroup} /> Layer
        </span>
      </div>
      <div className={contentCN}>
        <div className={styleCss.sidebarLeftContentTitle}>Layer Properties</div>
        <div>
          <Paper className={styleCss.paperSearch}>
            <InputBase
              className={styleCss.inputSearch}
              placeholder="Search Layer..."
            />
            <IconButton
              type="button"
              className={styleCss.iconButton}
              aria-label="search"
            >
              <SearchIcon />
            </IconButton>
          </Paper>
        </div>
        <div className={styleCss.sidebarLeftLayerList}>
          <FormControlLabel
            style={{ width: '100%', padding: 5, color: 'white !important' }}
            control={
              <Checkbox
                name="BaseLayer"
                checked={baseLayer}
                onClick={() => setBaseLayer(!baseLayer)}
                className={styleCss.checkboxTextFormat}
              />
            }
            label="Base Layer"
          />
          {listLayer.map(layerGroup => (
            <div
              style={{ marginTop: '16px' }}
              key={`layer-by-${layerGroup.created_by}`}
            >
              <FormControl
                component="fieldset"
                className={styleCss.checkboxTextFormat}
              >
                <FormLabel
                  component="legend"
                  className={styleCss.checkboxTextFormatTitle}
                  style={{
                    fontWeight: '600 !important',
                    width: '100%',
                    marginBottom: '8px',
                  }}
                >
                  {layerGroup.created_detail__name}
                </FormLabel>
                <FormGroup>
                  {layerGroup.layers.map(layer => (
                    <FormControlLabel
                      key={'layer-checkbox-' + layer.id}
                      control={
                        <Checkbox
                          name="gilad"
                          checked={!!layer.checked}
                          onClick={() => onLayerSelected(layer)}
                          className={styleCss.checkboxTextFormat}
                        />
                      }
                      label={`${layer.program_name} - ${layer.layer_name}`}
                    />
                  ))}
                </FormGroup>
              </FormControl>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

LayerSidebar.propTypes = {
  listLayer: PropTypes.object,
  openNotification: PropTypes.func,
  onUpdateMarkers: PropTypes.func,
  onUpdateGeoJSON: PropTypes.func,
  onUpdateLayerList: PropTypes.func,
};

export default LayerSidebar;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Radar } from 'react-chartjs-2';

// @material-ui/core
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Slider from '@material-ui/core/Slider';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

// Css & js Style
import styleCss from 'assets/css/views/global.module.css';

// Icons
import PrintIcon from '@material-ui/icons/Print';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  faCity,
  faLandmark,
  faArchway,
  faBuilding,
  faExclamationCircle,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';

// constants
import { labelSH, labelSU } from './constants';

function InformationBar({
  infobarTitle,
  valueSlider,
  isCanEdit,
  isInformationBarVisible,
  infobarData,
  dataBahayaParams,
  dataKeterpaparanParams,
  dataSensitivitasParams,
  dataKapasitasAdaptasiParams,
  rolesid,
  doSubmit,
  onChangeSlider,
  toggleInformationBar,
}) {
  // conditional classes
  const containerClassName = isInformationBarVisible
    ? styleCss.sidebarRight
    : styleCss.sidebarRightNone;

  const contentClassName = isInformationBarVisible
    ? styleCss.sidebarRightContent
    : styleCss.sidebarRightContentNone;

  // permissions
  const isAllowedToEdit =
    infobarTitle.tingkat.name === 'Bahaya' && rolesid === 2 && isCanEdit;

  const isKeterpaparan =
    infobarTitle.tingkat.name === 'Keterpaparan' && rolesid === 2;

  const isKerentanan =
    infobarTitle.tingkat.name === 'Kerentanan' && rolesid === 2;

  const isResiko = infobarTitle.tingkat.name === 'Resiko' && rolesid === 2;

  useEffect(() => {
    console.log(
      dataBahayaParams,
      dataKapasitasAdaptasiParams,
      dataKeterpaparanParams,
      dataSensitivitasParams,
    );
  }, [
    dataBahayaParams,
    dataKapasitasAdaptasiParams,
    dataKeterpaparanParams,
    dataSensitivitasParams,
  ]);

  return (
    <div className={containerClassName}>
      <div className={styleCss.sidebarRightIcon} onClick={toggleInformationBar}>
        <FontAwesomeIcon
          icon={faInfoCircle}
          className={styleCss.sidebarRightIconPos}
        />
      </div>

      <div className={contentClassName}>
        <div className={styleCss.sidebarRightContentTitle}>
          Visualisasi Data {infobarTitle.tingkat.name}
          {' - ' + infobarTitle.jenis.name}
        </div>

        {isAllowedToEdit && (
          <div>
            <table>
              <tbody>
                <tr>
                  <td style={{ fontWeight: 600 }}>Info Wilayah</td>
                </tr>
                <Divider />
                <tr>
                  <td>
                    <FontAwesomeIcon icon={faLandmark} /> Provinsi
                  </td>
                  <td>: {localStorage.getItem('dprov')}</td>
                </tr>
                <tr>
                  <td>
                    <FontAwesomeIcon icon={faCity} /> Kab/Kota
                  </td>
                  <td>: {localStorage.getItem('dkab')}</td>
                </tr>
                <tr>
                  <td>
                    <FontAwesomeIcon icon={faBuilding} /> Kecamatan
                  </td>
                  <td>: {localStorage.getItem('dkec')}</td>
                </tr>
                <tr>
                  <td>
                    <FontAwesomeIcon icon={faArchway} /> Desa/Kel
                  </td>
                  <td>: {localStorage.getItem('ddes')}</td>
                </tr>
              </tbody>
            </table>

            <Radar
              data={dataBahayaParams.data}
              options={dataBahayaParams.options}
              height={'400px'}
              width={'400px'}
            />

            <div className={styleCss.sidebarRightContentTitle}>
              Perubahan Curah Hujan
            </div>
            <div>
              <Typography id="discrete-slider-custom" gutterBottom>
                Rata-rata Tahunan
              </Typography>
              <div style={{ width: '70%', display: 'inline-block' }}>
                <Slider
                  defaultValue={0}
                  onChange={(event, val) => {
                    onChangeSlider(val, 'CHT');
                  }}
                  aria-labelledby="discrete-slider-custom"
                  step={1}
                  valueLabelDisplay="auto"
                  marks={labelSH}
                  min={-100}
                  max={100}
                />
              </div>
              <input
                style={{ width: '15%', float: 'right', textAlign: 'center' }}
                readOnly
                value={valueSlider.CHT}
              />
            </div>
            <div>
              <Typography id="discrete-slider-custom" gutterBottom>
                Rata-rata Optimum
              </Typography>
              <div style={{ width: '70%', display: 'inline-block' }}>
                <Slider
                  defaultValue={0}
                  onChange={(event, val) => {
                    onChangeSlider(val, 'CHM');
                  }}
                  aria-labelledby="discrete-slider-custom"
                  step={1}
                  valueLabelDisplay="auto"
                  marks={labelSH}
                  min={-100}
                  max={100}
                />
              </div>
              <input
                style={{ width: '15%', float: 'right', textAlign: 'center' }}
                readOnly
                value={valueSlider.CHM}
              />
            </div>
            <div>
              <Typography id="discrete-slider-custom" gutterBottom>
                Rata-rata Musiman
              </Typography>
              <div style={{ width: '70%', display: 'inline-block' }}>
                <Slider
                  defaultValue={0}
                  onChange={(event, val) => {
                    onChangeSlider(val, 'CHO');
                  }}
                  aria-labelledby="discrete-slider-custom"
                  step={1}
                  valueLabelDisplay="auto"
                  marks={labelSH}
                  min={-100}
                  max={100}
                />
              </div>
              <input
                style={{ width: '15%', float: 'right', textAlign: 'center' }}
                readOnly
                value={valueSlider.CHO}
              />
            </div>

            <div className={styleCss.sidebarRightContentTitle}>
              Perubahan Suhu
            </div>
            <div>
              <Typography id="discrete-slider-custom" gutterBottom>
                Rata-rata Tahunan
              </Typography>
              <div style={{ width: '70%', display: 'inline-block' }}>
                <Slider
                  defaultValue={0}
                  onChange={(event, val) => {
                    onChangeSlider(val, 'SUT');
                  }}
                  aria-labelledby="discrete-slider-custom"
                  step={0.1}
                  valueLabelDisplay="auto"
                  marks={labelSU}
                  min={-2}
                  max={2}
                />
              </div>
              <input
                style={{ width: '15%', float: 'right', textAlign: 'center' }}
                readOnly
                value={valueSlider.SUT}
              />
              <Typography id="discrete-slider-custom" gutterBottom>
                Rata-rata Optimum
              </Typography>
              <div style={{ width: '70%', display: 'inline-block' }}>
                <Slider
                  defaultValue={0}
                  onChange={(_, val) => {
                    onChangeSlider(val, 'SUM');
                  }}
                  aria-labelledby="discrete-slider-custom"
                  step={0.1}
                  valueLabelDisplay="auto"
                  marks={labelSU}
                  min={-2}
                  max={2}
                />
              </div>
              <input
                style={{ width: '15%', float: 'right', textAlign: 'center' }}
                readOnly
                value={valueSlider.SUM}
              />
              <Typography id="discrete-slider-custom" gutterBottom>
                Rata-rata Musiman
              </Typography>
              <div style={{ width: '70%', display: 'inline-block' }}>
                <Slider
                  defaultValue={0}
                  onChange={(_, val) => {
                    onChangeSlider(val, 'SUO');
                  }}
                  aria-labelledby="discrete-slider-custom"
                  step={0.1}
                  valueLabelDisplay="auto"
                  marks={labelSU}
                  min={-2}
                  max={2}
                />
              </div>
              <input
                style={{ width: '15%', float: 'right', textAlign: 'center' }}
                readOnly
                value={valueSlider.SUO}
              />
            </div>

            <div style={{ textAlign: 'center' }}>
              <Button
                variant="outlined"
                onClick={() => doSubmit()}
                className={styleCss.btnSaveValidasi}
              >
                Save
              </Button>
              <IconButton className={styleCss.IconPrintBahayaTersimpans}>
                <PrintIcon />
              </IconButton>
            </div>
          </div>
        )}
        {isKeterpaparan && (
          <div>
            <table>
              <table>
                <tbody>
                  <tr>
                    <td style={{ fontWeight: 600 }}>Info Wilayah</td>
                  </tr>
                  <Divider />
                  <tr>
                    <td>
                      <FontAwesomeIcon icon={faLandmark} /> Provinsi
                    </td>
                    <td>: {localStorage.getItem('dprov')}</td>
                  </tr>
                  <tr>
                    <td>
                      <FontAwesomeIcon icon={faCity} /> Kab/Kota
                    </td>
                    <td>: {localStorage.getItem('dkab')}</td>
                  </tr>
                  <tr>
                    <td>
                      <FontAwesomeIcon icon={faBuilding} /> Kecamatan
                    </td>
                    <td>: {localStorage.getItem('dkec')}</td>
                  </tr>
                  <tr>
                    <td>
                      <FontAwesomeIcon icon={faArchway} /> Desa/Kel
                    </td>
                    <td>: {localStorage.getItem('ddes')}</td>
                  </tr>
                  <tr>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                  </tr>
                </tbody>
              </table>
              <div style={{ margin: '2em 0' }}>
                <div className={styleCss.sidebarRightContentTitle}>
                  Spider Chart Keterpaparan
                </div>
                <Radar
                  data={dataKeterpaparanParams.data}
                  options={dataKeterpaparanParams.options}
                  height={'400px'}
                  width={'400px'}
                />
              </div>

              {infobarData.length === 0 ? (
                <tr style={{ textAlign: 'center' }}>
                  <FontAwesomeIcon icon={faExclamationCircle} /> Data belum
                  tersedia
                </tr>
              ) : (
                <tr style={{ marginTop: 15 }}>
                  <td style={{ fontWeight: 600 }}>Faktor yang Berkontribusi</td>
                </tr>
              )}
              {infobarData.length !== 0 && <Divider />}
              {infobarData.map(rdata => (
                <tr key={`rdata-${rdata.name}`}>
                  <td>&#x25CF; {rdata.name}</td>
                </tr>
              ))}
            </table>
          </div>
        )}

        {isKerentanan && (
          <div>
            <table>
              <table>
                <tbody>
                  <tr>
                    <td style={{ fontWeight: 600 }}>Info Wilayah</td>
                  </tr>
                  <Divider />
                  <tr>
                    <td>
                      <FontAwesomeIcon icon={faLandmark} /> Provinsi
                    </td>
                    <td>: {localStorage.getItem('dprov')}</td>
                  </tr>
                  <tr>
                    <td>
                      <FontAwesomeIcon icon={faCity} /> Kab/Kota
                    </td>
                    <td>: {localStorage.getItem('dkab')}</td>
                  </tr>
                  <tr>
                    <td>
                      <FontAwesomeIcon icon={faBuilding} /> Kecamatan
                    </td>
                    <td>: {localStorage.getItem('dkec')}</td>
                  </tr>
                  <tr>
                    <td>
                      <FontAwesomeIcon icon={faArchway} /> Desa/Kel
                    </td>
                    <td>: {localStorage.getItem('ddes')}</td>
                  </tr>
                  <tr>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                  </tr>
                </tbody>
              </table>

              <div style={{ margin: '2em 0' }}>
                <div className={styleCss.sidebarRightContentTitle}>
                  Spider Chart Sensitivitas
                </div>
                <Radar
                  data={dataSensitivitasParams.data}
                  options={dataSensitivitasParams.options}
                  height={'400px'}
                  width={'400px'}
                />
              </div>
              <div style={{ margin: '2em 0' }}>
                <div className={styleCss.sidebarRightContentTitle}>
                  Spider Chart KapasitasAdaptasi
                </div>
                <Radar
                  data={dataKapasitasAdaptasiParams.data}
                  options={dataKapasitasAdaptasiParams.options}
                  height={'400px'}
                  width={'400px'}
                />
              </div>

              {infobarData.length === 0 ? (
                <tr style={{ textAlign: 'center' }}>
                  <FontAwesomeIcon icon={faExclamationCircle} /> Data belum
                  tersedia
                </tr>
              ) : (
                <tr style={{ marginTop: 15 }}>
                  <td style={{ fontWeight: 600 }}>
                    Faktor yang Berkontribusi{' '}
                  </td>
                </tr>
              )}
              {infobarData.length !== 0 && <Divider />}
              {infobarData.map(rdata => (
                <tr key={`rdata-${rdata.name}`}>
                  <td>&#x25CF; {rdata.name}</td>
                </tr>
              ))}
            </table>
          </div>
        )}

        {isResiko && (
          <div>
            <table>
              <table>
                <tbody>
                  <tr>
                    <td style={{ fontWeight: 600 }}>Info Wilayah</td>
                  </tr>
                  <Divider />
                  <tr>
                    <td>
                      <FontAwesomeIcon icon={faLandmark} /> Provinsi
                    </td>
                    <td>: {localStorage.getItem('dprov')}</td>
                  </tr>
                  <tr>
                    <td>
                      <FontAwesomeIcon icon={faCity} /> Kab/Kota
                    </td>
                    <td>: {localStorage.getItem('dkab')}</td>
                  </tr>
                  <tr>
                    <td>
                      <FontAwesomeIcon icon={faBuilding} /> Kecamatan
                    </td>
                    <td>: {localStorage.getItem('dkec')}</td>
                  </tr>
                  <tr>
                    <td>
                      <FontAwesomeIcon icon={faArchway} /> Desa/Kel
                    </td>
                    <td>: {localStorage.getItem('ddes')}</td>
                  </tr>
                  <tr>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                  </tr>
                </tbody>
              </table>

              <div style={{ margin: '2em 0' }}>
                <div className={styleCss.sidebarRightContentTitle}>
                  Spider Chart Bahaya {' - ' + infobarTitle.jenis.name}
                </div>
                <Radar
                  data={dataBahayaParams.data}
                  options={dataBahayaParams.options}
                  height={'400px'}
                  width={'400px'}
                />
              </div>

              <div style={{ margin: '2em 0' }}>
                <div className={styleCss.sidebarRightContentTitle}>
                  Spider Chart Sensitivitas
                </div>
                <Radar
                  data={dataSensitivitasParams.data}
                  options={dataSensitivitasParams.options}
                  height={'400px'}
                  width={'400px'}
                />
              </div>

              <div style={{ margin: '2em 0' }}>
                <div className={styleCss.sidebarRightContentTitle}>
                  Spider Chart KapasitasAdaptasi
                </div>
                <Radar
                  data={dataKapasitasAdaptasiParams.data}
                  options={dataKapasitasAdaptasiParams.options}
                  height={'400px'}
                  width={'400px'}
                />
              </div>

              <div style={{ margin: '2em 0' }}>
                <div className={styleCss.sidebarRightContentTitle}>
                  Spider Chart Keterpaparan
                </div>
                <Radar
                  data={dataKeterpaparanParams.data}
                  options={dataKeterpaparanParams.options}
                  height={'400px'}
                  width={'400px'}
                />
              </div>
            </table>
          </div>
        )}
      </div>
    </div>
  );
}

InformationBar.propTypes = {
  infobarTitle: PropTypes.object,
  valueSlider: PropTypes.object,
  isCanEdit: PropTypes.bool,
  isInformationBarVisible: PropTypes.bool,
  infobarData: PropTypes.object,
  dataBahayaParams: PropTypes.object,
  rolesid: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  doSubmit: PropTypes.func,
  onChangeSlider: PropTypes.func,
  toggleInformationBar: PropTypes.func,
};

export default InformationBar;

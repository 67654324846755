import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import api from 'config/api';

// @material-ui/core
import Typography from '@material-ui/core/Typography';

// @material-ui/icons
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

// @material-ui/lab
import Skeleton from '@material-ui/lab/Skeleton';

// assets
import styleCss from 'assets/css/views/global.module.css';

function ValidasiDataBahaya() {
  const [loadValidasi, setLoadValidasi] = useState(true);
  const [dataValidasi, setDataValidasi] = useState([]);

  const getValidasi = id => {
    setLoadValidasi(true);
    api
      .getValidasiProfile(id)
      .then(res => {
        if (res.data.error_code === 0) {
          const { data } = res.data;
          setDataValidasi(data);
        }
        setLoadValidasi(false);
      })
      .catch(err => {
        setLoadValidasi(false);
        console.log('An error when getting data validasi bahaya:', err);
      });
  };

  useEffect(() => {
    getValidasi(localStorage.getItem('userId'));
  }, []);

  return (
    <div>
      <Typography className={styleCss.JudulIsiRingkasanValidasiData}>
        <ArrowDropDownIcon style={{ color: '#00A6A6' }} />
        Validasi Data Bahaya
      </Typography>
      <div style={{ overflowX: 'auto', marginLeft: '50px' }}>
        {loadValidasi ? (
          <Skeleton variant="rect" width="100%">
            <div style={{ height: 200 }} />
          </Skeleton>
        ) : (
          <table className={styleCss.TableValidasiBahaya}>
            <thead>
              <tr>
                <th>No</th>
                <th>Nama Wilayah</th>
                <th>Nama Penyakit</th>
                <th>Jumlah Kejadian</th>
              </tr>
            </thead>
            <tbody>
              {dataValidasi.map((dv, index) => (
                <tr key={`data-validasi-${index + 1}`}>
                  <td>{index + 1}</td>
                  <td>{dv.wilayah__name}</td>
                  <td>{dv.penyakit__name}</td>
                  <td>{dv.kejadian}</td>
                </tr>
              ))}
              {dataValidasi.length === 0 && (
                <tr>
                  <td colSpan="4" align="center">
                    <FontAwesomeIcon icon={faExclamationCircle} />
                    Data Validasi Belum Ada
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
}

ValidasiDataBahaya.propTypes = {
  userId: PropTypes.number,
};

export default ValidasiDataBahaya;

import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core
import Skeleton from '@material-ui/lab/Skeleton';

// @material-ui/lab
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';

// @material-ui/icons
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import styleCss from 'assets/css/views/global.module.css';

function AdminDaerah({ profile, isProfileLoading }) {
  // render
  if (!isProfileLoading && profile && profile.data) {
    const {
      info_geografi: { bu, bs, bb, bt },
      administrasi_wilayah: { total_kec, total_kel, total_desa },
      penyakit,
    } = profile.data;

    return (
      <>
        <TreeView
          className={styleCss.TreeView}
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          defaultExpanded={['1']}
          multiSelect
        >
          <TreeItem
            className={styleCss.TreeItemColor}
            nodeId="1"
            label="Informasi Grafis"
          >
            <TreeItem
              className={styleCss.TreeItem}
              nodeId="2"
              label={'Batas Utara : ' + bu}
            />
            <TreeItem
              className={styleCss.TreeItem}
              nodeId="3"
              label={'Batas Selatan : ' + bs}
            />
            <TreeItem
              className={styleCss.TreeItem}
              nodeId="4"
              label={'Batas Barat : ' + bb}
            />
            <TreeItem
              className={styleCss.TreeItem}
              nodeId="5"
              label={'Batas Timur : ' + bt}
            />
          </TreeItem>
        </TreeView>
        <TreeView
          className={styleCss.TreeView}
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          defaultExpanded={['1']}
          multiSelect
        >
          <TreeItem
            className={styleCss.TreeItemColor}
            nodeId="1"
            label="Adminitrasi Wilayah"
          >
            <TreeItem
              className={styleCss.TreeItem}
              nodeId="2"
              label={'Jumlah Kecamatan : ' + total_kec}
            />
            <TreeItem
              className={styleCss.TreeItem}
              nodeId="3"
              label={'Jumlah Kelurahan : ' + total_kel}
            />
            <TreeItem
              className={styleCss.TreeItem}
              nodeId="4"
              label={'Jumlah Desa : ' + total_desa}
            />
          </TreeItem>
        </TreeView>
        <TreeView
          className={styleCss.TreeView}
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          defaultExpanded={['1']}
          multiSelect
        >
          <TreeItem
            className={styleCss.TreeItemColor}
            nodeId="1"
            label="Penyakit Prioritas"
          >
            {penyakit.map((dp, index) => (
              <TreeItem
                key={`data-penyakit-${index + 1}`}
                nodeId={index + 2}
                className={styleCss.TreeItem}
                label={dp}
              />
            ))}
          </TreeItem>
        </TreeView>
      </>
    );
  }

  return (
    <div style={{ paddingLeft: 20, paddingRight: 20 }}>
      <div style={{ marginTop: 50 }}>
        <Skeleton variant="text" />
        <Skeleton variant="text" />
        <Skeleton variant="text" />
      </div>

      <div style={{ marginTop: 50 }}>
        <Skeleton variant="text" />
        <Skeleton variant="text" />
        <Skeleton variant="text" />
      </div>
    </div>
  );
}

AdminDaerah.propTypes = {
  profile: PropTypes.object,
  isProfileLoading: PropTypes.string,
};

export default AdminDaerah;

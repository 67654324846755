import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';

// @material-ui/core
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

// @material-ui/lab
import Skeleton from '@material-ui/lab/Skeleton';

// @material-ui/icons
import CreateIcon from '@material-ui/icons/Create';

import styleCss from 'assets/css/views/global.module.css';

// hooks
import useUserProfile from 'hooks/useUserProfile';

// components
import AdminProgram from './AdminProgram';
import AdminDaerah from './AdminDaerah';

function ProfileSection({ userId, roles }) {
  const history = useHistory();
  const { profile, isProfileLoading, getUserById } = useUserProfile(userId);
  const [role] = roles;
  // did mounted
  useEffect(() => {
    getUserById(userId);
  }, []);

  // render
  if (isProfileLoading || !profile) {
    return (
      <div style={{ paddingLeft: 20, paddingRight: 20 }}>
        <div className={styleCss.adminProfileContainer}>
          <Skeleton width={120} height={120} variant="circle" />
        </div>
        <div style={{ marginTop: 50 }}>
          <Skeleton variant="text" />
          <Skeleton variant="text" />
        </div>
        <div style={{ marginTop: 50 }}>
          <Skeleton variant="text" />
          <Skeleton variant="text" />
          <Skeleton variant="text" />
        </div>

        <div style={{ marginTop: 50 }}>
          <Skeleton variant="text" />
          <Skeleton variant="text" />
          <Skeleton variant="text" />
        </div>
      </div>
    );
  }

  const { name = '', email, image } = profile;
  const avatar = image || name[0];

  const onEditClick = () => history.push('/edit-program-profile');

  return (
    <div>
      <div className={styleCss.adminProfileContainer}>
        <IconButton className={styleCss.ProfilePic}>
          <Typography className={styleCss.nameProfile}>{name[0]}</Typography>

          <IconButton className={styleCss.editProfile} onClick={onEditClick}>
            <CreateIcon />
          </IconButton>
        </IconButton>
        <Typography className={styleCss.namaAdminDaerah}> {name} </Typography>
        <Typography className={styleCss.emailAdminDaerah}> {email}</Typography>
      </div>
      {role.id === 2 && <AdminDaerah profile={profile} />}
      {role.id === 5 && <AdminProgram profile={profile} />}
    </div>
  );
}

ProfileSection.propTypes = {
  userId: PropTypes.number,
  roles: PropTypes.object,
};

export default ProfileSection;

import React, { useEffect, useState } from 'react';
import api from 'config/api';
import { Chart } from 'react-chartjs-2';
import { useHistory } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay';
import { Map, TileLayer, ZoomControl } from 'react-leaflet';
import L from 'leaflet';
// @material-ui/core
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Snackbar from 'components/Snackbar/Snackbar.js';

// @material-ui/icons
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import CheckIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/Error';
import CircularProgress from '@material-ui/core/CircularProgress';

// core components
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import InputBase from '@material-ui/core/InputBase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

import styles from 'assets/jss/material-dashboard-react/views/registerStyle.js';
import styleCss from '../../../src/assets/css/views/global.module.css';

import { Typography } from '@material-ui/core';

const useStyles = makeStyles(styles);
Chart.defaults.global.legend.display = false;
const mapRef = React.createRef(null);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const downloadFormat = () => {
  let urls = api.getKerentananTemplate();
  window.open(urls);
};

export default function DaftarDataKerentanan() {
  const history = useHistory();
  const [open, setOpen] = React.useState(true);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const [loadDataCollection, setLoadDataCollection] = React.useState(false);
  const [dataCollection, setDataCollection] = React.useState([]);
  const [loadKerentanan, setLoadKerentanan] = React.useState(false);
  const [dataKerentanan, setDataKerentanan] = React.useState([]);
  const [filename, setFilename] = React.useState('');
  const [filedata, setFiledata] = React.useState('');
  const [isDeleteDiaolog, setIsDeleteDialog] = React.useState(false);
  const [selectedIdDC, setSelectedIdDC] = React.useState('');
  const [selectedDC, setSelectedDC] = React.useState();
  const [dataGeojson, setDataGeojson] = React.useState([]);
  const [loadGeoJson, setLoadGeoJson] = React.useState(false);

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };
  const [alert, setAlert] = useState({});
  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const showDetail = selected => {
    setSelectedDC(selected);

    getKerentananParamUser(selected.id);
  };
  const deleteDataConfirm = id => {
    setSelectedIdDC(id);
    setIsDeleteDialog(true);
  };

  const deleteDataKerentanan = () => {
    setIsLoading(true);
    api
      .deleteDataKerentanan(selectedIdDC)
      .then(res => {
        setIsLoading(false);
        if (res.data.error_code === 0) {
          openNotification('success', 'Berhasil Hapus Data kerentanan.');
          setIsDeleteDialog(false);
          getDataCollection(localStorage.getItem('userId'));
        } else {
          openNotification('danger', res.data.message);
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  };
  function closeNotification() {
    setAlert({
      isAlertOpen: false,
      alertState: 'danger',
    });
  }
  function openNotification(condition, Message) {
    if (condition === 'success') {
      setAlert({
        isAlertOpen: true,
        alertState: 'success',
        AlertMessage: Message,
      });
    } else {
      setAlert({
        isAlertOpen: true,
        alertState: 'danger',
        AlertMessage: Message,
      });
    }
    setTimeout(
      function() {
        closeNotification();
      }.bind(this),
      5000,
    );
  }

  const getKerentananParamUser = id => {
    setLoadKerentanan(true);
    setIsLoading(true);
    api
      .getKerentananParamUser(id)
      .then(res => {
        setLoadKerentanan(false);
        setIsLoading(false);
        if (res.data.error_code === 0) {
          let dk = res.data.data;

          setDataKerentanan(dk);
          getGeojson(dk);
        } else {
          openNotification('danger', res.data.message);
        }
      })
      .catch(err => {
        setLoadKerentanan(false);
        console.log('Errorr:', err);
      });
  };

  const getDataCollection = id => {
    setLoadDataCollection(true);
    api
      .getDataCollection(id)
      .then(res => {
        setLoadDataCollection(false);
        if (res.data.error_code === 0) {
          let dk = res.data.data;
          setDataCollection(dk);
        } else {
          openNotification('danger', res.data.message);
        }
      })
      .catch(err => {
        setLoadDataCollection(false);
        console.log('Errorr:', err);
      });
  };
  const getGeojson = dk => {
    setLoadGeoJson(true);
    setIsLoading(true);

    api
      .getGeojsonDesa('desa', dk[0].wilayah_id.slice(0, 4))
      .then(res => {
        setIsLoading(false);
        if (res.data.error_code === 0) {
          let datae = res.data.data;
          for (let i = 0; i < datae.features.length; i++) {
            for (let j = 0; j < dk.length; j++) {
              if (datae.features[i].properties.IDDESA === dk[j].wilayah_id) {
                datae.features[i].properties.isSelected = true;
                datae.features[i].properties.kerentanan = dk[j].kerentanan;
              }
            }
          }
          setDataGeojson(datae);

          const map = mapRef.current.leafletElement;
          let gjson = L.geoJson(res.data.data, {
            style: {
              color: '#00A6A6',
              weight: 1,
              opacity: 1,
              fillOpacity: 0,
            },
          }).addTo(map);
          let bounds = gjson.getBounds();
          map.fitBounds(bounds);

          gjson.eachLayer(function(layer) {
            if (layer.feature.properties.isSelected === true) {
              let kerentananData = layer.feature.properties.kerentanan;
              let kerentananColor = '';
              if (kerentananData >= 0 && kerentananData <= 0.2) {
                kerentananColor = '#1a9641';
              } else if (kerentananData >= 0.21 && kerentananData <= 0.4) {
                kerentananColor = '#ffffbf';
              } else if (kerentananData >= 0.41 && kerentananData <= 0.6) {
                kerentananColor = '#ffffbf';
              } else if (kerentananData >= 0.61 && kerentananData <= 0.8) {
                kerentananColor = '#fdae61';
              } else if (kerentananData >= 0.81 && kerentananData <= 1.0) {
                kerentananColor = '#d7191c';
              } else {
                kerentananColor = '#ffff';
              }

              layer.setStyle({
                fillOpacity: 1,
                color: kerentananColor,
              });
            }

            layer.addEventListener('mouseover', () => {
              let lay = layer.feature.properties;
              let dataSend = lay.DESA;
              if (lay.isSelected) {
                layer
                  .bindTooltip(
                    '<div>' +
                      dataSend +
                      '</div><div> kerentanan : ' +
                      lay.kerentanan +
                      '</div>',
                  )
                  .openTooltip();
              } else {
                layer.bindTooltip('<div>' + dataSend + '</div>').openTooltip();
              }
            });
          });
        }
        setLoadGeoJson(false);
      })
      .catch(err => {
        setIsLoading(false);
        console.log('Errorr:', err);
      });
  };

  const importData = () => {
    if (filename.length <= 0) {
      openNotification('danger', 'Nama file harus diisi!');
      return;
    }
    if (filedata.length <= 0) {
      openNotification('danger', 'File harus diisi dan dengan format excel!');
      return;
    }
    setIsLoading(true);

    // console.log(filedata,'isi file')
    let formData = new FormData();
    formData.append('name', filename);
    formData.append('file', filedata[0]);
    let year = new Date();
    formData.append('year', year.getFullYear());
    api
      .importDataKerentanan(formData)
      .then(res => {
        setIsLoading(false);
        if (res.data.error_code === 0) {
          openNotification('success', 'Berhasil menyimpan data');
          getDataCollection(localStorage.getItem('userId'));
          setOpenDialog(false);
        } else {
          openNotification('danger', res.data.message);
        }
      })
      .catch(err => {
        setIsLoading(false);
        openNotification('danger', 'koneksi bermasalah');
        console.log('Errorr:', err);
      });
  };

  useEffect(() => {
    if (!localStorage.getItem('token')) history.push('home');
    // getKerentananParamUser(localStorage.getItem('userId'))
    getDataCollection(localStorage.getItem('userId'));
  }, []);

  return (
    <div className={styleCss.Container}>
      <Snackbar
        place="tc"
        color={alert.alertState}
        icon={alert.alertState === 'success' ? CheckIcon : ErrorIcon}
        message={alert.AlertMessage}
        open={alert.isAlertOpen}
        closeNotification={() => closeNotification()}
        close
      />
      <LoadingOverlay
        active={isLoading}
        spinner
        text="Sedang melakukan pengecekan..."
        style={{ color: 'white' }}
      ></LoadingOverlay>
      <div
        style={{
          marginTop: '90px',
          marginLeft: '-10px',
          marginBottom: '-10px',
        }}
      >
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Link
            color="inherit"
            onClick={() => history.goBack()}
            className={styleCss.Link}
          >
            <ArrowBackIosIcon className={styleCss.IconBreadcrumbs} />
            Kembali ke Profil
          </Link>
        </Breadcrumbs>
      </div>

      <CardBody>
        <Card className={styleCss.CardDataKerentanan}>
          <GridContainer>
            <GridItem md={7}>
              <div className={styleCss.containerDataKerentanan}>
                <div className={styleCss.DivJudulDataKerentanan}>
                  <GridContainer>
                    <ArrowDropDownIcon
                      style={{ color: '#00A6A6', marginLeft: '20px' }}
                    />
                    <Typography className={styleCss.JudulDataKerentanan}>
                      Daftar Data Kerentanan
                    </Typography>
                  </GridContainer>
                </div>

                <div>
                  <GridContainer>
                    <GridItem md={9}>
                      <GridContainer>
                        <div>
                          <Button
                            onClick={handleClickOpen}
                            className={styleCss.buttonUploadData}
                          >
                            {' '}
                            <AddIcon
                              style={{ marginRight: '8px', marginLeft: '-7px' }}
                            />
                            Upload Data
                          </Button>

                          <Dialog
                            className={styleCss.dialogPopUpDataUpload}
                            open={openDialog}
                            // TransitionComponent={Transition}
                            keepMounted
                            aria-labelledby="alert-dialog-slide-title"
                            aria-describedby="alert-dialog-slide-description"
                          >
                            <DialogTitle id="alert-dialog-slide-title">
                              <IconButton
                                aria-label="close"
                                className={styleCss.closeButtonDialog}
                                onClick={handleClose}
                              >
                                <CloseIcon />
                              </IconButton>
                            </DialogTitle>
                            <div className={styleCss.divDataUploadDataJudul}>
                              <Typography
                                className={styleCss.JudulDialogUploadData}
                              >
                                Upload Data Kerentanan
                              </Typography>
                              <label>
                                Silahkan unggah data dan isi detil data anda
                              </label>
                            </div>

                            <DialogContent>
                              <div className={styleCss.registerInputContainer}>
                                <label className={styleCss.registerInputLabel}>
                                  Filename
                                </label>
                                <Input
                                  placeholder="Ketik disini..."
                                  value={filename}
                                  onChange={e => setFilename(e.target.value)}
                                  type="text"
                                  inputProps={{ 'aria-label': 'description' }}
                                  style={{ width: '100%', fontSize: 12 }}
                                />
                              </div>
                              <div className={styleCss.registerInputContainer}>
                                <input
                                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                  style={{ width: '100%', fontSize: 12 }}
                                  id="contained-button-file"
                                  type="file"
                                  onChange={e => setFiledata(e.target.files)}
                                />
                              </div>

                              <div>
                                <Typography
                                  onClick={() => downloadFormat()}
                                  className={styleCss.downloadformat}
                                >
                                  {' '}
                                  Unduh format data excel disini
                                </Typography>
                              </div>

                              <div
                                className={styleCss.divButtonKonfirmasi}
                                style={{ textAlign: 'center' }}
                              >
                                <Button
                                  className={styleCss.buttonLogin}
                                  onClick={() => importData()}
                                >
                                  Submit
                                </Button>
                              </div>
                            </DialogContent>
                          </Dialog>
                        </div>
                        <div>
                          <Paper
                            component="form"
                            className={styleCss.paperSearch}
                          >
                            <InputBase
                              className={styleCss.inputSearch}
                              placeholder="Search Data..."
                              // onChange={(e) => filterSearch(e.target.value)}
                            />
                            <IconButton
                              type="submit"
                              className={styleCss.iconButton}
                              aria-label="search"
                            >
                              <SearchIcon />
                            </IconButton>
                          </Paper>
                        </div>
                      </GridContainer>
                    </GridItem>
                    <GridItem md={3}>
                      <div className={styleCss.divUnduhFormatData}>
                        {/* <GridContainer>
                                                    <InsertDriveFileIcon className={styleCss.IconUnduhFormatData} />
                                                    <Typography className={styleCss.TextUnduhFormatData}> Unduh format data</Typography>
                                                </GridContainer> */}
                      </div>
                    </GridItem>
                  </GridContainer>
                </div>
                <div
                  style={{
                    overflowY: 'scroll',
                    marginLeft: '50px',
                    marginBottom: '20px',
                    maxHeight: '500px',
                  }}
                >
                  <table className={styleCss.TablePilihanDataKerentanan}>
                    <tr>
                      <th style={{ borderTopLeftRadius: '20px' }}>No</th>
                      <th>Nama File </th>
                      <th>Tahun </th>
                      <th style={{ borderTopRightRadius: '20px' }}>
                        Tindakan{' '}
                      </th>
                    </tr>
                    {loadDataCollection ? (
                      <tr>
                        <td colSpan="4" align="center">
                          {' '}
                          <CircularProgress />
                        </td>
                      </tr>
                    ) : (
                      dataCollection.map((dc, index) => {
                        return (
                          <tr>
                            <td>{index + 1}</td>
                            <td className={styleCss.TdNamaFile}>
                              <InsertDriveFileIcon
                                className={styleCss.IconDocTable}
                              />
                              {dc.name}
                            </td>
                            <td>{dc.year}</td>
                            <td>
                              <div className={styleCss.IconTableTindakanDiv}>
                                <Visibility
                                  className={styleCss.IconTableTindakan}
                                  onClick={() => showDetail(dc)}
                                />
                                <DeleteIcon
                                  className={styleCss.IconTableTindakan}
                                  onClick={() => deleteDataConfirm(dc.id)}
                                />
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    )}
                    {!loadDataCollection && dataCollection.length === 0 ? (
                      <tr>
                        <td colSpan={4} align={'center'}>
                          Data belum ada
                        </td>
                      </tr>
                    ) : (
                      ''
                    )}
                  </table>
                </div>
              </div>
            </GridItem>
            <GridItem md={5}>
              <div className={styleCss.DivJudulDataKerentanan}>
                <GridContainer>
                  <ArrowDropDownIcon
                    style={{ color: '#00A6A6', marginLeft: '20px' }}
                  />
                  <Typography className={styleCss.JudulDataKerentanan}>
                    Tampilkan Data Kerentanan
                  </Typography>
                </GridContainer>
              </div>
              {selectedDC ? (
                <label className={styleCss.openingData}>
                  Opening Data: {selectedDC.name}
                </label>
              ) : (
                ''
              )}

              <div style={{ marginRight: '30px', marginTop: '70px' }}>
                <AppBar
                  position="static"
                  className={styleCss.AppBarDataKerentanan}
                >
                  <Tabs
                    value={value}
                    onChange={handleChangeTab}
                    aria-label="simple tabs example"
                    indicatorColor="primary"
                    textColor="primary"
                    className={styleCss.tabDataKerentanan}
                    centered
                  >
                    <Tab label="Tabel" {...a11yProps(0)} />
                    <Tab label="Peta" {...a11yProps(1)} />
                    {/* <Tab label="Grafik" {...a11yProps(2)} /> */}
                  </Tabs>
                </AppBar>
                <div
                  style={{
                    border: '1px solid #979797',
                    height: '100%',
                    overflow: 'scroll',
                  }}
                >
                  <TabPanel
                    style={{ padding: '0px 0px' }}
                    value={value}
                    index={0}
                  >
                    {selectedDC ? (
                      <table className={styleCss.TableValidasiBahaya}>
                        <tr>
                          <th>No</th>
                          <th>Nama Wilayah</th>
                          <th>Fasilitas Kesehatan</th>
                          <th>Gerakan Pengelolaan Lingkungan</th>
                          <th>Historis Kejadian Penyakit</th>

                          <th>Kesejahteraan Masyarakat</th>
                          <th>Kualitas Lingkungan</th>
                          <th>Pendukung Fasilitas Kesehatan</th>
                          <th>Jumlah Penduduk Berobat</th>
                          <th>Perilaku Gaya Hidup</th>
                          <th>Promosi Kesehatan Masyarakat</th>
                          <th>Tenaga Kesehatan</th>
                          <th>Kerentanan</th>
                        </tr>

                        {dataKerentanan.map((dv, index) => {
                          return (
                            <tr>
                              <td>{index + 1}</td>
                              <td>{dv.wilayah__name}</td>
                              <td>{dv.fas_kes}</td>
                              <td>{dv.ger_peng_ling}</td>
                              <td>{dv.his_ke_pen}</td>

                              <td>{dv.kes_mas}</td>
                              <td>{dv.ku_ling}</td>
                              <td>{dv.pen_faskes}</td>
                              <td>{dv.per_berobat}</td>
                              <td>{dv.per_ga_hi}</td>
                              <td>{dv.pro_kes_mas}</td>
                              <td>{dv.tena_kes}</td>
                              <td>{dv.kerentanan}</td>
                            </tr>
                          );
                        })}
                        {dataKerentanan.length === 0 && !loadKerentanan ? (
                          <tr>
                            <td colSpan="13" align="center">
                              <FontAwesomeIcon icon={faExclamationCircle} />
                              Data Validasi Belum Ada
                            </td>
                          </tr>
                        ) : (
                          ''
                        )}
                      </table>
                    ) : (
                      ''
                    )}
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <div>
                      {selectedDC ? (
                        <Map
                          center={[0.7893, 120.9213]}
                          zoomControl={false}
                          zoom={5}
                          animate={false}
                          className={styleCss.MapKerentanan}
                          scrollWheelZoom={false}
                          // onlayeradd={esrimap()}

                          ref={mapRef}
                        >
                          {isLoading ? (
                            <div>
                              <CircularProgress
                                className={styleCss.spinnerMap}
                              />
                            </div>
                          ) : (
                            <div>
                              <TileLayer
                                attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                zIndex={-2}
                              />
                            </div>
                          )}
                          <div>
                            <ZoomControl position="topright"></ZoomControl>
                          </div>
                        </Map>
                      ) : (
                        ''
                      )}
                    </div>
                  </TabPanel>
                  {/* <TabPanel value={value} index={2}>
                                        Grafik
      </TabPanel> */}
                </div>
              </div>
            </GridItem>
          </GridContainer>
        </Card>
      </CardBody>

      <Dialog
        className={styleCss.dialogPopUpUbahPassword}
        open={isDeleteDiaolog}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title"></DialogTitle>
        <div className={styleCss.divKonfirmasiTindakan}>
          <Typography className={styleCss.JudulDialogUploadData}>
            Konfirmasi Tindakan
          </Typography>
          <label>Apakah anda yakin menghapus data ?</label>
        </div>

        <DialogContent className={styleCss.dialogPopUpConntenDataUpload}>
          <div className={styleCss.divButtonKonfirmasi}>
            <Button
              onClick={() => setIsDeleteDialog(false)}
              className={styleCss.buttonKonfirmasiTidak}
            >
              Tidak
            </Button>
            <Button
              onClick={() => deleteDataKerentanan()}
              className={styleCss.buttonKonfirmasiYa}
            >
              Ya
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

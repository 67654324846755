import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react';
import clsx from 'clsx';
import LoadingOverlay from 'react-loading-overlay';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';

// Material ui - core
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Hidden from '@material-ui/core/Hidden';
import InputAdornment from '@material-ui/core/InputAdornment';
import Input from '@material-ui/core/Input';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Typography } from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import NativeSelect from '@material-ui/core/NativeSelect';

// icons
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import DashboardIcon from '@material-ui/icons/Dashboard';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import Menu from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import LockIcon from '@material-ui/icons/Lock';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/Error';
// core components
import Snackbar from 'components/Snackbar/Snackbar.js';

// assets
import logo from 'assets/img/logo_low.png';
import styleCss from 'assets/css/views/global.module.css';
import styles from 'assets/jss/material-dashboard-react/components/headerStyle.js';
import stylesHeader from 'assets/jss/material-dashboard-react/components/headerLinksStyle.js';

// configs
import api from 'config/api';
import { UserContext } from 'App';

const useStyles = makeStyles(styles);
const useStylesHeader = makeStyles(stylesHeader);
const useStyles1 = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
});

export default function Header(props) {
  const { isLogin, name: username } = useContext(UserContext);

  const url = window.location.href;
  const { color, width } = props;

  const classes1 = useStyles1();
  const classes = useStyles();

  const history = useHistory();

  const appBarClasses = classNames({
    [' ' + classes[color]]: color,
  });

  const role = JSON.parse(localStorage.getItem('roles'));

  const [state, setState] = useState({
    right: false,
  });
  const [open, setOpen] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogType, setDialogType] = useState('login');
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({});

  const [values, setValues] = useState({
    amount: '',
    password: '',
    password2: '',
    weight: '',
    weightRange: '',
    showPassword: false,
    listProvinsi: [],
    selectedProvinsi: '',
    listKabkot: [],
    selectedKabkot: '',
    email: '',
  });

  const loginPrep = () => {
    setDialogType('login');
    setValues({
      amount: '',
      password: '',
      password2: '',
      weight: '',
      weightRange: '',
      showPassword: false,
      listProvinsi: [],
      selectedProvinsi: '',
      listKabkot: [],
      selectedKabkot: '',
      email: '',
    });
  };

  const registerPrep = () => {
    setDialogType('register');
    setValues({
      amount: '',
      password: '',
      password2: '',
      weight: '',
      weightRange: '',
      showPassword: false,
      listProvinsi: [],
      selectedProvinsi: '',
      listKabkot: [],
      selectedKabkot: '',
    });
    getProvinsi();
  };

  // event handlers
  const toggleDrawer = (anchor, open) => event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleChange = prop => event => {
    if (prop === 'selectedProvinsi') {
      getKabkot(event.target.value);
      setValues({
        ...values,
        selectedProvinsi: event.target.value,
        selectedKabkot: '',
      });
    } else {
      setValues({ ...values, [prop]: event.target.value });
    }
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  const handleClickShowPassword2 = () => {
    setValues({ ...values, showPassword2: !values.showPassword2 });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };
  const handleMouseDownPassword2 = event => {
    event.preventDefault();
  };
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const doLogout = () => {
    localStorage.clear();
    history.push('/home');
    window.location.reload();
  };
  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }
  const doLogin = () => {
    // login validation
    if (!values.email) {
      openNotification('danger', 'Email harus diisi.');
      return;
    }

    if (!values.password) {
      openNotification('danger', 'Password harus diisi.');
      return;
    }
    // start api request
    setLoading(true);

    api
      .login(values.email, values.password)
      .then(res => {
        setLoading(false);
        if (res.data.access_token) {
          localStorage.setItem('token', res.data.access_token);
          window.location.reload();
        } else {
          openNotification('danger', 'Email atau Password salah.');
        }
      })
      .catch(err => {
        setLoading(false);
        if (err.response) {
          let error =
            'The provided authorization grant (e.g., authorization code, resource owner credentials) or refresh token is invalid, expired, revoked, does not match the redirection URI used in the authorization request, or was issued to another client.';
          if (err.response.data.message === error) {
            openNotification('danger', 'Email atau Password salah.');
          } else {
            openNotification('danger', err.response.data.message);
          }
        } else {
          openNotification('danger', 'Koneksi bermasalah.');
        }
      });
  };

  const doRegister = () => {
    if (!values.selectedProvinsi) {
      openNotification('danger', 'Provinsi harus diisi.');
      return;
    }
    if (!values.selectedKabkot) {
      openNotification('danger', 'Kabupaten/Kota harus diisi.');
      return;
    }
    if (!values.name) {
      openNotification('danger', 'Nama harus diisi.');
      return;
    }
    if (!values.email) {
      openNotification('danger', 'Email harus diisi.');
      return;
    }
    if (!values.password && !values.password2) {
      openNotification('danger', 'Password Harus diisi.');
      return;
    }

    if (values.password !== values.password2) {
      openNotification('danger', 'Konfirmasi Password tidak sesuai!.');
      return;
    }

    let kabkotparse = JSON.parse(values.selectedKabkot);

    let datas = {
      name: values.name,
      email: values.email,
      wilayah_id: kabkotparse.id,
      password: values.password,
    };

    setLoading(true);
    api
      .register(datas)
      .then(res => {
        setLoading(false);
        if (res.data.error_code === 0) {
          setIsRegistered(true);
          dialogType('done');
        } else {
          openNotification('danger', res.data.message);
        }
      })
      .catch(err => {
        setLoading(false);
        // this.LoadingBar.complete()
        if (err.response) {
          let error =
            'The provided authorization grant (e.g., authorization code, resource owner credentials) or refresh token is invalid, expired, revoked, does not match the redirection URI used in the authorization request, or was issued to another client.';
          if (err.response.data.message === error) {
            openNotification('danger', 'Email atau Password salah.');
          } else {
            openNotification('danger', err.response.data.message);
          }
        } else {
          openNotification('danger', 'Koneksi bermasalah.');
        }
      });
  };

  function closeNotification() {
    setAlert({
      isAlertOpen: false,
      alertState: 'danger',
    });
  }

  function openNotification(condition, Message) {
    if (condition === 'success') {
      setAlert({
        isAlertOpen: true,
        alertState: 'success',
        AlertMessage: Message,
      });
    } else {
      setAlert({
        isAlertOpen: true,
        alertState: 'danger',
        AlertMessage: Message,
      });
    }
    setTimeout(
      function() {
        closeNotification();
      }.bind(this),
      5000,
    );
  }
  const goTo = url => {
    localStorage.setItem('sdstate', 'profile');
    history.push('/' + url);
  };

  const list = anchor => (
    <div
      className={clsx(classes1.list, {
        [classes1.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {['Beranda', 'Register', 'Login'].map((text, index) => (
          <ListItem button key={text} component={Link} to={'/' + text}>
            <ListItemIcon>
              {index === 0 && <DashboardIcon />}
              {index === 1 && <AssignmentIcon />}
              {index === 2 && <AccountBoxIcon />}
            </ListItemIcon>

            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
    </div>
  );

  const getProvinsi = () => {
    api.getProvinsi().then(res => {
      // setIsLoading(true);
      if (res.data.error_code === 0) {
        setValues({
          ...values,
          listProvinsi: res.data.data,
        });
      } else {
        openNotification('danger', res.data.message);
      }
    });
  };

  const getKabkot = id => {
    let dt = JSON.parse(id);
    api.getKabupaten(dt.name).then(res => {
      // setIsLoading(true);
      if (res.data.error_code === 0) {
        setValues({
          ...values,
          selectedProvinsi: id,
          listKabkot: res.data.data,
        });
      } else {
        openNotification('danger', res.data.message);
      }
    });
  };

  useEffect(() => {
    getProvinsi();
  }, []);

  return (
    <div>
      <Snackbar
        place="tc"
        color={alert.alertState}
        icon={alert.alertState === 'success' ? CheckIcon : ErrorIcon}
        message={alert.AlertMessage}
        open={alert.isAlertOpen}
        closeNotification={() => closeNotification()}
        close
      />
      <LoadingOverlay
        active={loading}
        spinner
        text="Mohon tunggu..."
        style={{ color: 'white' }}
      />
      <Dialog className={styleCss.dialogPopUpUbahPassword} open={openDialog}>
        <DialogTitle id="alert-dialog-slide-title">
          <IconButton
            aria-label="close"
            className={styleCss.closeButtonDialog}
            onClick={() => setOpenDialog(false)}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        {(dialogType === 'login' || dialogType === 'register') && (
          <div className={styleCss.divKonfirmasiTindakan}>
            <Typography className={styleCss.JudulDialogUploadData}>
              Selamat Datang di APIK
            </Typography>
            <Typography className={styleCss.JudulSubDialog}>
              {dialogType === 'login' && 'Silahkan Login ke Akun anda'}
              {dialogType === 'register' &&
                'silahkan Registrasi untuk Akun baru'}
            </Typography>
          </div>
        )}

        {dialogType === 'login' && (
          <DialogContent className={styleCss.dialogPopUpConntenDataUpload}>
            <div>
              <div>
                <Input
                  placeholder="Email"
                  inputProps={{ 'aria-label': 'description' }}
                  onChange={handleChange('email')}
                  name="email"
                  style={{ width: '100%', fontSize: 12, marginTop: 20 }}
                  startAdornment={
                    <InputAdornment position="start">
                      <AccountCircle />
                    </InputAdornment>
                  }
                />
              </div>
              <div>
                <Input
                  id="standard-adornment-password"
                  type={values.showPassword ? 'text' : 'password'}
                  value={values.password}
                  onChange={handleChange('password')}
                  placeholder="Password"
                  style={{
                    width: '100%',
                    fontSize: 12,
                    marginTop: 20,
                    marginBottom: 20,
                  }}
                  startAdornment={
                    <InputAdornment position="start">
                      <LockIcon />
                    </InputAdornment>
                  }
                  endAdornment={
                    <InputAdornment position="start">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {values.showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </div>
            </div>
            <div className={styleCss.divButtonKonfirmasi}>
              <Button
                className={styleCss.buttonRegister}
                onClick={() => registerPrep()}
              >
                Registrasi
              </Button>
              <Button
                className={styleCss.buttonLogin}
                onClick={() => doLogin()}
              >
                Login
              </Button>
            </div>
          </DialogContent>
        )}

        {dialogType === 'register' && (
          <DialogContent className={styleCss.dialogPopUpConntenDataUpload}>
            <div>
              <div className={styleCss.registerInputContainer}>
                <label className={styleCss.registerInputLabel}>Provinsi</label>
                <NativeSelect
                  value={values.selectedProvinsi}
                  onChange={handleChange('selectedProvinsi')}
                  style={{ width: '100%', fontSize: 12 }}
                >
                  <option key="noneProvinsi" value="">
                    Pilih Provinsi
                  </option>
                  {values.listProvinsi.map((dt, index) => {
                    return (
                      <option
                        key={'listProvinsi' + index}
                        value={JSON.stringify(dt)}
                      >
                        {' '}
                        {dt.name}
                      </option>
                    );
                  })}
                </NativeSelect>
              </div>
              <div className={styleCss.registerInputContainer}>
                <label className={styleCss.registerInputLabel}>
                  Kabupaten/Kota
                </label>
                <NativeSelect
                  value={values.selectedKabkot}
                  onChange={handleChange('selectedKabkot')}
                  name="age"
                  style={{ width: '100%', fontSize: 12 }}
                >
                  <option key="nonekb" value="">
                    Pilih Kabupaten/Kota
                  </option>
                  {values.listKabkot.map((dt, index) => {
                    return (
                      <option
                        key={'listKabkot' + index}
                        value={JSON.stringify(dt)}
                      >
                        {' '}
                        {dt.name}
                      </option>
                    );
                  })}
                </NativeSelect>
              </div>
              <div className={styleCss.registerInputContainer}>
                <label className={styleCss.registerInputLabel}>Nama</label>
                <Input
                  placeholder="Ketik disini..."
                  onChange={handleChange('name')}
                  type="text"
                  inputProps={{ 'aria-label': 'description' }}
                  style={{ width: '100%', fontSize: 12 }}
                />
              </div>
              <div className={styleCss.registerInputContainer}>
                <label className={styleCss.registerInputLabel}>Email</label>
                <Input
                  placeholder="Ketik disini..."
                  onChange={handleChange('email')}
                  type="email"
                  inputProps={{ 'aria-label': 'description' }}
                  style={{ width: '100%', fontSize: 12 }}
                />
              </div>
              <div className={styleCss.registerInputContainer}>
                <label className={styleCss.registerInputLabel}>Password</label>
                <Input
                  id="standard-adornment-password"
                  type={values.showPassword ? 'text' : 'password'}
                  value={values.password}
                  onChange={handleChange('password')}
                  placeholder="Ketik disini..."
                  style={{ width: '100%', fontSize: 12 }}
                  endAdornment={
                    <InputAdornment position="start">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {values.showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </div>
              <div className={styleCss.registerInputContainer}>
                <label className={styleCss.registerInputLabel}>
                  Konfirmasi Password
                </label>
                <Input
                  id="standard-adornment-password"
                  type={values.showPassword2 ? 'text' : 'password'}
                  value={values.password2}
                  onChange={handleChange('password2')}
                  placeholder="Ketik disini..."
                  style={{ width: '100%', fontSize: 12 }}
                  endAdornment={
                    <InputAdornment position="start">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword2}
                        onMouseDown={handleMouseDownPassword2}
                      >
                        {values.showPassword2 ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </div>
            </div>
            <div className={styleCss.divButtonKonfirmasi}>
              <Button
                className={styleCss.buttonLogin}
                onClick={() => doRegister()}
              >
                Submit
              </Button>
              <div className={styleCss.hasaccount}>
                Sudah memiliki akun ?{' '}
                <span
                  onClick={() => loginPrep()}
                  style={{ color: '#FF6B09', cursor: 'pointer' }}
                >
                  Login
                </span>
              </div>
            </div>
          </DialogContent>
        )}

        {dialogType === 'done' && (
          <DialogContent className={styleCss.dialogPopUpConntenDataUpload}>
            <div className={styleCss.successRegIcon}>
              <CheckCircleIcon fontSize="inherit" />
            </div>
            <div className={styleCss.successRegTitle}>
              Selamat anda telah berhasil mendaftarkan diri !
            </div>
            <div className={styleCss.successRegText}>
              Akun anda akan diverifikasi terlebih dahulu oleh administrator
              pusat, untuk info lebih lanjut akan dikirim ke email terdaftar.{' '}
            </div>
            <div className={styleCss.divButtonKonfirmasi}>
              <Button
                className={styleCss.buttonLogin}
                onClick={() => loginPrep()}
              >
                {' '}
                Login
              </Button>
            </div>
          </DialogContent>
        )}
      </Dialog>

      <AppBar
        className={classes.appBar + appBarClasses}
        style={{
          position: 'fixed !important',
          width: width,
          float: 'left',
          left: 0,
          right: 'auto !important',
          transition: 'all .3s linear',
        }}
      >
        <Toolbar className={classes.container}>
          <div>
            <Link className={classes.gambar} to={'/'}>
              <img src={logo} style={{ height: 70 }} />
            </Link>
          </div>
          <div className={classes.flex}>
            {/* Here we create navbar brand, based on route name */}
          </div>
          <Hidden smDown implementation="css">
            {isLogin ? (
              <React.Fragment>
                <Button
                  ref={anchorRef}
                  aria-controls={open ? 'menu-list-grow' : undefined}
                  aria-haspopup="true"
                  onClick={handleToggle}
                  style={{
                    color: 'white',
                    borderWidth: 1,
                    borderStyle: 'solid',
                    borderColor: 'rgb(255, 255, 255)',
                    borderRadius: 54,
                    fontSize: 12,
                    fontWeight: 600,
                    textTransform: 'capitalize',
                  }}
                >
                  {username}
                </Button>
                <Popper
                  open={open}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  transition
                  disablePortal
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === 'bottom'
                            ? 'center top'
                            : 'center bottom',
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                          {role && role.length === 1 && role[0].id === 5 ? (
                            <MenuList
                              autoFocusItem={open}
                              id="menu-list-grow"
                              onKeyDown={handleListKeyDown}
                              style={{ padding: '0 !important', fontSize: 12 }}
                            >
                              <MenuItem
                                style={{
                                  padding: '0 !important',
                                  fontSize: 12,
                                  background:
                                    url.includes('profile') ||
                                    url.includes('data-kerentanan')
                                      ? '#00A6A6'
                                      : '#fffff',
                                  color:
                                    url.includes('profile') ||
                                    url.includes('data-kerentanan')
                                      ? 'white'
                                      : 'black',
                                }}
                                onClick={() => goTo('program-profile')}
                              >
                                Profile
                              </MenuItem>
                              <Divider light />

                              <MenuItem
                                style={{
                                  padding: '0 !important',
                                  fontSize: 12,
                                  background: url.includes('home')
                                    ? '#00A6A6'
                                    : '#fffff',
                                  color: url.includes('home')
                                    ? 'white'
                                    : 'black',
                                }}
                                onClick={() => goTo('beranda')}
                              >
                                Visualisasi
                              </MenuItem>
                              <Divider light />
                              <MenuItem
                                style={{
                                  padding: '0 !important',
                                  fontSize: 12,
                                }}
                                onClick={() => doLogout()}
                              >
                                Logout
                              </MenuItem>
                            </MenuList>
                          ) : (
                            <MenuList
                              autoFocusItem={open}
                              id="menu-list-grow"
                              onKeyDown={handleListKeyDown}
                              style={{ padding: '0 !important', fontSize: 12 }}
                            >
                              <MenuItem
                                style={{
                                  padding: '0 !important',
                                  fontSize: 12,
                                  background:
                                    url.includes('profile') ||
                                    url.includes('data-kerentanan')
                                      ? '#00A6A6'
                                      : '#fffff',
                                  color:
                                    url.includes('profile') ||
                                    url.includes('data-kerentanan')
                                      ? 'white'
                                      : 'black',
                                }}
                                onClick={() => goTo('profile')}
                              >
                                Profile
                              </MenuItem>
                              <Divider light />

                              <MenuItem
                                style={{
                                  padding: '0 !important',
                                  fontSize: 12,
                                  background: url.includes('home')
                                    ? '#00A6A6'
                                    : '#fffff',
                                  color: url.includes('home')
                                    ? 'white'
                                    : 'black',
                                }}
                                onClick={() => goTo('beranda')}
                              >
                                Visualisasi
                              </MenuItem>
                              <Divider light />
                              <MenuItem
                                style={{
                                  padding: '0 !important',
                                  fontSize: 12,
                                  background: url.includes('validasi')
                                    ? '#00A6A6'
                                    : '#fffff',
                                  color: url.includes('validasi')
                                    ? 'white'
                                    : 'black',
                                }}
                                onClick={() => goTo('validasi')}
                              >
                                Validasi
                              </MenuItem>
                              <Divider light />
                              <MenuItem
                                style={{
                                  padding: '0 !important',
                                  fontSize: 12,
                                  background: url.includes('adaptasi')
                                    ? '#00A6A6'
                                    : '#fffff',
                                  color: url.includes('adaptasi')
                                    ? 'white'
                                    : 'black',
                                }}
                                onClick={() => goTo('adaptasi')}
                              >
                                Adaptasi
                              </MenuItem>
                              <Divider light />
                              <MenuItem
                                style={{
                                  padding: '0 !important',
                                  fontSize: 12,
                                  background: url.includes('desa-desi')
                                    ? '#00A6A6'
                                    : '#fffff',
                                  color: url.includes('desa-desi')
                                    ? 'white'
                                    : 'black',
                                }}
                                onClick={() => goTo('desa-desi')}
                              >
                                Desa-Desi
                              </MenuItem>
                              <Divider light />
                              <MenuItem
                                style={{
                                  padding: '0 !important',
                                  fontSize: 12,
                                }}
                                onClick={() => doLogout()}
                              >
                                Logout
                              </MenuItem>
                            </MenuList>
                          )}
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </React.Fragment>
            ) : (
              <Button
                className={classes.title}
                onClick={() => setOpenDialog(true)}
              >
                Login
              </Button>
            )}
          </Hidden>

          <Hidden mdUp implementation="css">
            {['right'].map(anchor => (
              <Fragment key={anchor}>
                <Button
                  onClick={toggleDrawer(anchor, true)}
                  style={{ float: 'right', color: 'white' }}
                >
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={toggleDrawer(anchor, true)}
                  >
                    <Menu />
                  </IconButton>
                </Button>
                <Drawer
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                >
                  {list(anchor)}
                </Drawer>
              </Fragment>
            ))}
          </Hidden>
        </Toolbar>
      </AppBar>
    </div>
  );
}
Header.propTypes = {
  color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger']),
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  routes: PropTypes.arrayOf(PropTypes.object),
};

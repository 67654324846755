import React, { useEffect, useState } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { Chart } from 'react-chartjs-2';

import { useHistory } from 'react-router-dom';

import {
  Map,
  TileLayer,
  ZoomControl,
  GeoJSON,
  WMSTileLayer,
} from 'react-leaflet';
import Leaflet from 'leaflet';

// core components
import Snackbar from 'components/Snackbar/Snackbar.js';
import Navbar from 'components/Navbars/Navbar.js';

// @material-ui/core
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';

// Css & js Style
import styleCss from 'assets/css/views/global.module.css';

// Icons
import CheckIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/Error';
import SearchIcon from '@material-ui/icons/Search';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCity,
  faLandmark,
  faArchway,
  faBuilding,
  faLayerGroup,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';

import api from 'config/api';

Chart.defaults.global.legend.display = false;

const mapRef = React.createRef(null);
export default function Home({ isInformationBarVisible, toggleInfomationbar }) {
  const history = useHistory();

  const [alert, setAlert] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadOverlay, setIsLoadOverlay] = useState(false);
  const [geoJsonData, setGeoJsonData] = useState();
  const [loadGeoJson, setLoadGeoJson] = useState(true);
  const [dropdownCodeMap, setDropdownCodeMap] = useState('');
  const [leftMap, setLeftMap] = useState(false);
  const [rightMap, setRightMap] = useState(false);
  const [savedAdaptasi, setSavedAdaptasi] = useState({});
  const [baseLayer, setBaseLayer] = useState(false);
  const [disabledDd, setDisabledDd] = useState(false);
  const [selectedDesa, setSelectedDesa] = useState('');
  const [dataDaerah, setDataDaerah] = useState({
    prov: '',
    kabkot: '',
    kec: '',
    desa: '',
  });
  const [dropdown, setDropdown] = useState({
    tingkat: '',
    jenis: '',
    tahun: '',
    model: '',
  });
  const [listTahun, setListTahun] = useState([]);
  const [listTingkat, setListTingkat] = useState([]);
  const [listJenis, setListJenis] = useState([]);
  const [listModel, setListModel] = useState([]);
  const [listLayer, setListLayer] = useState([]);
  const [dataLegend, setDataLegend] = useState(false);
  const [listLegend, setListLegend] = useState([]);
  const [rightSideTitle, setRightSideTitle] = useState({
    tingkat: { name: null },
    jenis: { name: '' },
  });
  const [isReset, setIsReset] = useState(false);
  const [dataUser, setDataUser] = useState(false);
  const [isValidated, setIsValidated] = useState(false);
  const [isCreate, setIsCreate] = useState(false);
  const [idEdit, setIdEdit] = useState('');
  const [defaultAdaptasi, setDefaultAdaptasi] = useState([]);
  const [selectedChecked, setSelectedChecked] = useState([]);
  const [isHasOther, setIshasOther] = useState(false);
  const [otherText, setOtherText] = useState('');

  // map state, full, halfright, halfleft, fullhalf
  const getMasterType = (type, group) => {
    api
      .getMasterType(type, group)
      .then(res => {
        setIsLoading(true);
        if (res.data.error_code === 0) {
          const { data } = res.data;
          if (type === 'Level') {
            setListTingkat(data);
          } else if (type === 'Jenis') {
            setListJenis(data);
          } else if (type === 'Period') {
            setListTahun(data);
          } else if (type === 'Model') {
            setListModel(data);
          } else if (type === 'Legend') {
            const [legend] = data;
            legend.data.reverse();
            setListLegend(legend.data);
          }
        }
        setIsLoading(false);
      })
      .catch(err => {
        setIsLoading(false);
        console.log('Error:', err);
      });
  };

  const getGeojson = (wilayah_id, first) => {
    setLoadGeoJson(true);
    setIsReset(true);
    setIsLoading(true);

    api
      .getGeojsonDesa('desa', first ? wilayah_id : dataUser.wilayah_id)
      .then(res => {
        setIsLoading(false);
        if (res.data.error_code === 0) {
          const data = res.data.data;
          setGeoJsonData(data);

          const map = mapRef.current.leafletElement;

          const gjson = Leaflet.geoJson(data, {
            style: {
              color: '#00A6A6',
              weight: 1,
              opacity: 1,
              fillOpacity: 0.5,
            },
          }).addTo(map);

          const bounds = gjson.getBounds();

          map.fitBounds(bounds);

          gjson.eachLayer(layer => {
            //  initMaps(data);
            layer.addEventListener('click', async () => {
              const lay = layer.feature.properties;

              await getDataSide(lay.IDDESA);

              setSelectedDesa(lay.IDDESA);
              setDataDaerah({
                prov: lay.PROVINSI,
                kabkot: lay.KABKOT,
                kec: lay.KECAMATAN,
                desa: lay.DESA,
              });
            });

            layer.addEventListener('mouseover', () => {
              const lay = layer.feature.properties;
              const dataSend = lay.DESA;
              layer.bindTooltip('<div>' + dataSend + '</div>').openTooltip();
            });
          });
        }
        setLoadGeoJson(false);
      })
      .catch(err => {
        setIsLoading(false);
        console.log('Errorr:', err);
      });
  };

  const setSidebar = state => {
    if (state === 'left') {
      setLeftMap(!leftMap);
    } else {
      if (dropdown.tahun && dropdown.jenis && dropdown.tingkat && isValidated) {
        if (dropdown.model) {
          setRightMap(!rightMap);
          toggleInfomationbar(!isInformationBarVisible);
        }
      } else {
        openNotification(
          'danger',
          'Mohon lengkapi data dropdown dan pilih peta dahulu.',
        );
      }
    }
  };

  const getMapLayer = () => {
    api
      .getLayers()
      .then(res => {
        setIsLoading(true);
        if (res.data.error_code === 0) {
          setListLayer(res.data.data);
        }
      })
      .catch(err => {
        setIsLoading(false);
        console.log('Error:', err);
      });
  };

  const searchData = () => {
    if (!dropdown.tingkat) {
      openNotification('danger', 'Tingkat harus dipilih!');
      return;
    } else if (!dropdown.jenis) {
      openNotification('danger', 'Jenis harus dipilih!');
      return;
    } else if (!dropdown.tahun) {
      openNotification('danger', 'Tahun harus dipilih!');
      return;
    } else {
      let dtingkat = JSON.parse(dropdown.tingkat);
      let djenis = JSON.parse(dropdown.jenis);
      let dtahun = JSON.parse(dropdown.tahun);
      let dmodel = JSON.parse(dropdown.model);
      let comb = '';
      if (dtingkat.name === 'Keterpaparan' || dtingkat.name === 'Kerentanan') {
        comb = dtingkat.code + djenis.code + dtahun.code + djenis.code;
      } else {
        comb = dtingkat.code + djenis.code + dtahun.code + dmodel.code;
      }
      console.log(dmodel, 'isi model');
      setRightSideTitle({
        tingkat: dtingkat,
        jenis: djenis,
        tahun: dtahun,
        model: dmodel,
      });
      setDropdownCodeMap('apik:' + comb);
      setDisabledDd(true);
      setBaseLayer(true);
      setDataLegend(true);

      getGeojson(dtingkat, djenis, dtahun, dmodel);
    }
  };

  const doSubmit = () => {
    if (isCreate) {
      createAdaptasi();
    } else {
      editAdaptasi();
    }
  };

  const createAdaptasi = () => {
    setIsLoadOverlay(true);
    let detailAdaptasi = [];

    for (let i = 0; i < selectedChecked.length; i++) {
      if (selectedChecked[i])
        detailAdaptasi.push({ adaptation: defaultAdaptasi[i].name });
    }

    if (isHasOther) {
      detailAdaptasi.push({ adaptation: otherText });
    }

    const dats = {
      wilayah_id: selectedDesa,
      details: detailAdaptasi,
    };

    api
      .createAdaptasi(dats)
      .then(res => {
        setIsLoadOverlay(false);
        if (res.data.error_code === 0) {
          openNotification('success', 'Berhasil Menyimpan data.');
        } else {
          openNotification('danger', res.data.message);
        }
      })
      .catch(err => {
        openNotification('danger', 'koneksi bermasalah');
        setIsLoadOverlay(false);
        console.log('Errorr:', err);
      });
  };

  const editAdaptasi = () => {
    setIsLoadOverlay(true);
    let detailAdaptasi = [];

    for (let i = 0; i < selectedChecked.length; i++) {
      if (selectedChecked[i])
        detailAdaptasi.push({ adaptation: defaultAdaptasi[i].name });
    }
    if (isHasOther) detailAdaptasi.push({ adaptation: otherText });

    let dats = {
      id: idEdit,
      wilayah_id: selectedDesa,
      details: detailAdaptasi,
    };
    api
      .editAdaptasi(dats)
      .then(res => {
        setIsLoadOverlay(false);
        if (res.data.error_code === 0) {
          openNotification('success', 'Berhasil Mengubah data.');
        } else {
          openNotification('danger', res.data.message);
        }
      })
      .catch(err => {
        setIsLoadOverlay(false);
        console.log('Errorr:', err);
      });
  };

  const getDefaultAdaptasi = (iddesa, tingkat, level, tahun, model) => {
    setIsLoading(true);
    console.log(
      'adaptation.getDefaultAdaptasi',
      iddesa,
      tingkat,
      level,
      tahun,
      model,
    );
    return api
      .getDefaultAdaptasi(
        iddesa,
        tingkat,
        level,
        tahun,
        model ? model : undefined,
      )
      .then(res => {
        setIsLoading(false);
        if (res.data.error_code === 0) {
          setIsValidated(true);
          let vals = res.data.data ? res.data.data : [];

          return vals;
        }
      })
      .catch(err => {
        setIsLoading(false);
        console.log('Errorr:', err);
      });
  };

  const getDataSide = iddesa =>
    api
      .getAdaptasi(iddesa)
      .then(async res => {
        const body = res.data;

        if (body.error_code !== 0) {
          throw new Error(body.message || 'Error get data side');
        }

        setIsValidated(true);

        const { data } = body;

        setSavedAdaptasi(data);

        if (data.length === 0) {
          setIsCreate(true);
        } else {
          setIsCreate(false);
          setIdEdit(data[0].id);
        }

        const dBahaya = await getDefaultAdaptasi(iddesa, 1, 1, 1, 1);
        const dKer = await getDefaultAdaptasi(iddesa, 3, 1, 1);
        const dket = await getDefaultAdaptasi(iddesa, 4, 1, 1);

        const vals = [...dBahaya, ...dKer, ...dket];

        console.log('combined', vals);

        const selected = new Array(vals.length);

        console.log(selected);

        setSelectedChecked(prev => selected);
        setIshasOther(false);
        setDefaultAdaptasi(vals);
        setRightMap(true);
        toggleInfomationbar(true);
      })
      .catch(err => {
        setIsLoading(false);
        console.log('Errorr:', err);
      });

  function closeNotification() {
    setAlert({
      isAlertOpen: false,
      alertState: 'danger',
    });
  }
  function openNotification(condition, Message) {
    if (condition === 'success') {
      setAlert({
        isAlertOpen: true,
        alertState: 'success',
        AlertMessage: Message,
      });
    } else {
      setAlert({
        isAlertOpen: true,
        alertState: 'danger',
        AlertMessage: Message,
      });
    }
    setTimeout(
      function() {
        closeNotification();
      }.bind(this),
      5000,
    );
  }

  const getUserByEmail = id => {
    setIsLoading(true);
    api
      .getUserByEmail(id)
      .then(res => {
        if (res.data.error_code === 0) {
          setDataUser(res.data.data[0]);
          let comb =
            res.data.data[0].wilayah__prov_no +
            '' +
            res.data.data[0].wilayah__kab_kot_no;
          getGeojson(comb, true);
        }
        setIsLoading(false);
      })
      .catch(err => {
        setIsLoading(false);
        console.log('Errorr:', err);
      });
  };
  const setCheckAdaptasi = (e, index) => {
    console.log(e.target.checked, 'isi check');
    console.log(index, 'isi index');
    console.log(selectedChecked, 'isi selected');
    let ad = selectedChecked;
    ad[index] = e.target.checked;
    console.log(ad, 'changed check');
    setSelectedChecked(prev => ad);
  };

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      history.push('home');
    }

    localStorage.setItem('iddes', null);

    getMasterType('Level');
    getMasterType('Legend');

    getMapLayer();
    getUserByEmail(localStorage.getItem('email'));
  }, []);

  return (
    <div className={styleCss.mapContainer}>
      <LoadingOverlay
        active={isLoadOverlay}
        spinner
        text="Sedang menyimpan data..."
        style={{ color: 'white' }}
      />
      <Snackbar
        place="tc"
        color={alert.alertState}
        icon={alert.alertState === 'success' ? CheckIcon : ErrorIcon}
        message={alert.AlertMessage}
        open={alert.isAlertOpen}
        closeNotification={() => closeNotification()}
        close
      />
      <div
        className={leftMap ? styleCss.sidebarLeft : styleCss.sidebarLeftNone}
      >
        <div
          className={
            leftMap ? styleCss.sidebarLeftIconActive : styleCss.sidebarLeftIcon
          }
          onClick={() => setSidebar('left')}
        >
          <span className={styleCss.layerspan}>
            <FontAwesomeIcon icon={faLayerGroup} /> Layer
          </span>
        </div>
        <div
          className={
            leftMap ? styleCss.sidebarLeftContent : styleCss.leftSidebarDataNone
          }
        >
          <div className={styleCss.sidebarLeftContentTitle}>
            Layer Properties
          </div>
          <div>
            <Paper className={styleCss.paperSearch}>
              <InputBase
                className={styleCss.inputSearch}
                placeholder="Search Layer..."
              />
              <IconButton
                type="button"
                className={styleCss.iconButton}
                aria-label="search"
              >
                <SearchIcon />
              </IconButton>
            </Paper>
          </div>
          <div>
            <FormControlLabel
              style={{ width: '100%', padding: 5 }}
              control={
                <Checkbox
                  name="BaseLayer"
                  checked={baseLayer}
                  onClick={() => setBaseLayer(!baseLayer)}
                  className={styleCss.checkboxTextFormat}
                />
              }
              label="Base Layer"
            />
            {listLayer.map((lyr, index) => (
              <div key={`layer-list-item-${index + 1}`}>
                <FormControl
                  component="fieldset"
                  className={styleCss.checkboxTextFormat}
                >
                  <FormLabel
                    component="legend"
                    className={styleCss.checkboxTextFormatTitle}
                    style={{ fontWeight: '600 !important', width: '100%' }}
                  >
                    {lyr.created_by__name}
                  </FormLabel>
                  <FormGroup>
                    {lyr.layers.map((lyrC, index) => (
                      <FormControlLabel
                        key={'layer' + index}
                        control={
                          <Checkbox
                            name="gilad"
                            className={styleCss.checkboxTextFormat}
                          />
                        }
                        label={lyrC.program_name + ' - ' + lyrC.layer_name}
                      />
                    ))}
                  </FormGroup>
                </FormControl>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Map
        center={[0.7893, 120.9213]}
        zoomControl={false}
        zoom={5}
        animate={false}
        className={rightMap ? styleCss.Maphalf : styleCss.Map}
        scrollWheelZoom={false}
        ref={mapRef}
      >
        {isLoading ? (
          <div>
            <CircularProgress className={styleCss.spinnerMap} />
          </div>
        ) : (
          <div>
            <TileLayer
              attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              zIndex={-2}
            />

            {baseLayer ? (
              <WMSTileLayer
                layers="apik:apik_desa"
                url="https://geoserver.piarea.co.id/geoserver/gwc/service/wms/"
                format="image/PNG"
                // opacity= "0.7"
                styles={dropdownCodeMap}
              />
            ) : (
              ''
            )}

            {/* {
                                listLegend.length > 0 ?
                                    <Control position="bottomleft">
                                        <div className={styleCss.BoxLegend} >
                                            <div className={styleCss.LegendCms}>
                                                <GridContainer >
                                                    <GridItem xs={4}>
                                                        <NotesIcon className={styleCss.IconInfoLegendHeader} />
                                                    </GridItem>
                                                    <GridItem xs={8}>

                                                        <Typography className={styleCss.TextLegend}>Legend</Typography>

                                                    </GridItem>
                                                </GridContainer>
                                            </div>

                                            {
                                                dataLegend ?
                                                    <table>

                                                        {
                                                            listLegend.map((dl, index) => {
                                                                return (
                                                                    <tr>
                                                                        <td> <div style={{
                                                                            width: 14, height: 14, background: dl.fill, border: '1px solid' + dl.outline, marginLeft: 20, marginBottom: 5, marginTop: 5
                                                                        }}></div></td>
                                                                        <td>
                                                                            {dl.range}
                                                                        </td>
                                                                        <td>{dl.category}</td>


                                                                    </tr>
                                                                )
                                                            })
                                                        }

                                                    </table>
                                                    : <div style={{ padding: 20, textAlign: 'center' }}>
                                                        <FontAwesomeIcon icon={faExclamationCircle} /> Belum ada data terpilih
                          </div>

                                            }



                                        </div>
                                    </Control>
                                    : ''


                            } */}
            {loadGeoJson ? (
              <GeoJSON
                data={geoJsonData}
                style={{ color: '#00A6A6', weight: 1, opacity: 0.65 }}
              />
            ) : (
              ''
            )}

            <div style={{ marginTop: 50 }}>
              <ZoomControl position="topright"></ZoomControl>
            </div>
          </div>
        )}
      </Map>

      <div
        className={rightMap ? styleCss.sidebarRight : styleCss.sidebarRightNone}
      >
        <div className={styleCss.sidebarRightIcon} onClick={() => setSidebar()}>
          <FontAwesomeIcon
            icon={faInfoCircle}
            className={styleCss.sidebarRightIconPos}
          />
        </div>
        <div
          className={
            rightMap
              ? styleCss.sidebarRightContent
              : styleCss.sidebarRightContentNone
          }
        >
          <div className={styleCss.sidebarRightContentTitle}>
            Adaptasi Data {rightSideTitle.tingkat.name}
            {' - ' + rightSideTitle.jenis.name}
          </div>
          {/* 
                    <div>
                        <Radar data={dataBahayaParams.data} options={dataBahayaParams.options} />
                    </div> */}

          <table>
            <tr>
              <td style={{ fontWeight: 600 }}>Info Wilayah</td>
            </tr>
            <Divider />
            <tr>
              <td>
                <FontAwesomeIcon icon={faLandmark} /> Provinsi
              </td>
              <td>: {dataDaerah.prov}</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon icon={faCity} /> Kab/Kota
              </td>
              <td>: {dataDaerah.kabkot}</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon icon={faBuilding} /> Kecamatan
              </td>
              <td>: {dataDaerah.kec}</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon icon={faArchway} /> Desa/Kel
              </td>
              <td>: {dataDaerah.desa}</td>
            </tr>
            <tr>
              <td></td>
            </tr>
            <tr>
              <td></td>
            </tr>
            <tr>
              <td></td>
            </tr>
          </table>

          <div>
            <div style={{ fontWeight: 600 }}>Pilihan Adaptasi</div>
            <FormGroup>
              {defaultAdaptasi.map((da, index) => {
                return (
                  <FormControlLabel
                    key={'label' + index}
                    className={styleCss.labelForm}
                    style={{ color: 'black' }}
                    control={
                      <Checkbox
                        color="default"
                        key={'checked' + index}
                        checked={selectedChecked[index]}
                        onClick={e => setCheckAdaptasi(e, index)}
                      />
                    }
                    label={da.name}
                  />
                );
              })}
              <div className={styleCss.adaptationOtherContainer}>
                <Checkbox
                  color="default"
                  key={'checkedLainnya'}
                  name="checkedA"
                  checked={isHasOther || false}
                  onClick={e => setIshasOther(e.target.checked)}
                />
                <input
                  style={{ paddingTop: 5, paddingBottom: 5, width: '100%' }}
                  placeholder="Lainnya"
                  value={otherText}
                  onChange={e => setOtherText(e.target.value)}
                />
              </div>
            </FormGroup>
          </div>

          <div style={{ marginTop: 300, textAlign: 'center' }}>
            <Button
              variant="outlined"
              onClick={() => doSubmit()}
              className={styleCss.btnSaveValidasi}
            >
              Save
            </Button>
          </div>
        </div>
      </div>

      <div className={styleCss.footerHome}>@2020 Copyright APIK</div>
    </div>
  );
}

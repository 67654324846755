import {
  container,
  defaultFont,
  primaryColor,
  defaultBoxShadow,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
  whiteColor,
} from 'assets/jss/material-dashboard-react.js';

const headerStyle = () => ({
  appBar: {
    background: 'linear-gradient(#00A6A6, transparent)',

    boxShadow: '0 4px 20px 0 transparent, 0 7px 10px -5px transparent',

    // borderBottom: "0",
    // marginBottom: "",

    // position: "absolute",
    width: '100%',
    // paddingTop: "10px",
    zIndex: '1029',
    // color: grayColor[5],
    border: '0',
    borderRadius: '0px',
    // padding: "10px 0",
    // transition: "all 150ms ease 0s",
    minHeight: '50px',
    display: 'block',
  },
  container: {
    ...container,
    minHeight: '50px',
  },
  flex: {
    flex: 1,
  },
  title: {
    ...defaultFont,
    letterSpacing: 'unset',
    lineHeight: '20px',
    padding: '5px 30px',
    textTransform: 'capitalize',
    fontSize: '16px',
    borderRadius: '30px',
    marginRight: '10px',
    color: 'white',
    // fontWeight:"bold",
    margin: '0',
    background: '#FF6B09',
    '&:hover,&:focus': {
      background: '#FF6B09',
      color: 'white',
      fontWeight: 'bold',
    },
  },

  appResponsive: {
    top: '8px',
  },
  primary: {
    backgroundColor: primaryColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  info: {
    backgroundColor: infoColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  success: {
    backgroundColor: successColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  warning: {
    backgroundColor: warningColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  danger: {
    backgroundColor: dangerColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
});

export default headerStyle;

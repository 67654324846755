const formatDate = dateWithTime => {
  const dateSeparateTime = dateWithTime.split(' ');
  const dateTemp = dateSeparateTime[0].split('-');
  return dateTemp[2] + '-' + dateTemp[1] + '-' + dateTemp[0];
};

const formatDateWithTime = datetime => {
  let splitDate = datetime.split(' ');
  let date = formatDate(splitDate[0]);
  let time = splitDate[1];
  console.log(time + ', ' + date);
  return time + ', ' + date;
};
const formatDateFromDT = datetime => {
  let splitDate = datetime.split(' ');
  let date = formatDate(splitDate[0]);

  let dateSeparateTime = date.split(' ');
  let dateTemp = dateSeparateTime[0].split('-');
  return dateTemp[2] + '-' + dateTemp[1] + '-' + dateTemp[0];
};

const openNotification = (condition, Message) => {
  if (condition === 'success') {
    this.setState({
      isAlertOpen: true,
      alertState: 'success',
      AlertMessage: Message,
    });
  } else {
    this.setState({
      isAlertOpen: true,
      alertState: 'danger',
      AlertMessage: Message,
    });
  }
  setTimeout(
    function() {
      this.closeNotification();
    }.bind(this),
    3000,
  );
};
const closeNotification = () => {
  this.setState({ isAlertOpen: false });
};

const timeSince = date => {
  let newDate = new Date(date);
  let seconds = Math.floor((new Date() - newDate) / 1000);
  let interval = seconds / 31536000;
  if (interval > 1) {
    return Math.floor(interval) + ' tahun yang lalu';
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + ' bulan yang lalu';
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + ' hari yang lalu';
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + ' jam yang lalu';
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + ' menit yang lalu';
  }
  if (seconds === 0) {
    return 'Sekarang';
  }
  return Math.floor(seconds) + ' detik yang lalu';
};

const groupBy = (xs, f) =>
  xs.reduce((r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r), {});

const calculateRadar = (type_id, dats) => {
  let minSU = 0;
  let maxSU = 0;

  let minSUM2 = 28;
  let maxSUM2 = 35;
  let minSU2 = 0;
  let maxSU2 = 0;

  let minCHM = 0;
  let maxCHM = 0;
  let minCHT = 0;
  let maxCHT = 0;
  let minCHO = 0;
  let maxCHO = 0;

  if (type_id === 1) {
    minCHT = 1000;
    maxCHT = 4000;
    minCHM = 100;
    maxCHM = 1200;
    minCHO = 100;
    maxCHO = 300;

    minSU = 15;
    maxSU = 28;
    minSU2 = 31;
    maxSU2 = 40;
  } else if (type_id === 2) {
    //if malaria
    minCHT = 600;
    maxCHT = 1800;
    minCHM = 500;
    maxCHM = 500;
    minCHO = 45;
    maxCHO = 150;

    minSU = 15;
    maxSU = 25;
    minSU2 = 28;
    maxSU2 = 35;
    minSUM2 = 28;
    maxSUM2 = 35;
  } else if (type_id === 3) {
    // if diare
    minCHT = 1000;
    maxCHT = 3000;
    minCHM = 150;
    maxCHM = 750;
    minCHO = 50;
    maxCHO = 250;

    minSU = 15;
    maxSU = 25;
    minSU2 = 28;
    maxSU2 = 35;
  } else {
    //if pneumania
    minCHT = 1000;
    maxCHT = 4000;
    minCHM = 100;
    maxCHM = 1200;
    minCHO = 100;
    maxCHO = 300;

    minSU = 25;
    maxSU = 35;
  }
  //ch
  let chb = dats[0].ch_bulanan;
  let dataCHM =
    chb <= minCHM ? 0 : chb >= maxCHM ? 1 : (chb - minCHM) / (maxCHM - minCHM);
  let cht = dats[0].ch_tahunan;
  let dataCHT =
    cht <= minCHT ? 0 : cht >= maxCHT ? 1 : (cht - minCHT) / (maxCHT - minCHT);
  let cho = dats[0].ch_optimum;
  let dataCHO =
    cho <= minCHO ? 0 : cho >= minCHO ? 1 : (cho - minCHO) / (minCHO - minCHO);

  //su
  let sutFinal = 0;
  let sumFinal = 0;
  let suoFinal = 0;
  let sut = dats[0].su_tahunan;
  let sum = dats[0].su_bulanan;
  let suo = dats[0].su_optimum;

  if (type_id === 4) {
    sutFinal =
      sut < minSU ? 1 : sut > maxSU ? 1 : (maxSU - sut) / (maxSU - minSU);
    sumFinal =
      sum < minSU ? 1 : sum > maxSU ? 1 : (maxSU - sum) / (maxSU - minSU);
    suoFinal =
      suo < minSU ? 1 : suo > maxSU ? 1 : (maxSU - suo) / (maxSU - minSU);
  } else {
    let sutCode1 =
      sut <= minSU ? 0 : sut >= maxSU ? 1 : (sut - minSU) / (maxSU - minSU);
    let sutCode2 =
      sut <= minSU2
        ? 0
        : sut >= maxSU2
        ? 1
        : (sut - minSU2) / (maxSU2 - minSU2);
    sutFinal = sut < maxSU ? sutCode1 : sutCode2;

    let sumCode1 =
      sum <= minSU ? 0 : sum >= maxSU ? 1 : (sum - minSU) / (maxSU - minSU);
    let sumCode2 = 0;
    if (type_id === 2) {
      sumCode2 =
        sum <= minSUM2
          ? 0
          : sum >= maxSUM2
          ? 1
          : (sum - minSUM2) / (maxSU2 - minSUM2);
    } else {
      sumCode2 =
        sum <= minSU2
          ? 0
          : sum >= maxSU2
          ? 1
          : (sum - minSU2) / (maxSU2 - minSU2);
    }
    sumFinal = sum < maxSU ? sumCode1 : sumCode2;

    let suoCode1 =
      suo <= minSU ? 0 : suo >= maxSU ? 1 : (suo - minSU) / (maxSU - minSU);
    let suoCode2 =
      suo <= minSU2
        ? 0
        : suo >= maxSU2
        ? 1
        : (suo - minSU2) / (maxSU2 - minSU2);
    suoFinal = suo < maxSU ? suoCode1 : suoCode2;
  }

  let data = {
    dataCHM: Math.round((dataCHM + Number.EPSILON) * 100) / 100,
    dataCHT: Math.round((dataCHT + Number.EPSILON) * 100) / 100,
    dataCHO: Math.round((dataCHO + Number.EPSILON) * 100) / 100,
    sutFinal: Math.round((sutFinal + Number.EPSILON) * 100) / 100,
    suoFinal: Math.round((suoFinal + Number.EPSILON) * 100) / 100,
    sumFinal: Math.round((sumFinal + Number.EPSILON) * 100) / 100,
  };
  return data;
};

export default {
  formatDate,
  formatDateWithTime,
  openNotification,
  closeNotification,
  timeSince,
  groupBy,
  formatDateFromDT,
  calculateRadar,
};

import React, { forwardRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Radar, Chart } from 'react-chartjs-2';

import gf from 'config/globalFunc';
import api from 'config/api';

// @material-ui/core
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

// @material-ui/icons
import CloseIcon from '@material-ui/icons/Close';
import PrintIcon from '@material-ui/icons/Print';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

// @material-ui/lab
import Skeleton from '@material-ui/lab/Skeleton';

// core components
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';

// assets
import styleCss from 'assets/css/views/global.module.css';
import { format } from 'date-fns';

Chart.defaults.global.legend.display = false;

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

function VisualisasiDataBahaya({ userId }) {
  const [openDialog, setOpenDialog] = useState(false);
  const [loadBahaya, setLoadBahaya] = useState(true);
  const [dataBahaya, setDataBahaya] = useState([]);

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const getBahayaByDataVisual = id => {
    setLoadBahaya(true);
    api
      .getBahayaByDataVisual(id)
      .then(res => {
        const responseBody = res.data;
        if (responseBody.error_code === 0) {
          const dataBahayaChart = responseBody.data.map(item => ({
            ...item,
            options: {
              responsive: true,
              maintainAspectRatio: false,
              height: '100px',
            },
            datas: {
              labels: ['SU O', 'SU T', 'SU M', 'CH O', 'CH T', 'CH M'],
              datasets: [
                {
                  data: [
                    item.su_bulanan,
                    item.su_tahunan,
                    item.su_optimum,
                    0,
                    0,
                    0,
                  ],
                  backgroundColor: 'rgba(0,166,166,0.5)',
                  borderColor: 'rgba(0,166,166,1)',
                  pointBackgroundColor: 'rgba(0,166,166,1)',
                  pointBorderColor: '#fff',
                  pointHoverBackgroundColor: '#fff',
                  pointHoverBorderColor: 'rgba(0,166,166,1)',
                  label: 'SU',
                },
                {
                  data: [
                    0,
                    0,
                    0,
                    item.ch_bulanan,
                    item.ch_tahunan,
                    item.ch_optimum,
                  ],
                  backgroundColor: 'rgba(255,99,132,0.5)',
                  borderColor: 'rgba(255,99,132,1)',
                  pointBackgroundColor: 'rgba(255,99,132,1)',
                  pointBorderColor: '#fff',
                  pointHoverBackgroundColor: '#fff',
                  pointHoverBorderColor: 'rgba(255,99,132,1)',
                  label: 'CH',
                },
              ],
            },
          }));

          setDataBahaya(dataBahayaChart);
        }
        setLoadBahaya(false);
      })
      .catch(err => {
        setLoadBahaya(false);
        console.log('An error occured when getting data bahaya:', err);
      });
  };

  useEffect(() => {
    getBahayaByDataVisual(userId);
  }, []);

  return (
    <div>
      <Typography className={styleCss.JudulIsiRingkasan}>
        <ArrowDropDownIcon style={{ color: '#00A6A6' }} />
        Visualisasi Data Bahaya
      </Typography>
      <div>
        <div style={{ marginLeft: '50px' }}>
          <GridContainer>
            {loadBahaya ? (
              <GridItem md="12">
                <GridContainer>
                  <GridItem md={4} key={1 + 'radars'}>
                    <Skeleton variant="rect" width="100%">
                      <div style={{ height: 120 }} />
                    </Skeleton>
                  </GridItem>
                  <GridItem md={4} key={2 + 'radars'}>
                    <Skeleton variant="rect" width="100%">
                      <div style={{ height: 120 }} />
                    </Skeleton>
                  </GridItem>
                  <GridItem md={4} key={3 + 'radars'}>
                    <Skeleton variant="rect" width="100%">
                      <div style={{ height: 120 }} />
                    </Skeleton>
                  </GridItem>
                </GridContainer>
              </GridItem>
            ) : (
              dataBahaya.slice(0, 3).map((db, index) => (
                <GridItem md={4} key={index + 'radar'}>
                  <div
                    style={{
                      border: '1px solid',
                      borderRadius: '4px',
                      height: '122px',
                      width: '105px',
                      padding: '0 12px',
                      position: 'relative',
                      paddingBottom: '12px',
                    }}
                  >
                    <Radar data={db.datas} options={db.options} />
                    <div
                      style={{
                        background: 'rgba(51, 51, 51, 0.1)',
                        borderTopLeftRadius: '4px',
                        color: 'rgb(51, 51, 51)',
                        position: 'absolute',
                        bottom: '0px',
                        right: '0px',
                        padding: '1px 4px',
                      }}
                    >
                      {format(new Date(db.created_date), 'dd/MM/yyyy')}
                    </div>
                  </div>
                </GridItem>
              ))
            )}

            {dataBahaya.length === 0 && !loadBahaya && (
              <GridItem
                md={12}
                key={'noradar'}
                style={{
                  textAlign: 'center',
                  height: 100,
                  marginTop: 50,
                }}
              >
                <span className={styleCss.dataKerentanan}>
                  <FontAwesomeIcon icon={faExclamationCircle} /> Tidak ada data
                  Visualisasi Bahaya
                </span>
              </GridItem>
            )}
          </GridContainer>
        </div>
        <div>
          <button className={styleCss.lihatDataSemua} onClick={handleClickOpen}>
            Lihat semua data bahaya tersimpan
          </button>

          <Dialog
            className={styleCss.dialogPopUp}
            open={openDialog}
            TransitionComponent={Transition}
            keepMounted
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle id="alert-dialog-slide-title">
              <IconButton
                aria-label="close"
                className={styleCss.closeButtonDialog}
                onClick={handleClose}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <div className={styleCss.divDataBahayaJudul}>
              <Typography>Data Bahaya</Typography>
              <label>Daftar data bahaya yang tersimpan di akun anda</label>
            </div>

            <DialogContent className={styleCss.dialogPopUpConnten}>
              {dataBahaya.map((db, index) => (
                <Card
                  className={styleCss.IsiDataBahayaPopup}
                  key={`data-bahaya-${index + 1}`}
                >
                  <GridContainer>
                    <GridItem md={3}>
                      <Radar data={db.datas} options={db.options} />
                    </GridItem>
                    <GridItem md={9}>
                      <GridContainer>
                        <GridItem md={7}>
                          <div
                            className={styleCss.divKeteranganBahayaTersimpan}
                          >
                            <Typography className={styleCss.KetBahaya1}>
                              {db.level__name} - {db.penyakit__name}
                            </Typography>
                            <Typography className={styleCss.KetBahaya2}>
                              {db.period__name} - {db.model__name}
                            </Typography>

                            <Typography className={styleCss.KetBahaya3}>
                              {db.wilayah__name}
                            </Typography>

                            <label className={styleCss.KetBahaya4}>
                              {' '}
                              {db?.created_date
                                ? gf.formatDateFromDT(db.created_date)
                                : ''}{' '}
                              | {db.user__name}
                            </label>
                          </div>
                        </GridItem>
                        <GridItem md={5} style={{ marginRight: '-10px' }}>
                          <IconButton
                            className={styleCss.IconPrintBahayaTersimpan}
                          >
                            <PrintIcon />
                          </IconButton>
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                </Card>
              ))}
            </DialogContent>
          </Dialog>
        </div>
      </div>
    </div>
  );
}

VisualisasiDataBahaya.propTypes = {
  userId: PropTypes.number,
};

export default VisualisasiDataBahaya;

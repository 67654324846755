import React, { useEffect, useState } from 'react';
import api from 'config/api';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

// @material-ui/core
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

// @material-ui/icons
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

// @material-ui/lab
import Skeleton from '@material-ui/lab/Skeleton';

// core components
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';

// assets
import styleCss from 'assets/css/views/global.module.css';

function DataKerentanan({ userId }) {
  const history = useHistory();
  const [isLoading, setLoading] = useState(true);
  const [dataKerentanan, setDataKerentanan] = useState([]);

  const getKerentananParamUser = id => {
    setLoading(true);
    api
      .getDataCollection(id)
      .then(res => {
        if (res.data.error_code === 0) {
          setDataKerentanan(res.data.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch(err => {
        setLoading(false);
        console.log('Errorr:', err);
      });
  };

  const downloadFormat = () => {
    const urls = api.getKerentananTemplate();
    window.open(urls);
  };

  useEffect(() => {
    getKerentananParamUser(userId);
  }, []);

  return (
    <div>
      <Typography className={styleCss.JudulIsiRingkasan}>
        <ArrowDropDownIcon style={{ color: '#00A6A6' }} />
        Data Kerentanan
      </Typography>
      <div>
        <Typography className={styleCss.IsiRingkasan}>
          {' '}
          Baru Ditambahkan :
        </Typography>
        <GridContainer>
          <GridItem md={9}>
            {dataKerentanan.slice(0, 2).map(dk => (
              <div
                className={styleCss.ditambahkanCard}
                key={`data-kerentanan-${dk.id}`}
              >
                <span>
                  <InsertDriveFileIcon
                    className={styleCss.IconFileDitambahkan}
                  />
                </span>{' '}
                <span className={styleCss.dataKerentanan}>{dk.name}</span>{' '}
                <span className={styleCss.ditambakanPadaTgl}>
                  ditambahkan pada : {dk.year}
                </span>
              </div>
            ))}

            {dataKerentanan.length === 0 && !isLoading ? (
              <div
                className={styleCss.ditambahkanCard}
                style={{ textAlign: 'center' }}
              >
                <span className={styleCss.dataKerentanan}>
                  <FontAwesomeIcon icon={faExclamationCircle} /> belum ada data
                  kerentanan
                </span>
              </div>
            ) : (
              isLoading && (
                <div>
                  <div className={styleCss.ditambahkanCard}>
                    <Skeleton variant="rect" width="100%">
                      <div style={{ height: 20 }} />
                    </Skeleton>
                  </div>

                  <div className={styleCss.ditambahkanCard}>
                    <Skeleton variant="rect" width="100%">
                      <div style={{ height: 20 }} />
                    </Skeleton>
                  </div>
                </div>
              )
            )}
          </GridItem>
          {!isLoading && (
            <GridItem md={3}>
              <Button
                variant="outlined"
                onClick={() => history.push('/data-kerentanan')}
                className={styleCss.buttonLihatData}
              >
                Lihat Data
              </Button>
              <Typography
                onClick={() => downloadFormat()}
                className={styleCss.UnduhData}
              >
                Unduh format data
              </Typography>
            </GridItem>
          )}
        </GridContainer>
      </div>
    </div>
  );
}

DataKerentanan.propTypes = {
  userId: PropTypes.number,
};

export default DataKerentanan;

import { useState } from 'react';
import api from 'config/api';

function useUserProfile(userId) {
  const [profile, setProfile] = useState({});
  const [isProfileLoading, setProfileLoading] = useState(false);

  const getUserById = () => {
    setProfileLoading(true);
    if (!userId) {
      return;
    }
    api
      .getUserById(userId)
      .then(res => {
        const responseBody = res.data;
        if (responseBody.error_code !== 0) {
          throw new Error(
            responseBody.error_message || 'unable fetch user data',
          );
        }
        setProfile(responseBody.data);
      })
      .catch(err => {
        console.log('an error occured: ', err);
      })
      .finally(() => {
        setProfileLoading(false);
      });
  };

  return {
    profile,
    isProfileLoading,
    getUserById,
  };
}

export default useUserProfile;

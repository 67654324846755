import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core
import Skeleton from '@material-ui/lab/Skeleton';

// @material-ui/lab
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';

// @material-ui/icons
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import styleCss from 'assets/css/views/global.module.css';

function AdminProgram({ profile, isProfileLoading }) {
  // render
  if (!isProfileLoading && profile && profile.data) {
    const { email } = profile;
    const {
      kepalaDinas: namaPengelola,
      alamat: jabatan,
      penJawab: nip,
      nomor,
    } = profile.data;

    return (
      <>
        <TreeView
          className={styleCss.TreeView}
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          defaultExpanded={['1']}
          multiSelect
        >
          <TreeItem
            className={styleCss.TreeItemColor}
            nodeId="1"
            label="Informasi Pengelola"
          >
            <TreeItem
              className={styleCss.TreeItem}
              nodeId="2"
              label={'Nama Pengelola : ' + namaPengelola}
            />
            <TreeItem
              className={styleCss.TreeItem}
              nodeId="3"
              label={'Jabatan : ' + jabatan}
            />
            <TreeItem
              className={styleCss.TreeItem}
              nodeId="4"
              label={'NIP : ' + nip}
            />
          </TreeItem>
        </TreeView>
        <TreeView
          className={styleCss.TreeView}
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          defaultExpanded={['1']}
          multiSelect
        >
          <TreeItem
            className={styleCss.TreeItemColor}
            nodeId="1"
            label="Informasi Kontak"
          >
            <TreeItem
              className={styleCss.TreeItem}
              nodeId="2"
              label={'Email : ' + email}
            />
            <TreeItem
              className={styleCss.TreeItem}
              nodeId="3"
              label={'Nomor : ' + nomor}
            />
          </TreeItem>
        </TreeView>
      </>
    );
  }

  return (
    <div style={{ paddingLeft: 20, paddingRight: 20 }}>
      <div style={{ marginTop: 50 }}>
        <Skeleton variant="text" />
        <Skeleton variant="text" />
        <Skeleton variant="text" />
      </div>

      <div style={{ marginTop: 50 }}>
        <Skeleton variant="text" />
        <Skeleton variant="text" />
        <Skeleton variant="text" />
      </div>
    </div>
  );
}

AdminProgram.propTypes = {
  profile: PropTypes.object,
  isProfileLoading: PropTypes.string,
};

export default AdminProgram;

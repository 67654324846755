import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// @material-ui/core
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';

// Css & js Style
import styleCss from 'assets/css/views/global.module.css';

// Icons
import SearchIcon from '@material-ui/icons/Search';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook } from '@fortawesome/free-solid-svg-icons';

// configs
import api from 'config/api';

// Home Component Definition
function LayerSidebar({
  listProductKnowledge,
  openNotification,
  onUpdateProductKnowledge,
  setOpenLeftLayer,
}) {
  // states definitions
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [search, setSearch] = useState('');

  const toggleSidebar = () => {
    !isSidebarOpen
      ? setOpenLeftLayer('productKnowledge')
      : setOpenLeftLayer('');
    setSidebarOpen(!isSidebarOpen);
  };

  const getProductKnowledge = () => {
    api
      .getKnowledgeProducts(1, '', search)
      .then(res => {
        if (res.data.error_code === 0) {
          onUpdateProductKnowledge(res.data.data);
        } else {
          throw new Error(res.data.message);
        }
      })
      .catch(error => {
        openNotification('danger', error.message);
      });
  };

  useEffect(() => {
    getProductKnowledge();
  }, []);

  // conditional class names
  const containerCN = isSidebarOpen
    ? styleCss.sidebarLeft
    : styleCss.sidebarLeftNone;

  const leftIconCN = isSidebarOpen
    ? styleCss.sidebarLeftIconUpperActive
    : styleCss.sidebarLeftIconUpper;

  const contentCN = isSidebarOpen
    ? styleCss.sidebarLeftContent
    : styleCss.leftSidebarDataNone;

  return (
    <div className={containerCN}>
      <div className={leftIconCN} onClick={toggleSidebar}>
        <span className={styleCss.layerspan}>
          <FontAwesomeIcon icon={faBook} /> Knowledge Product
        </span>
      </div>
      <div className={contentCN} style={{ zIndex: 999, position: 'absolute' }}>
        <div>
          <Paper className={styleCss.paperSearch}>
            <InputBase
              className={styleCss.inputSearch}
              placeholder="Search Product Knowledge..."
              onChange={e => setSearch(e.target.value)}
              value={search}
            />
            <IconButton
              type="button"
              className={styleCss.iconButton}
              aria-label="search"
            >
              <SearchIcon />
            </IconButton>
          </Paper>
        </div>
        <div className={styleCss.sidebarLeftLayerList}>
          {listProductKnowledge.map(product => (
            <a
              style={{
                textDecoration: 'none',
                margin: '10px',
              }}
              href={product.url}
              key={product.created_by}
              target="_blank"
            >
              <p
                style={{
                  fontWeight: 'bold',
                  fontSize: '16px',
                  color: '#FFFFFF',
                  padding: 0,
                  margin: 0,
                }}
              >
                {product.name}
              </p>
              <p
                style={{
                  fontSize: '12px',
                  color: '#FFFFFF',
                  padding: 0,
                  margin: 0,
                }}
              >
                {product.description}
              </p>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
}

LayerSidebar.propTypes = {
  listLayer: PropTypes.object,
  openNotification: PropTypes.func,
  onUpdateMarkers: PropTypes.func,
  onUpdateGeoJSON: PropTypes.func,
  onUpdateLayerList: PropTypes.func,
};

export default LayerSidebar;
